/* RESPONSIBLE TEAM: team-tickets-1 */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable ember/no-actions-hash */
/* eslint-disable promise/prefer-await-to-then */
/* eslint-disable ember/no-controllers */
/* eslint-disable @intercom/intercom/no-legacy-modal */
import { alias } from '@ember/object/computed';
import { inject as service } from '@ember/service';
import BaseStyleConversationController from 'embercom/controllers/base/conversation-style';

export default BaseStyleConversationController.extend({
  pubsub: service(),
  conversationsService: service(),
  userCacheService: service('userCacheService'),
  modalService: service(),
  conversation: alias('model'),
  isSaving: false,

  actions: {
    addPart(part) {
      this.conversationsService.addPartAndSave(
        part.conversation,
        part.type,
        part.blocks,
        part.uploads,
        part.assignee,
      );
    },

    tagLastUserComment() {
      let taggingModal = 'inbox/inbox/conversations/modals/tagging';

      if (this.get('app.canUseConversationLevelTagging')) {
        this.modalService.openModal(taggingModal, { taggable: this.conversation });
      } else {
        let lastUserPartWithBody = this.get('conversation.lastUserPartWithBody');
        if (lastUserPartWithBody) {
          this.modalService.openModal(taggingModal, { taggable: lastUserPartWithBody });
        }
      }
    },
    tagUser() {
      let firstParticipantId = this.get('conversation.participants.firstObject.id');
      this.userCacheService.getUserAndRefreshCache(firstParticipantId).then((user) => {
        let taggingComponent = 'users/segments/segment/modals/tagging';
        this.modalService.openModal(taggingComponent, user, { canCloseModal: true });
      });
    },
    focusComposerTab(tab) {
      if (this.conversationCard) {
        this.conversationCard.send('focusComposerTab', tab);
      }
    },
  },
});
