/* RESPONSIBLE TEAM: team-customer-data-platform */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
/* eslint-disable ember/no-actions-hash */
/* eslint-disable ember/require-computed-property-dependencies */
/* eslint-disable ember/no-controllers */
/* eslint-disable ember/no-classic-classes */
import { A } from '@ember/array';
import EmberObject, { computed } from '@ember/object';
import { readOnly, sort } from '@ember/object/computed';
import { inject as service } from '@ember/service';
import Controller from '@ember/controller';
import { findBy, notImplemented, ternaryToProperty } from '@intercom/pulse/lib/computed-properties';
import { task } from 'ember-concurrency';
import { put } from 'embercom/lib/ajax';

export default Controller.extend({
  appService: service(),
  attributeService: service(),
  app: readOnly('appService.app'),
  // eslint-disable-next-line @intercom/intercom/no-legacy-modal
  modalService: service(),
  intl: service(),
  notificationsService: service(),

  attributes: notImplemented(
    'attribute-settings-controller: The child controller should define the set of attributes',
  ),
  archivedAttributes: notImplemented(
    'attribute-settings-controller: The child controller should define the set of archived attributes',
  ),
  archivedAttributesWithSettings: computed('model.archivedAttributes.@each.archived', function () {
    return this.attributeService.attributesWithSettings(this.get('model.archivedAttributes'));
  }),

  columns: computed('intl.locale', function () {
    return A([
      EmberObject.create({
        label: this.intl.t('settings.people-data.attribute-name'),
        canExpandToFit: true,
        component: 'table-cells/attributes/name-and-description-cell',
        sortable: true,
        isSortColumn: false,
        sortedAscending: true,
        propertyName: 'attribute.name',
      }),
      EmberObject.create({
        label: this.intl.t('settings.people-data.format'),
        component: 'table-cells/attributes/format-and-options-cell',
        canExpandToFit: false,
      }),
      EmberObject.create({
        label: this.intl.t('settings.people-data.created'),
        component: 'table-cells/attributes/created-at-cell',
        sortable: true,
        isSortColumn: false,
        sortedAscending: false,
        propertyName: 'attribute.created_at',
      }),
      EmberObject.create({
        component: 'table-cells/attributes/edit-button-cell',
      }),
    ]);
  }),

  sortedAttributes: ternaryToProperty(
    'sortDefinition',
    'attributesSortedBySortDefinition',
    'attributesWithDefaultSorting',
  ),
  attributesSortedBySortDefinition: sort('attributes', 'sortDefinition'),
  attributesWithDefaultSorting: computed('attributes', function () {
    return this.attributes.sort((a1, a2) => {
      if (a1.isStandard && a2.isCustomData) {
        return -1;
      } else if (a1.isCustomData && a2.isStandard) {
        return 1;
      }
      return 0;
    });
  }),
  rows: computed(
    'sortedAttributes.@each.archived',
    'archivedAttributes.@each.archived',
    function () {
      return this.sortedAttributes.concat(this.archivedAttributes).uniqBy('attribute.identifier');
    },
  ),

  currentSortColumn: findBy('columns', 'isSortColumn'),
  sortDefinition: computed('currentSortColumn', 'currentSortColumn.sortedAscending', function () {
    let sortColumn = this.currentSortColumn;

    if (!sortColumn) {
      return null;
    }

    return sortColumn.sortedAscending
      ? [sortColumn.propertyName]
      : [`${sortColumn.propertyName}:desc`];
  }),

  filterSearchTerm: '',
  filteredRows: computed('rows.@each.attribute', 'filterSearchTerm', function () {
    return this.rows.filter((row) => this.rowMatchesFilterSearchTerm(row));
  }),

  rowMatchesFilterSearchTerm(row) {
    let rowAttributeName = row.get('attribute.name').toLowerCase();
    let filterSearchTerm = this.filterSearchTerm.toLowerCase();
    return rowAttributeName.includes(filterSearchTerm);
  },
  toggleMessengerCdaCreation: task(function* () {
    try {
      let data = {
        app_id: this.appService.app.id,
        messenger_cda_creation_enabled: !this.appService.app.hasMessengerCdaCreationEnabled,
      };
      yield put('/ember/messenger_cda_creation_settings/update_setting', data).then(() => {
        this.appService.app.reload().then(() => {
          this.notificationsService.notifyConfirmation(
            this.intl.t('settings.people-data.secure-mode-update-success'),
          );
        });
      });
    } catch (e) {
      this.notificationsService.notifyError(this.intl.t('settings.people-data.cannot_save_error'));
    }
  }).drop(),

  actions: {
    openAttributeCreationModal(type) {
      this.modalService.openModal('settings/modals/attribute-details', {
        attribute: null,
        allowArchival: false,
        showPeopleAndCompanyToggleTab: false,
        typeToCreate: type,
      });
    },

    openDetailsView(row) {
      this.modalService.openModal('settings/modals/attribute-details', {
        attribute: row.get('attribute'),
        allowArchival: row.get('attribute.isCustomData'),
      });
    },

    toggleSort(column) {
      let columns = this.columns;
      let currentSortColumn = this.currentSortColumn;

      if (currentSortColumn?.get('propertyName') === column.propertyName) {
        currentSortColumn.toggleProperty('sortedAscending');
      } else {
        currentSortColumn?.set('isSortColumn', false);

        let newSortColumn = columns.findBy('propertyName', column.propertyName);
        newSortColumn.set('isSortColumn', true);
      }
    },

    onArchive(attribute) {
      attribute.set('archived', true);
      this.get('model.archivedAttributes').pushObject(attribute);
    },

    onUnarchive(attribute) {
      attribute.set('archived', false);
      this.get('model.archivedAttributes').removeObject(attribute);
    },

    toggleMessengerCdaCreation() {
      this.toggleMessengerCdaCreation.perform();
    },
  },
});
