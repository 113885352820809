/* RESPONSIBLE TEAM: team-ml */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable ember/no-actions-hash */
/* eslint-disable promise/prefer-await-to-then */
/* eslint-disable ember/no-controllers */
/* eslint-disable ember/no-classic-classes */
import { readOnly } from '@ember/object/computed';
import Controller, { inject as controller } from '@ember/controller';
import { inject as service } from '@ember/service';
import { post } from 'embercom/lib/ajax';

export default Controller.extend({
  intercomEventService: service(),
  notificationsService: service(),
  appController: controller('apps.app'),
  app: readOnly('appController.model'),
  currentAdmin: readOnly('app.currentAdmin'),
  currentAdminEmail: readOnly('currentAdmin.email'),
  importStarted: false,

  zendeskChatController: controller('apps.app.import.zendesk-chat'),
  importerConfiguration: readOnly('zendeskChatController.importerConfiguration'),

  _trackAnalyticsEvent(action, object) {
    this.intercomEventService.trackAnalyticsEvent({
      action,
      object,
      context: 'zendesk_chat_import',
      conversation_import_id: this.get('model.id'),
    });
  },

  actions: {
    importConversations() {
      this._trackAnalyticsEvent('clicked', 'import_button');
      return post(`/ember/conversation_imports/${this.get('model.id')}/import`, {
        app_id: this.get('app.id'),
        admin_id: this.get('currentAdmin.id'),
      })
        .then(() => {
          this.set('importStarted', true);
          if (window.opener) {
            window.opener.importFlowCompleted = true;
          }
          this._trackAnalyticsEvent('viewed', 'import_started_page');
        })
        .catch((err) => {
          this.notificationsService.notifyResponseError(err, {
            default: err.jqXHR.responseJSON.error,
          });
        });
    },
  },
});
