/* RESPONSIBLE TEAM: team-channels */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
/* eslint-disable ember/no-controllers */
import Controller from '@ember/controller';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import { isEmpty } from '@ember/utils';
import { tracked } from '@glimmer/tracking';

export default class AccountsController extends Controller {
  @service intercomEventService;
  @service notificationsService;

  @tracked accountToBeDeleted;

  get accounts() {
    return this.model.filter((account) => !account.deleted);
  }

  get anyAccount() {
    return !isEmpty(this.model);
  }

  @action
  async deleteAccount() {
    try {
      let account = await this.accountToBeDeleted;
      await account.destroyRecord();
      account.unloadRecord();
      this._trackDeletion();
      this.notificationsService.notifyConfirmation(
        `Account ${account.domain} was successfully removed.`,
      );
      this.accountToBeDeleted = null;
    } catch (e) {
      this.notificationsService.notifyError('There was a problem removing an account.');
    }
  }

  @action
  deleteAccountConfirmation(account) {
    this.accountToBeDeleted = account;
  }

  @action
  transitionToSaleforceAppSettings() {
    this.transitionToRoute('apps.app.appstore', {
      queryParams: { app_package_code: 'salesforce-by-intercom' },
    });
  }

  _trackDeletion() {
    this.intercomEventService.trackAnalyticsEvent({
      action: 'deleted',
      object: 'account',
    });
  }
}
