/* RESPONSIBLE TEAM: team-channels */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable ember/no-controllers */
import { alias, notEmpty, not } from '@ember/object/computed';
import IntegrateBaseController from 'embercom/controllers/apps/app/integrations-hub/integrate-base-controller';
import { inject as service } from '@ember/service';
import { task } from 'ember-concurrency';

let OauthTokensController = IntegrateBaseController.extend({
  intl: service(),
  notificationsService: service(),
  oauthTokens: alias('model'),
  showTable: notEmpty('model'),
  emptyState: not('showTable'),

  revoke: task(function* (oauthToken) {
    try {
      yield oauthToken.destroyRecord();
    } catch (err) {
      this.notificationsService.notifyError(this.intl.t('account.oauth-tokens.revoke-error'));
    }
  }),
});

export default OauthTokensController;
