/* RESPONSIBLE TEAM: team-growth-opportunities */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable ember/no-controllers */
import Controller, { inject as controller } from '@ember/controller';
import { action, get } from '@ember/object';
import { inject as service } from '@ember/service';
import { VALUE_BASED_PRICING_FEB2022_STARTER_SOLUTION_ID } from 'embercom/lib/billing';

export default class UsageController extends Controller {
  @controller('apps/app/teams-checkout') teamsController;
  @service router;
  @service media;
  @service intl;

  @action
  transitionToCheckout() {
    let { solutionId } = this.teamsController;

    let queryParams = { solution_id: solutionId };
    this.router.transitionTo('apps.app.teams-checkout.confirm', { queryParams });
  }

  get continueButtonLabel() {
    if (
      get(this.teamsController, 'seats') > this.teamsController.minimumSeats ||
      this.media.isMobile
    ) {
      return this.intl.t('teams-checkout.usage.continue');
    } else {
      return this.intl.t('teams-checkout.usage.continue_without_adding_seats');
    }
  }

  get annualDiscountPercentage() {
    return this.anyPrice.getAnnualDiscountPercentage();
  }

  get discountText() {
    if (this.teamsController.solutionId === VALUE_BASED_PRICING_FEB2022_STARTER_SOLUTION_ID) {
      return this.intl.t('teams-checkout.usage.discount_text');
    } else {
      return this.intl.t('teams-checkout.usage.save_discount', {
        annualDiscountPercentage: this.annualDiscountPercentage,
      });
    }
  }

  get anyPrice() {
    return get(this.teamsController, 'prices')[0];
  }
}
