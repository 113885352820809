/* RESPONSIBLE TEAM: team-proactive-support */

import Controller from '@ember/controller';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';

export default class MatchCheckController extends Controller {
  queryParams = ['userId'];

  @tracked userId: string | undefined;

  @action onSelectUser(userId: string | undefined) {
    this.userId = userId;
  }
}
