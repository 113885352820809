/* RESPONSIBLE TEAM: team-proactive-support */
import Controller from '@ember/controller';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { stats } from 'embercom/models/data/stats-system/stats-constants';
import { isPresent } from '@ember/utils';

export default class AppsAppOutboundSeriesSeriesController extends Controller {
  @service seriesEditorService;
  @tracked nodeId = null;
  @tracked _mode = 'view'; // Tracking mode directly causes this issue: https://github.com/emberjs/ember.js/issues/18715
  @tracked _view = 'overview';
  @tracked _filter = stats.receipt;
  queryParams = ['nodeId', 'mode', 'view', 'filter'];

  get filter() {
    return this._filter;
  }

  set filter(value) {
    this._filter = value;
  }

  get mode() {
    return this._mode;
  }

  set mode(value) {
    this._mode = value;
  }

  get view() {
    return this._view;
  }

  set view(value) {
    this._view = value;
  }

  @action changeFilter(newFilter) {
    this.filter = newFilter;
  }

  @action changeMode(newMode) {
    if (newMode !== this.mode) {
      this.mode = newMode;
    }
  }

  @action changeView(newView) {
    this.view = newView;
  }

  @action changeActiveSeriesNode(node) {
    if (isPresent(node) && node.id !== this.nodeId) {
      this.nodeId = node.id;
    } else if (node === null && isPresent(this.nodeId)) {
      this.nodeId = null;
    }
  }
}
