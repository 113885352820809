/* RESPONSIBLE TEAM: team-proactive-support */
import OutboundBaseListController from 'embercom/lib/outbound/base-list-controller';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { isBlank } from '@ember/utils';

export default class AppsAppOutboundWhatsappIndexController extends OutboundBaseListController {
  @service outboundHomeService;
  @service contentEditorService;
  @service appService;
  @service store;
  @service intl;

  @tracked whatsappIntegrations;
  @tracked onboardingStarted = false;

  get bannerData() {
    return {
      pageTitle: this.intl.t('controllers.apps.app.outbound.whatsapp.banner.title'),
      icon: 'messenger-whatsapp',
      button: this.intl.t('controllers.apps.app.outbound.whatsapp.banner.button'),
      description: this.intl.t('controllers.apps.app.outbound.whatsapp.banner.description'),
    };
  }

  get showOnboardingHome() {
    return isBlank(this.whatsappIntegrations) || this.onboardingStarted;
  }

  get isPaywalled() {
    return !this.appService.app?.canUseFeature('whatsapp_integration');
  }
}
