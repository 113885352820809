/* RESPONSIBLE TEAM: team-reporting */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
/* eslint-disable ember/no-actions-hash */
/* eslint-disable ember/no-classic-classes */
import { readOnly, equal } from '@ember/object/computed';
import Controller from '@ember/controller';
import PALETTE from '@intercom/pulse/lib/palette';
import { inject as service } from '@ember/service';
import { SIGNAL_CONFIGS } from 'embercom/models/reporting/signal';
import moment from 'moment-timezone';
import dateAndTimeFormats from 'embercom/lib/date-and-time-formats';

export default Controller.extend({
  intercomEventService: service(),
  notificationsService: service(),
  reportingService: service(),
  appService: service(),
  signalConfigs: SIGNAL_CONFIGS,
  palette: PALETTE,

  teamOrTeammate: null,
  tag: null,
  app: readOnly('appService.app'),
  timezone: readOnly('app.timezone'),

  concatenatedProperties: ['queryParams'],
  queryParams: ['teammateId', 'scopingTagIds', 'deprecatedReports', 'botInboxTime'],

  teammateId: null,
  scopingTagIds: null,
  range: null,
  botInboxTime: null,

  botInboxTimeExcluded: equal('botInboxTime', 'excluded'),

  _recordFilteredByDateAnalyticsEvent() {
    let range = this.range;
    let params = {
      action: 'filtered_by_date',
      object: this.reportName,
      date_filter: this.range.selectedRange,
      start_date: range.startMoment.format('YYYY/MM/DD'),
      end_date: range.endMoment.format('YYYY/MM/DD'),
    };
    let teamOrTeammate = this.teamOrTeammate;
    if (teamOrTeammate) {
      params.models = [teamOrTeammate];
    }
    this.intercomEventService.trackAnalyticsEvent(params);
  },
  actions: {
    updateRange(range) {
      let excludeBotInboxStartDate = null;
      if (this.botInboxTimeExcluded) {
        excludeBotInboxStartDate = this.settings.excludeBotInboxStartDate;
      }
      this.reportingService.updateRange(range, excludeBotInboxStartDate);

      this._recordFilteredByDateAnalyticsEvent();
    },
    changeTag(tag) {
      let scopingTagIds = null;
      if (tag !== null) {
        scopingTagIds = [tag.get('id')];
      }
      this.setProperties({ scopingTagIds, tag });
    },
    toggleBotInboxTime(value) {
      this.set('botInboxTime', value);

      if (
        this.botInboxTimeExcluded &&
        this.range.startMoment.isBefore(this.settings.excludeBotInboxStartDate)
      ) {
        this.notificationsService.notifyWarning(
          `Data to exclude bots is available from ${moment(
            this.settings.excludeBotInboxStartDate,
          ).format(dateAndTimeFormats.date)}, when your bot inbox was activated.`,
          5000,
        );
      }
    },
  },
});
