/* RESPONSIBLE TEAM: team-knowledge-and-data-setup */

/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
/* eslint-disable ember/no-actions-hash */
/* eslint-disable promise/prefer-await-to-then */
/* eslint-disable ember/require-computed-property-dependencies */
/* eslint-disable ember/no-controllers */
/* eslint-disable ember/no-classic-classes */
import Controller, { inject as controller } from '@ember/controller';
import { action, computed } from '@ember/object';
import { not, or, readOnly, reads } from '@ember/object/computed';
import { inject as service } from '@ember/service';
import { task } from 'ember-concurrency';
import { FILE_SIZE_VALIDATOR } from 'embercom/components/articles/site/settings/upload-button';
import ErrorHandling from 'embercom/components/mixins/articles/error-handling';
import EventTracking from 'embercom/components/mixins/articles/event-tracking';
import HelpCenterWebsiteTurnedOnNotification from 'embercom/components/notifications/help-center-website-turned-on-notification';
import ajax, { get } from 'embercom/lib/ajax';
import { CAN_MANAGE_ARTICLES_PERMISSION } from 'embercom/lib/articles/constants';
import { MediaHelper, UploadHelper } from 'embercom/objects/media/media-helper';
import { isPresent } from '@ember/utils';

const ARTICLE_SETTING_PAGE_ROUTE_NAME = 'apps.app.articles.site.settings.setting';

export default Controller.extend(ErrorHandling, EventTracking, {
  queryParams: ['sectionId'],
  sectionId: undefined,
  hasActiveApiImport: false,
  store: service(),
  paywallService: service(),
  helpCenterService: service(),
  notificationsService: service(),
  intercomConfirmService: service(),
  intercomEventService: service(),
  realTimeEventService: service(),
  permissionsService: service(),
  intl: service(),
  router: service(),
  site: readOnly('helpCenterService.site'),
  appController: controller('apps/app'),
  app: reads('appController.model'),
  pickerColorHex: reads('site.themeColor'),
  pickerColor: computed('pickerColorHex', function () {
    return `#${this.pickerColorHex}`;
  }),
  defaultLocale: computed('site', function () {
    let locale = this.get('site.supportedLocales').findBy('localeId', this.get('app.locale'));
    return !locale ? 'different' : locale.name;
  }),

  init() {
    this._super(...arguments);
    this.realTimeEventService.on(
      'CustomDomainProcessed',
      this,
      this._handleCustomDomainNotification,
    );
    this.realTimeEventService.on('ImportStarted', this, '_handleStartedImport');
    this.realTimeEventService.on('ImportCompleted', this, '_handleCompletedImport');
    this.realTimeEventService.on('ArticleSyncError', this, '_handleArticleSyncError');
    this.set('uploadHelper', this._createUploadHelper());
  },

  _handleStartedImport(event) {
    this.set('hasActiveApiImport', true);
  },

  _handleCompletedImport(event) {
    this.set('hasActiveApiImport', false);
    this.set('currentImport', null);

    if (this.router.currentRouteName === ARTICLE_SETTING_PAGE_ROUTE_NAME) {
      this.getSyncSettings.perform();
      this.notificationsService.notifyConfirmation(
        this.intl.t('articles.collections.import.notification.success'),
      );
    }
  },

  _handleArticleSyncError(event) {
    if (this.router.currentRouteName === ARTICLE_SETTING_PAGE_ROUTE_NAME) {
      this.notificationsService.notifyError(this.intl.t('articles.settings.sync.error.info'));
    }
  },

  resetMediaUploader() {
    this.set('uploadHelper', this._createUploadHelper());
  },

  willDestroy() {
    this.realTimeEventService.off(
      'CustomDomainProcessed',
      this,
      this._handleCustomDomainNotification,
    );
    this._super(...arguments);
  },

  _handleCustomDomainNotification(event) {
    if (!this.model) {
      return;
    }
    let { customDomainId, state } = event;
    if (this.model.customDomain && this.model.customDomain.id === customDomainId.toString()) {
      this.set('model.customDomain', { ...this.model.customDomain, state });
      if (state === 'success') {
        let message = this.intl.t('articles.settings.basics.custom-domain.success');
        this.notificationsService.notifyConfirmation(message);
      } else if (state === 'failed') {
        let message = this.intl.t(
          'articles.settings.basics.custom-domain.errors.domain-validation',
        );
        this.notificationsService.notifyError(message);
      }
    }
  },
  saveEnabled: computed(
    'site.hasDirtyAttributes',
    'uploadHelper.hasUpdates',
    '_saveSite.isRunning',
    function () {
      return (
        !this.get('_saveSite.isRunning') &&
        (this.get('site.hasUnsavedChanges') || this.get('uploadHelper.hasUpdates'))
      );
    },
  ),
  saveDisabled: not('saveEnabled'),
  showSaveCancel: or('site.hasUnsavedChanges', 'uploadHelper.hasUpdates'),

  sectionCanClose: task(function* () {
    if (!this.site.hasDirtyAttributes) {
      return true;
    }
    let options = {
      title: this.intl.t('articles.settings.discard-changes.title'),
      body: this.intl.t('articles.settings.discard-changes.body'),
      confirmButtonText: this.intl.t('articles.settings.discard-changes.confirm-button'),
    };
    let confirmed = yield this.intercomConfirmService.confirm(options);
    if (confirmed) {
      this.site.rollbackAttributes();
      this.intercomEventService.trackAnalyticsEvent({
        action: 'discarded',
        object: 'settings',
      });
      return true;
    } else {
      return false;
    }
  }),

  fileSizeValidator(size, sizeText) {
    return FILE_SIZE_VALIDATOR(
      size,
      this.intl.t('articles.settings.redesign.validation.file-size-error', {
        size: sizeText,
      }),
    );
  },

  _createUploadHelper() {
    return UploadHelper.create({
      header: MediaHelper.create({
        type: 'header',
        siteSettingsName: 'headerUrl',
        validators: [this.fileSizeValidator(2000000, '2MB')],
      }),
      logo: MediaHelper.create({
        type: 'logo',
        siteSettingsName: 'logoUrl',
        validators: [this.fileSizeValidator(1000000, '1MB')],
      }),
      footerLogo: MediaHelper.create({
        type: 'footer_logo',
        siteSettingsName: 'footerLogoUrl',
        validators: [this.fileSizeValidator(1000000, '1MB')],
      }),
      favicon: MediaHelper.create({
        type: 'favicon',
        siteSettingsName: 'faviconUrl',
        validators: [this.fileSizeValidator(1500000, '1.5MB')],
      }),
      social: MediaHelper.create({
        type: 'social_image',
        siteSettingsName: 'socialUrl',
        validators: [this.fileSizeValidator(2000000, '2MB')],
      }),
      collection_card: [
        MediaHelper.create({
          type: 'collection_card',
          siteSettingsName: 'collectionCardGlobal',
          validators: [this.fileSizeValidator(2000000, '2MB')],
        }),
      ],
      contentBlock: MediaHelper.create({
        type: 'content_block',
        siteSettingsName: 'contentBlock',
        validators: [this.fileSizeValidator(2000000, '2MB')],
      }),
      mediaTypes: [
        'header',
        'logo',
        'footerLogo',
        'favicon',
        'social',
        'collection_card',
        'contentBlock',
      ],
    });
  },

  _captureAndDisplayError(response) {
    this.handleServerError(response, this.intl.t('articles.settings.switch.notification.error'));
  },

  _saveMedia: task(function* () {
    if (this.site.hasMissingImages()) {
      this.notificationsService.notifyError(
        this.intl.t('articles.settings.redesign.validations.missing-image'),
      );
      return false;
    }

    let mediaHelpers = [
      this.get('uploadHelper.header'),
      this.get('uploadHelper.logo'),
      this.get('uploadHelper.footerLogo'),
      this.get('uploadHelper.favicon'),
      this.get('uploadHelper.social'),
      this.get('uploadHelper.contentBlock'),
    ];
    mediaHelpers = mediaHelpers.concat(this.get('uploadHelper.collection_card'));

    yield this._saveMediaWithUpdates(mediaHelpers);
    yield this.saveCustomFontFaces();

    this.resetCardOptionsSnapshot();
    this.resetMediaUploader();
    return true;
  }),

  resetCardOptionsSnapshot() {
    this.site.customizationOptions.cardOptionsSnapshots = {};
  },

  async _saveMediaWithUpdates(mediaHelpers) {
    for (let mediaHelper of mediaHelpers) {
      if (mediaHelper.get('hasUpdates')) {
        let mediaCollectionType = mediaHelper.get('collectionCardType');
        let collectionType = this.site.customizationOptions?.collectionCard?.global.imageType;
        if (mediaCollectionType === '' || mediaCollectionType === collectionType) {
          await this._updateMedia(mediaHelper);
        }
      }
    }
  },

  async saveCustomFontFaces() {
    let allFontFaces = this.site.customizationOptions?.global?.font?.customFontFaces;
    if (!allFontFaces) {
      return;
    }
    let fontFacesToUpload = allFontFaces.filter(
      (fontFace) => fontFace.mediaHelper && fontFace.mediaHelper.hasUpdates,
    );
    for (let fontFace of fontFacesToUpload) {
      let mediaHelper = fontFace.mediaHelper;
      let formMetadata = fontFace.serialize();
      await this.uploadFromMediaHelper(mediaHelper, formMetadata);
    }
  },

  async _updateMedia(mediaHelper) {
    let url = mediaHelper.get('url');
    if (url) {
      await this.uploadFromMediaHelper(mediaHelper);
    }
    if (!url && mediaHelper.get('isReset')) {
      await this.deleteMediaHelper(mediaHelper);
    }
  },

  async uploadFromMediaHelper(mediaHelper, formMetadata = {}) {
    let siteSettingsName = mediaHelper.get('siteSettingsName');
    let file = mediaHelper.get('file');
    let type = mediaHelper.get('type');
    try {
      await this.uploadAsset(file, type, {
        siteSettingsName,
        ...formMetadata,
      });
      mediaHelper.setProperties({
        isSaved: true,
        isReset: false,
      });
    } catch (response) {
      this.handleServerError(response, this.intl.t('articles.settings.switch.notification.error'));
      mediaHelper.setProperties({
        isSaved: false,
        isReset: true,
      });
    }
  },

  async deleteMediaHelper(mediaHelper, formMetadata) {
    let siteSettingsName = mediaHelper.get('siteSettingsName');
    let type = mediaHelper.get('type');
    try {
      await this.deleteAsset(type, {
        siteSettingsName,
        ...formMetadata,
      });
      mediaHelper.reset();
    } catch (response) {
      this.handleServerError(response, this.intl.t('articles.settings.switch.notification.error'));
      mediaHelper.setProperties({
        isSaved: false,
        isReset: true,
      });
    }
  },

  uploadAsset(file, type, formMetadata) {
    let formData = this._assetRequestFormData(type, formMetadata);
    formData.append('image', file);
    return ajax({
      type: 'POST',
      url: '/ember/help_center_sites/upload_asset.json',
      data: formData,
      contentType: false,
      processData: false,
    }).then((response) => this._storeUpdatedSite(type, response));
  },

  deleteAsset(type, formMetadata) {
    let formData = this._assetRequestFormData(type, formMetadata);
    return ajax({
      type: 'DELETE',
      url: '/ember/help_center_sites/delete_asset.json',
      data: formData,
      contentType: false,
      processData: false,
    }).then((response) => this._storeUpdatedSite(type, response));
  },

  _assetRequestFormData(type, formMetadata = {}) {
    let formData = new FormData();
    formData.append('asset_type', type);
    formData.append('app_id', this.get('app.id'));
    formData.append('help_center_id', this.get('site.id'));
    let siteSettingsName = formMetadata.siteSettingsName;
    if (type === 'collection_card') {
      let isGlobal = siteSettingsName === 'collectionCardGlobal';
      let collectionId = isGlobal ? '' : siteSettingsName.slice(siteSettingsName.indexOf('-') + 1);
      formData.append('collection_card_is_global', isGlobal);
      formData.append('collection_id', collectionId);
    }
    Object.entries(formMetadata).forEach(([key, value]) => {
      if (value) {
        formData.append(key, value);
      }
    });
    return formData;
  },

  _storeUpdatedSite(mediaType, updatedSiteResponse) {
    if (mediaType === 'collection_card') {
      return;
    }
    this.store.pushPayload({
      'help-center-sites': [updatedSiteResponse],
    });
  },

  _getSwitchHelpCenterWebsiteModalOptions(newValue) {
    let turnOnOptions = {
      title: this.intl.t('articles.settings.switch-v1.turn-on.title'),
      body: this.intl.t('articles.settings.switch-v1.turn-on.body', {
        url: this.get('site.userFriendlyUrl'),
      }),
      confirmButtonText: this.intl.t('articles.settings.switch-v1.turn-on.confirm'),
      primaryButtonType: 'primary-live',
    };
    let turnOffOptions = {
      title: this.intl.t('articles.settings.switch-v1.turn-off.title'),
      body: this.intl.t('articles.settings.switch-v1.turn-off.body', {
        url: this.get('site.userFriendlyUrl'),
      }),
      confirmButtonText: this.intl.t('articles.settings.switch-v1.turn-off.confirm'),
      primaryButtonType: 'primary-destructive',
    };
    return newValue ? turnOnOptions : turnOffOptions;
  },

  async _toggleHelpCenterWebsiteModal(newValue) {
    let confirmOptions = this._getSwitchHelpCenterWebsiteModalOptions(newValue);
    let isConfirmed = await this.intercomConfirmService.confirm(confirmOptions);
    if (!isConfirmed) {
      return;
    }
    let site = this.site;
    site.set('websiteTurnedOn', newValue);
    this._saveSite
      .perform()
      .then(() => {
        this.notificationsService.notifyConfirmationWithModelAndComponent(
          {
            site,
          },
          HelpCenterWebsiteTurnedOnNotification,
        );
        this.trackEducateEvent({
          action: newValue ? 'website_turned_on' : 'website_turned_off',
          object: 'help_center',
          place: 'settings',
        });
      })
      .catch((err) => {
        site.rollbackAttributes();
        this._captureAndDisplayError(err);
      });
  },

  _saveSite: task(function* () {
    if (this.site.validations.isValid) {
      yield this.site.save();
      this.notificationsService.notifyConfirmation(
        this.intl.t('articles.settings.switch.notification.success'),
      );
      this.helpCenterService.notifyPropertyChange('allSites');
    } else {
      this.site
        .get('validations.messages')
        .forEach((message) => this.notificationsService.notifyError(message));
    }
  }).drop(),

  _refreshCustomDomain: task(function* (customDomainId) {
    return yield ajax({
      type: 'PATCH',
      url: `/ember/custom_domains/${customDomainId}/refresh?app_id=${this.app.id}`,
    });
  }),

  _createCustomDomain: task(function* (url) {
    return yield ajax({
      type: 'POST',
      url: `/ember/custom_domains?app_id=${this.app.id}`,
      data: JSON.stringify({ hostname: url, help_center_id: this.site.id }),
    });
  }),

  _deleteCustomDomain: task(function* () {
    return yield ajax({
      type: 'POST',
      url: `/ember/custom_domains/clean?app_id=${this.app.id}`,
      data: JSON.stringify({ help_center_id: this.site.id }),
    });
  }),

  _trackDomainChangeEvent(domainChange) {
    let [oldDomain, newDomain] = domainChange;
    let action = newDomain === '' ? 'deleted' : 'created';
    this.trackEducateEvent({
      action,
      object: 'domain',
      place: 'settings',
      domain_url: action === 'deleted' ? oldDomain : newDomain,
    });
  },

  _trackDomainAndPrefixChangeEvent(domainAndPrefixChange) {
    let [oldValue, newValue] = domainAndPrefixChange;
    let action = newValue === '' ? 'deleted' : 'created';
    this.trackEducateEvent({
      action,
      object: 'domainAndPrefix',
      place: 'settings',
      domain_url: action === 'deleted' ? oldValue : newValue,
    });
  },

  _trackHomeUrlChangeEvent(homeUrlChange) {
    let [oldUrl, newUrl] = homeUrlChange;
    this.trackEducateEvent({
      action: 'saved',
      object: 'home_url',
      place: 'settings',
      owner: 'educate',
      from: oldUrl,
      to: newUrl,
    });
  },

  _trackDefaultLanguageChangeEvent(languageChange) {
    this.trackEducateEvent({
      action: 'saved',
      object: 'default_language',
      place: 'settings',
      owner: 'educate',
      from: languageChange[0],
      to: languageChange[1],
    });
  },

  _getSupportedLocalesDiff(supportedLocalesChange, defaultLanguageChange) {
    let added = [];
    let removed = [];
    this.site.supportedLocales.map((locale) => {
      if (locale.changedAttributes().isSelected) {
        if (locale.isSelected) {
          added.push(locale.localeId);
        } else {
          removed.push(locale.localeId);
        }
      }
    });
    // Cleanup so we don't double-track changes to the default
    if (
      defaultLanguageChange &&
      removed.includes(defaultLanguageChange[0]) &&
      added.includes(defaultLanguageChange[1])
    ) {
      removed = removed.filter((localeId) => localeId !== defaultLanguageChange[0]);
      added = added.filter((localeId) => localeId !== defaultLanguageChange[1]);
    }
    return { added, removed };
  },

  _trackSupportedLocalesChangeEvent({ added, removed }) {
    // We're not tracking changes to the title, just isSelected
    if (added.length + removed.length > 0) {
      this.trackEducateEvent({
        action: 'saved',
        object: 'language',
        place: 'settings',
        owner: 'educate',
        added,
        removed,
      });
    }
  },

  _trackAudienceTargetingChange() {
    this.intercomEventService.trackAnalyticsEvent({
      action: 'turned_on',
      object: 'audience_targeting',
    });
  },

  refreshCustomDomain: action(async function () {
    let { customDomain } = this.model;
    if (customDomain) {
      try {
        let updatedCustomDomain = await this._refreshCustomDomain.perform(customDomain.id);
        this.set('model.customDomain', updatedCustomDomain);
        this.trackEducateEvent({
          action: 'refresh-success',
          object: 'custom-domain',
          place: 'settings',
        });
      } catch (exception) {
        this.notificationsService.notifyError('Error');
        this.trackEducateEvent({
          action: 'refresh-failure',
          object: 'custom-domain',
          place: 'settings',
        });
      }
    }
  }),

  createCustomDomain: action(async function () {
    let isSuccessful = false;
    let url = this.site.customDomainAndPathPrefix;

    if (!url) {
      this.notificationsService.notifyError(
        this.intl.t('articles.settings.basics.custom-domain.errors.invalid-url'),
      );
      return;
    }
    try {
      let createdCustomDomain = await this._createCustomDomain.perform(url);
      this.set('model.customDomain', createdCustomDomain);
      isSuccessful = true;
    } catch (exception) {
      let {
        responseJSON: {
          error: { code: errorCode },
        },
      } = exception.jqXHR;
      let errorMessage = this.intl.t('articles.settings.basics.custom-domain.errors.generic');
      if (errorCode === 0) {
        errorMessage = this.intl.t(
          'articles.settings.basics.custom-domain.errors.duplicated-custom-domain',
        );
      } else if (errorCode === 1) {
        errorMessage = this.intl.t('articles.settings.basics.custom-domain.errors.does-not-exist');
      } else if (errorCode === 2) {
        errorMessage = this.intl.t('articles.settings.basics.custom-domain.errors.generic');
      } else if (errorCode === 3) {
        errorMessage = this.intl.t('articles.settings.basics.custom-domain.errors.invalid-url');
      } else if (errorCode === 4) {
        errorMessage = this.intl.t(
          'articles.settings.basics.custom-domain.errors.too-many-certificates',
        );
      } else if (errorCode === 5) {
        errorMessage = this.intl.t('articles.settings.basics.custom-domain.errors.cname-not-set');
      }
      this.notificationsService.notifyError(errorMessage);
      isSuccessful = false;
    }

    if (isSuccessful) {
      this.trackEducateEvent({
        action: 'create-success',
        object: 'custom-domain',
        place: 'settings',
      });
      this.send('save');
    } else {
      this.trackEducateEvent({
        action: 'create-failure',
        object: 'custom-domain',
        place: 'settings',
      });
    }
  }),

  cleanCustomDomainAndSave: action(async function () {
    if (!this.model) {
      return;
    }
    let isSuccessful = true;

    if (this.model.isManagedCustomDomainEnabled) {
      try {
        await this._deleteCustomDomain.perform();
        this.set('model.customDomain', null);
        isSuccessful = true;
      } catch (exception) {
        this.notificationsService.notifyError('Error');
        isSuccessful = false;
      }
    }

    if (isSuccessful) {
      this.trackEducateEvent({
        action: 'delete-success',
        object: 'custom-domain',
        place: 'settings',
      });
      this.send('save');
    } else {
      this.trackEducateEvent({
        action: 'delete-failure',
        object: 'custom-domain',
        place: 'settings',
      });
    }
  }),

  configureZendeskSync: action(async function () {
    this.permissionsService.checkPermission(CAN_MANAGE_ARTICLES_PERMISSION).then(() => {
      this.set('showArticleSyncModal', true);
    });
  }),

  getApiImport() {
    return ajax({
      url: '/ember/article_import_jobs/current_import',
      type: 'GET',
      data: { app_id: this.app.id },
    });
  },

  loadVisibleCollections: task(function* () {
    let collections = yield get('/ember/articles_collections/home_collections', {
      app_id: this.site.appId,
      help_center_id: this.site.id,
    });

    if (isPresent(collections)) {
      this.site.set('visibleCollections', collections);
    }
  }),

  getSyncSettings: task(function* () {
    let syncSettings = yield this.store.findAll('articles/article-sync-setting');
    let zendeskSyncSetting = syncSettings.find((setting) => setting.provider === 'zendesk');
    this.set('zendeskSyncSetting', zendeskSyncSetting);
  }),

  triggerManualSync: task(function* () {
    yield this.zendeskSyncSetting?.triggerManualSync();
  }),

  pauseRegularSync: task(function* () {
    try {
      yield this.zendeskSyncSetting?.pause();
      yield this.getSyncSettings.perform();

      this.notificationsService.notifyConfirmation(
        this.intl.t('articles.settings.sync.remove-sync.notification.success'),
      );
    } catch (error) {
      this.notificationsService.notifyError(
        this.intl.t('articles.settings.sync.remove-sync.notification.error'),
      );
      console.error(error);
    }
    this.set('showRemoveSyncModal', false);
    return;
  }),

  actions: {
    updateHeadline(heading) {
      this.set('site.defaultLocale.headline', heading);
    },
    setColor(color) {
      color = color.substring(1);
      this.set('site.themeColor', color);
    },
    cancel(accordionAPI) {
      this.site.rollbackAttributes();
      let uploadHelper = this.uploadHelper;
      uploadHelper.reset();
      accordionAPI?.closeSection();
    },
    sectionDidOpen(sectionName) {
      this.intercomEventService.trackAnalyticsEvent({
        action: 'opened',
        object: `${sectionName}-section`,
      });
    },
    sectionDidClose(sectionName) {
      this.intercomEventService.trackAnalyticsEvent({
        action: 'closed',
        object: `${sectionName}-section`,
      });
    },
    save() {
      let domainChange = this.site.changedAttributes()['customDomain'];
      let domainAndPrefixChange = this.site.changedAttributes()['customDomainAndPathPrefix'];
      let defaultLanguageChange = this.site.changedAttributes()['locale'];
      let supportedLocalesChange = this.site.changedAttributes()['supportedLocales'];
      let supportedLocalesDiff = this._getSupportedLocalesDiff(
        supportedLocalesChange,
        defaultLanguageChange,
      );
      let homeUrlChange = this.site.changedAttributes()['homeUrl'];
      let previousSegmentedContentTurnedOn = this.site.segmentedContentTurnedOn;
      let ticketsPortalRuleset = this.site.ticketsPortalRuleset;

      this._saveMedia.perform().then((shouldSave) => {
        if (!shouldSave) {
          return;
        }

        this._saveSite
          .perform()
          .then(() => {
            if (domainChange) {
              this._trackDomainChangeEvent(domainChange);
            }
            if (domainAndPrefixChange) {
              this._trackDomainAndPrefixChangeEvent(domainAndPrefixChange);
            }
            if (defaultLanguageChange) {
              this._trackDefaultLanguageChangeEvent(defaultLanguageChange);
            }
            if (supportedLocalesChange) {
              this._trackSupportedLocalesChangeEvent(supportedLocalesDiff);
            }
            if (homeUrlChange) {
              this._trackHomeUrlChangeEvent(homeUrlChange);
            }
            if (!previousSegmentedContentTurnedOn && this.site.segmentedContentTurnedOn) {
              this._trackAudienceTargetingChange();
            }
            if (this.get('site.ticketsPortalTurnedOn') && !!this.get('site.ticketsPortalRuleset')) {
              this.get('site.ticketsPortalRuleset').fetchAudiencePreviewTask.perform();
            }
            if (ticketsPortalRuleset?.isNew) {
              ticketsPortalRuleset.deleteRecord();
            }
            this.site.setupDefaultPredicateGroup();
          })
          .catch((err) => {
            this._captureAndDisplayError(err);
          });
      });
    },
    toggleHelpCenterWebSite() {
      let newValue = !this.get('site.websiteTurnedOn');
      if (newValue) {
        this.paywallService
          .paywall({ featureName: 'help_center' })
          .then(() => {
            this._toggleHelpCenterWebsiteModal(newValue);
          })
          .catch(this.paywallService.handleError);
      } else {
        this._toggleHelpCenterWebsiteModal(newValue);
      }
    },
  },
});
