/* RESPONSIBLE TEAM: team-channels */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable ember/no-controllers */
/* eslint-disable ember/no-classic-classes */
import { alias, readOnly } from '@ember/object/computed';
import { inject as service } from '@ember/service';
import Controller, { inject as controller } from '@ember/controller';
export default Controller.extend({
  router: service(),
  commonPrimaryNavBarService: service(),
  appController: controller('apps/app'),
  app: alias('appController.model'),
  currentAdmin: readOnly('app.currentAdmin'),
  currentRouteName: readOnly('router.currentRouteName'),
});
