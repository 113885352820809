/* RESPONSIBLE TEAM: team-proactive-support */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable ember/no-actions-hash */
/* eslint-disable ember/require-computed-property-dependencies */
/* eslint-disable ember/no-controllers */
import ReportBase from 'embercom/controllers/apps/app/reports/base/report';
import { inject as service } from '@ember/service';
import { SIGNAL_CONFIGS } from 'embercom/models/reporting/signal';
import { improvementDirections } from 'embercom/components/reporting/element/summary-comparison';
import {
  botInboxAndOfficeHoursField,
  timeTimeToCloseField,
} from 'embercom/lib/reporting/office-hours-field';
import {
  LEADS_CREATED_FILTERS,
  CONVERSATION_RATINGS,
  LEADS_CREATED,
  NEW_CONVERSATIONS,
} from 'embercom/lib/reporting/queries';
import { computed } from '@ember/object';
import { FILTER_PROPERTY_PLACEHOLDER } from 'embercom/components/reporting/flexible/chart';
import {
  stackNestedBucketsToPercents,
  createAmazingOrGoodValueForNestedBuckets,
  useAggregateBucketValueAsBucketCount,
} from 'embercom/lib/reporting/signal-transformations';
import { not, readOnly } from '@ember/object/computed';
import { units } from 'embercom/lib/reporting/flexible/formatters';
import {
  MEDIAN,
  MEAN,
  MAX,
  MIN,
  FIRST_USER_CONVERSATION_PART_CREATED_AT,
} from 'embercom/lib/reporting/flexible/constants';
import ViewConfig, { GROUPINGS } from 'embercom/lib/reporting/flexible/view-config';

export default ReportBase.extend({
  router: service(),
  reportingService: service(),
  bootOptionsService: service(),
  appService: service(),
  app: readOnly('appService.app'),
  linksEnabled: not('bootOptionsService.embeddedContent'),

  actions: {
    transitionToAnswerBot() {
      this.router.transitionTo('apps.app.automation.resolution-bot');
    },
  },
  transforms: [
    useAggregateBucketValueAsBucketCount,
    createAmazingOrGoodValueForNestedBuckets,
    stackNestedBucketsToPercents,
  ],

  createdAtFieldName: computed('app.features', function () {
    return FIRST_USER_CONVERSATION_PART_CREATED_AT;
  }),

  columnChartConfigs: computed('range', 'botInboxTime', function () {
    return {
      firstResponseTime: {
        viewConfig: this.buildViewConfig({
          unit: units.seconds,
          groupings: { defaultOption: GROUPINGS[this.createdAtFieldName] },
        }),
        dataConfig: {
          xAxis: {
            type: 'temporal',
            data: {
              interval: FILTER_PROPERTY_PLACEHOLDER,
              property: this.createdAtFieldName,
            },
          },
          series: [
            {
              name: 'first_time_response_columnchart',
              source: 'initial_admin_reply',
              type: 'median',
              data: {
                property: botInboxAndOfficeHoursField(
                  'response_time',
                  this.settings.reportingExcludeOooHours,
                  this.botInboxTime === 'excluded',
                ),
              },
              time: {
                property: this.createdAtFieldName,
                start: FILTER_PROPERTY_PLACEHOLDER,
                end: FILTER_PROPERTY_PLACEHOLDER,
              },
            },
          ],
        },
      },
      timeToClose: {
        viewConfig: this.buildViewConfig({
          unit: units.seconds,
          groupings: {
            defaultOption: GROUPINGS[FIRST_USER_CONVERSATION_PART_CREATED_AT],
          },
        }),
        dataConfig: {
          xAxis: {
            type: 'temporal',
            data: {
              interval: FILTER_PROPERTY_PLACEHOLDER,
              property: FIRST_USER_CONVERSATION_PART_CREATED_AT,
            },
          },
          series: [
            {
              name: 'time_to_close_columnchart',
              source: 'conversation_closed',
              type: 'median',
              data: {
                property: botInboxAndOfficeHoursField(
                  timeTimeToCloseField(this.botInboxTimeExcluded),
                  this.settings.reportingExcludeOooHours,
                  this.botInboxTimeExcluded,
                ),
              },
              time: {
                property: FIRST_USER_CONVERSATION_PART_CREATED_AT,
                start: FILTER_PROPERTY_PLACEHOLDER,
                end: FILTER_PROPERTY_PLACEHOLDER,
              },
            },
          ],
        },
      },
    };
  }),

  counterDefaultConfigs: computed('range', 'botInboxTime', function () {
    return {
      firstResponseTimeCounter: {
        viewConfig: this.buildViewConfig({
          unit: units.minute,
          groupings: {
            defaultOption: {
              ...GROUPINGS[this.createdAtFieldName],
              isComparison: true,
            },
          },
          counter: {
            counter_conversations_first_response_time: {
              improvementDirection: improvementDirections.DECREASING,
            },
          },
        }),
        dataConfig: {
          series: [
            {
              name: 'counter_conversations_first_response_time',
              source: 'initial_admin_reply',
              type: 'median',
              data: {
                property: botInboxAndOfficeHoursField(
                  'response_time',
                  this.settings.reportingExcludeOooHours,
                  this.botInboxTime === 'excluded',
                ),
              },
              time: {
                property: this.createdAtFieldName,
                start: FILTER_PROPERTY_PLACEHOLDER,
                end: FILTER_PROPERTY_PLACEHOLDER,
              },
            },
          ],
        },
      },
      timeToCloseCounter: {
        viewConfig: this.buildViewConfig({
          unit: units.minute,
          groupings: {
            defaultOption: {
              ...GROUPINGS[FIRST_USER_CONVERSATION_PART_CREATED_AT],
              isComparison: true,
            },
          },
          counter: {
            counter_conversations_time_to_close: {
              improvementDirection: improvementDirections.DECREASING,
            },
          },
        }),
        dataConfig: {
          series: [
            {
              name: 'counter_conversations_time_to_close',
              source: 'conversation_closed',
              type: 'median',
              data: {
                property: botInboxAndOfficeHoursField(
                  timeTimeToCloseField(this.botInboxTimeExcluded),
                  this.settings.reportingExcludeOooHours,
                  this.botInboxTimeExcluded,
                ),
              },
              time: {
                property: FIRST_USER_CONVERSATION_PART_CREATED_AT,
                start: FILTER_PROPERTY_PLACEHOLDER,
                end: FILTER_PROPERTY_PLACEHOLDER,
              },
            },
          ],
        },
      },
    };
  }),

  queries: computed('settings', 'botInboxTime', function () {
    return {
      newConversation: NEW_CONVERSATIONS,
      conversationsParticipatedIn: SIGNAL_CONFIGS.CONVERSATIONS_PARTICIPATED_IN,
      conversationRatings: CONVERSATION_RATINGS,
      leadsCreated: LEADS_CREATED,
    };
  }),

  buildViewConfig({ unit, displayUnit = '', groupings, counter = {} }) {
    let viewConfig = new ViewConfig();

    viewConfig.aggregations = { defaultOption: MEDIAN, rest: [MIN, MAX, MEAN] };
    viewConfig.groupings = groupings;
    viewConfig.formatUnit = { unit, displayUnit };
    viewConfig.counter = counter;

    return viewConfig;
  },

  filters: {
    leadsCreated: LEADS_CREATED_FILTERS,
  },

  flexibleFilters: {},
});
