/* RESPONSIBLE TEAM: team-product-guidance */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable ember/no-classic-classes */
import { inject as service } from '@ember/service';
import Controller from '@ember/controller';

export default Controller.extend({
  queryParams: {
    onboardingHome: {
      as: 'onboarding_home',
      type: 'boolean',
    },
    newMessengerPreview: {
      as: 'new_messenger_preview',
      type: 'boolean',
    },
  },

  appService: service(),

  onboardingHome: true,
});
