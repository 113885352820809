/* RESPONSIBLE TEAM: team-proactive-support */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable ember/no-controllers */
/* eslint-disable ember/no-classic-classes */
import Controller, { inject as controller } from '@ember/controller';
import { readOnly } from '@ember/object/computed';
import PALETTE from '@intercom/pulse/lib/palette';

export default Controller.extend({
  parentController: controller('apps.app.reports.lead-generation.custom-bots'),
  signals: readOnly('parentController.signals'),
  range: readOnly('parentController.range'),
  palette: PALETTE,
});
