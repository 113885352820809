/* RESPONSIBLE TEAM: team-data-interop */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable ember/no-classic-classes */
import Controller from '@ember/controller';

export default Controller.extend({
  queryParams: {
    appId: 'app_id',
    clientId: 'client_id',
    state: 'state',
    redirectURI: 'redirect_uri',
  },
  appId: null,
  clientId: null,
  state: null,
  redirectURI: null,
});
