/* RESPONSIBLE TEAM: team-proactive-support */
import PerformanceReportBase from 'embercom/controllers/apps/app/reports/base/performance-report';
import { FILTER_PROPERTY_PLACEHOLDER } from 'embercom/components/reporting/flexible/chart';
import { officeHoursField } from 'embercom/lib/reporting/office-hours-field';
import { improvementDirections } from 'embercom/components/reporting/element/summary-comparison';
import { units } from 'embercom/lib/reporting/flexible/formatters';
import {
  MEDIAN,
  MEAN,
  MAX,
  MIN,
  COUNT,
  CLOSE_DATE,
  MEETING_BOOKED_AT,
} from 'embercom/lib/reporting/flexible/constants';
import { SIGNAL_CONFIGS } from 'embercom/models/reporting/signal';
import ViewConfig, { GROUPINGS } from 'embercom/lib/reporting/flexible/view-config';
import { inject as service } from '@ember/service';

export default class SalesController extends PerformanceReportBase {
  @service router;
  @service intl;

  queries = {
    messageROI: SIGNAL_CONFIGS.MESSAGE_ROI,
  };

  get filteredByAdmin() {
    return this.teammateIds?.length === 1;
  }

  get counterDefaultConfigs() {
    return {
      timeToCloseOpportunityCounter: {
        viewConfig: this.buildViewConfig({
          unit: units.minute,
          aggregations: { defaultOption: MEDIAN, rest: [MIN, MAX, MEAN] },
          groupings: { defaultOption: { ...GROUPINGS[CLOSE_DATE], isComparison: true } },
          counter: {
            opportunities_created_counter: {
              improvementDirection: improvementDirections.DECREASING,
            },
          },
        }),
        dataConfig: {
          series: [
            {
              name: 'opportunities_created_counter',
              source: 'opportunity_created',
              type: 'median',
              data: {
                property: officeHoursField('time_to_close', this.settings.reportingExcludeOooHours),
              },
              time: {
                property: CLOSE_DATE,
                start: FILTER_PROPERTY_PLACEHOLDER,
                end: FILTER_PROPERTY_PLACEHOLDER,
              },
              filter: {
                type: 'and',
                filters: [
                  {
                    type: 'category',
                    data: {
                      property: 'closed',
                      values: [true],
                    },
                  },
                  {
                    type: 'exists',
                    data: {
                      property: 'user_id',
                    },
                  },
                ],
              },
            },
          ],
        },
      },
      meetingsBookedCounter: {
        viewConfig: this.buildViewConfig({
          unit: units.value,
          aggregations: { defaultOption: COUNT },
          groupings: { defaultOption: { ...GROUPINGS[MEETING_BOOKED_AT], isComparison: true } },
          counter: {
            meetings_booked_counter: {
              improvementDirection: improvementDirections.INCREASING,
            },
          },
        }),
        dataConfig: {
          series: [
            {
              name: 'meetings_booked_counter',
              source: 'meeting_booked',
              type: 'count',
              data: {
                property: 'teammate_participant_id',
              },
              time: {
                property: MEETING_BOOKED_AT,
                start: FILTER_PROPERTY_PLACEHOLDER,
                end: FILTER_PROPERTY_PLACEHOLDER,
              },
            },
          ],
        },
      },
    };
  }

  get columnChartConfigs() {
    return {
      timeToCloseOpportunity: {
        viewConfig: this.buildViewConfig({
          unit: units.seconds,
          aggregations: { defaultOption: MEDIAN, rest: [MIN, MAX, MEAN] },
          groupings: { defaultOption: GROUPINGS[CLOSE_DATE] },
        }),
        dataConfig: {
          xAxis: {
            type: 'temporal',
            data: {
              interval: FILTER_PROPERTY_PLACEHOLDER,
              property: CLOSE_DATE,
            },
          },
          series: [
            {
              name: 'opportunities_created_columnchart',
              source: 'opportunity_created',
              type: 'median',
              data: {
                property: officeHoursField('time_to_close', this.settings.reportingExcludeOooHours),
              },
              time: {
                property: CLOSE_DATE,
                start: FILTER_PROPERTY_PLACEHOLDER,
                end: FILTER_PROPERTY_PLACEHOLDER,
              },
            },
          ],
          filter: {
            type: 'and',
            filters: [
              {
                type: 'category',
                data: {
                  property: 'closed',
                  values: [true],
                },
              },
              {
                type: 'exists',
                data: {
                  property: 'user_id',
                },
              },
            ],
          },
        },
      },
      meetingsBooked: {
        viewConfig: this.buildViewConfig({
          aggregations: { defaultOption: COUNT },
          unit: units.value,
          displayUnit: this.intl.t('apps.app.reports.lead-generation.sales.teammate-participant'),
          groupings: { defaultOption: GROUPINGS[MEETING_BOOKED_AT] },
        }),
        dataConfig: {
          xAxis: {
            type: 'temporal',
            data: {
              interval: FILTER_PROPERTY_PLACEHOLDER,
              property: MEETING_BOOKED_AT,
            },
          },
          series: [
            {
              name: 'meetings_booked_columnchart',
              source: 'meeting_booked',
              type: 'count',
              data: {
                property: 'teammate_participant_id',
              },
              time: {
                property: MEETING_BOOKED_AT,
                start: FILTER_PROPERTY_PLACEHOLDER,
                end: FILTER_PROPERTY_PLACEHOLDER,
              },
            },
          ],
        },
      },
    };
  }

  get salesReportBaseRoute() {
    return 'apps.app.reports.lead-generation.sales';
  }

  buildViewConfig({ unit, displayUnit = '', aggregations, groupings, counter = {} }) {
    let viewConfig = new ViewConfig();

    viewConfig.aggregations = aggregations;
    viewConfig.groupings = groupings;
    viewConfig.formatUnit = { unit, displayUnit };
    viewConfig.counter = counter;

    return viewConfig;
  }
}
