/* RESPONSIBLE TEAM: team-tickets-1 */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable ember/no-actions-hash */
/* eslint-disable ember/no-controllers */
/* eslint-disable ember/no-classic-classes */
import { reads, readOnly, filter } from '@ember/object/computed';
import Controller, { inject as controller } from '@ember/controller';
import { action } from '@ember/object';
export default Controller.extend({
  length: reads('model.length'), //TODO: GJ: remove this when all controllers that use `CursorPagination` are `Ember.Controller`
  userController: controller('apps/app/users/user'),
  isLoading: false,
  conversations: readOnly('model'),
  isPaginating: false,
  hasMoreConversationsToLoad: true,
  filteredConversations: filter('conversations', function (item) {
    return item.get('type') !== 'note_created';
  }),

  nextPage: action(function () {
    if (this.hasMoreConversationsToLoad) {
      this.send('paginate');
    }
  }),

  actions: {
    nextPage() {
      if (this.hasMoreConversationsToLoad) {
        this.send('paginate');
      }
    },
  },
});
