/* RESPONSIBLE TEAM: team-frontend-tech */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
/* eslint-disable ember/no-actions-hash */
/* eslint-disable promise/prefer-await-to-then */
/* eslint-disable ember/no-controllers */
import { filterBy, notEmpty } from '@ember/object/computed';
import { inject as service } from '@ember/service';
import IntegrateBaseController from 'embercom/controllers/apps/app/integrations-hub/integrate-base-controller';

let ApiKeysController = IntegrateBaseController.extend({
  notificationsService: service('notificationsService'),
  appService: service(),
  apiKeys: filterBy('model', 'isNew', false),
  appApiKeys: filterBy('apiKeys', 'testApp', false),
  testAppApiKeys: filterBy('apiKeys', 'testApp', true),
  testAppPresent: notEmpty('app.test_app_id'),
  actions: {
    deleteKey(apiKey) {
      apiKey.destroyRecord().then(
        () => {
          this.notificationsService.notifyConfirmation('API key deleted successfully.');
        },
        () => {
          this.notificationsService.notifyError('Unable to delete API key.');
        },
      );
    },
  },
});

export default ApiKeysController;
