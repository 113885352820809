/* RESPONSIBLE TEAM: team-ai-agent */
import Controller from '@ember/controller';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';

export default class SettingsController extends Controller {
  queryParams = ['section'];

  @service declare modalService: any;
  @service declare appService: any;

  @tracked declare section: string;
  @tracked declare previewConfiguration: any;
  @tracked declare aiAgentQuickReplySettings: any;
  @tracked declare aiAgentSettings: any;
  @tracked declare aiAgentToneOfVoiceSettings: any;
  @tracked declare aiAgentMultilingualSettings: any;
  @tracked declare languages: any;
  @action completeConversationImportFlow() {
    this.send('refreshRoute');
    this.modalService.closeModal();
  }
}
