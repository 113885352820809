/* RESPONSIBLE TEAM: team-frontend-tech */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable ember/no-actions-hash */
/* eslint-disable promise/prefer-await-to-then */
/* eslint-disable ember/require-computed-property-dependencies */
/* eslint-disable ember/no-controllers */
/* eslint-disable ember/no-observers */
import { computed } from '@ember/object';
import { isEmpty } from '@ember/utils';
import { reads, readOnly, and, not } from '@ember/object/computed';
import { inject as controller } from '@ember/controller';
import AccountControllerBase from 'embercom/controllers/apps/app/account/base/account-controller-base';
import { inject as service } from '@ember/service';
import { task } from 'ember-concurrency';
import { encodedVapidToken } from 'embercom/lib/vapid-token';
import UserAgentDetector from '@intercom/pulse/lib/user-agent-detector';
import Metrics from 'embercom/models/metrics';
import Admin from 'embercom/models/admin';

let AccountNotificationsController = AccountControllerBase.extend({
  intercomEventService: service(),
  notificationsService: service(),
  sounds: service(),
  // eslint-disable-next-line @intercom/intercom/no-legacy-modal
  modalService: service(),
  intl: service(),
  appsController: controller('apps'),
  appController: controller('apps.app'),
  admin: reads('appsController.model'),
  app: reads('appController.model'),
  get saveSuccessNotificationMessage() {
    return this.intl.t('account.notifications.settings-saved');
  },
  noWebPushSupport: isEmpty(window.PushManager),
  get noWebPushSupportMessage() {
    return this.intl.t('account.notifications.notifications-not-supported');
  },
  hasWebPushSupport: not('noWebPushSupport'),

  accountSpecificNotificationUrl: readOnly('model.accountOwnerSpecificPageNotificationUrl'),
  accountSpecificNotificationEnabled: readOnly('model.accountOwnerSpecificPageNotificationEnabled'),
  accountSpecificNotificationDisabled: not('accountSpecificNotificationEnabled'),
  accountSpecificNotificationUrlHasError: computed(
    'accountSpecificNotificationUrl',
    'accountSpecificNotificationEnabled',
    function () {
      return (
        isEmpty(this.accountSpecificNotificationUrl) && this.accountSpecificNotificationEnabled
      );
    },
  ),
  specificPageNotificationEditorAttrs: computed('accountSpecificNotificationUrl', function () {
    return {
      accountSpecificNotificationUrl: this.accountSpecificNotificationUrl,
      updateSpecificPageNotificationUrl: this.get('actions.updateSpecificPageNotificationUrl'),
      parentContext: this,
    };
  }),

  get notificationSoundOptions() {
    return [
      {
        text: this.intl.t('account.notifications.notification-sound-names.default'),
        value: 'default',
      },
      { text: this.intl.t('account.notifications.notification-sound-names.blip'), value: 'blip' },
      { text: this.intl.t('account.notifications.notification-sound-names.bop'), value: 'bop' },
      { text: this.intl.t('account.notifications.notification-sound-names.clack'), value: 'clack' },
      { text: this.intl.t('account.notifications.notification-sound-names.hiss'), value: 'hiss' },
      { text: this.intl.t('account.notifications.notification-sound-names.shake'), value: 'shake' },
      { text: this.intl.t('account.notifications.notification-sound-names.tink'), value: 'tink' },
    ];
  },

  selectedNotificationSoundId: computed(function () {
    let soundId = this.model.notificationSound;

    if (soundId && this.sounds.soundIds.includes(soundId)) {
      return soundId;
    }
    return 'default';
  }),

  hasChanges: readOnly('model.hasDirtyAttributes'),
  hasUnsavedChanges() {
    return this.get('model.hasDirtyAttributes');
  },

  revertUnsavedChanges() {
    this.model.rollbackAttributes();
  },

  showRespondTooltip: and(
    'admin.hasMoreThanOneApp',
    'admin.inboxIsNotActiveOnEveryApp',
    'admin.inboxIsActiveOnAnyApp',
  ),
  showGlobalNotificationNote: and('hasChanges', 'admin.hasMoreThanOneApp'),

  receivesDailySignupEmail: computed('model.dailySummaryEmailFor', {
    get() {
      return this.get('model.dailySummaryEmailFor') !== null;
    },
    set(key, value) {
      this.set('model.dailySummaryEmailFor', value ? 'users' : null);
      return value;
    },
  }),

  encodedVapidToken: computed('ENV.environment', function () {
    return encodedVapidToken();
  }),

  savePushSubscription(pushSubscription, notify = true) {
    return this.model
      .savePushSubscription(this.app, this.admin, pushSubscription)
      .then(() => {
        if (notify) {
          this.notificationsService.notifyConfirmation(
            this.intl.t('account.notifications.save-confirmation'),
          );
        }
      })
      .catch(() => {
        this.notificationsService.notifyError(this.intl.t('account.notifications.save-error'));
      });
  },

  enablePushNotifications: task(function* () {
    let serviceWorkerRegistration = yield navigator.serviceWorker.ready;
    let pushManager = serviceWorkerRegistration.pushManager;
    let options = { userVisibleOnly: true };
    if (UserAgentDetector.isChrome()) {
      options.applicationServerKey = this.encodedVapidToken;
    }
    let permissionState = yield pushManager.permissionState(options);
    // Permission has been previously denied, nothing we can do here.
    if (permissionState === 'denied') {
      return this.modalService.openModal('account/notifications-denied-modal');
    }
    let existingPushSubscription = yield pushManager.getSubscription();
    if (existingPushSubscription) {
      if (existingPushSubscription.endpoint.startsWith('https://android.googleapis.com/gcm/send')) {
        Metrics.capture({ increment: ['gcm_unsubscribe_token'] });
        yield existingPushSubscription.unsubscribe();
      } else {
        this.savePushSubscription(existingPushSubscription, false);
        return;
      }
    }
    // User is going to be prompted, show an informational modal
    if (permissionState === 'prompt') {
      this.modalService.openModal('account/notifications-ask-modal');
    }
    try {
      let pushSubscription = yield pushManager.subscribe(options);
      this.modalService.closeModal();
      this.savePushSubscription(pushSubscription);
    } catch (e) {
      // permission was denied
      this.modalService.openModal('account/notifications-denied-modal');
    }
  }).drop(),

  afterSave() {
    Admin.meWithoutRedirect();
  },

  actions: {
    webPushSettingsChanged(notificationType, { target: { checked: isChecked } }) {
      this.set(notificationType, isChecked);
      if (isChecked && window.PushManager !== undefined) {
        this.enablePushNotifications.perform();
      }
    },
    toggleBrowserAudioAlerts() {
      this.model.toggleProperty('browserAudioAlerts');
    },
    updateSpecificPageNotificationUrl(url) {
      let context = this.get('componentAttrs.parentContext');
      context.set('model.accountOwnerSpecificPageNotificationUrl', url);
    },
    toggleSpecificPageSetting(value) {
      this.set('model.accountOwnerSpecificPageNotificationEnabled', value);
    },
    setNotificationSound(value) {
      this.set('model.notificationSound', value);
      this.sounds.adminNotificationSounds[value].play();
    },
  },
});

export default AccountNotificationsController;
