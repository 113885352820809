/* RESPONSIBLE TEAM: team-customer-data-platform */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable ember/no-new-mixins */
import Mixin from '@ember/object/mixin';

export default Mixin.create({
  actions: {
    revert() {
      this.model.rollbackAttributes();
    },

    doSearch(searchItem) {
      this.searchController.send('doSearch', searchItem);
    },
    exitSearch() {
      this.searchController.send('exitSearch');
    },
  },
});
