/* RESPONSIBLE TEAM: team-proactive-support */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
/* eslint-disable ember/no-new-mixins */
import { computed } from '@ember/object';
import { or, empty, and, notEmpty } from '@ember/object/computed';
import Mixin from '@ember/object/mixin';
import { changeGate, ternaryToProperty, trim } from '@intercom/pulse/lib/computed-properties';

export default Mixin.create({
  search_term: '',
  admin_id: '',
  isSearch: or('hasAdminId', 'hasSearchTerm'),
  searchPageTitle: computed('searchTermOrAdminName', function () {
    return `Search results for "${this.searchTermOrAdminName}"`;
  }),
  searchTermOrAdminName: ternaryToProperty(
    'hasAdminId',
    'searchTermInputFromAdminId',
    'trimmedSearchTerm',
  ),
  adminIdIsEmpty: empty('admin_id'),
  searchTermIsEmpty: empty('search_term'),
  searchTermAndAdminIdAreEmpty: and('adminIdIsEmpty', 'searchTermIsEmpty'),
  trimmedSearchTerm: trim('search_term'),
  gatedSearchTerm: changeGate('trimmedSearchTerm', { sync: true }),
  hasSearchTerm: notEmpty('gatedSearchTerm'),
  hasAdminId: notEmpty('admin_id'),
  searchTermInputFromAdminId: computed('admin_id', 'app.admins', function () {
    if (this.get('app.admins')) {
      return this.get('app.admins').findBy('id', this.admin_id).get('name');
    }
  }),
  resetSearchTermInput() {
    let searchTerm = this.search_term;
    let adminId = this.admin_id;
    let searchTermInput;
    if (adminId) {
      searchTermInput = `From: ${this.searchTermInputFromAdminId}`;
    } else {
      searchTermInput = searchTerm;
    }
    this.set('search_term_input', searchTermInput);
  },
  actions: {
    resetSearchTerm() {
      this.setProperties({
        search_term_input: '',
        search_term: '',
        admin_id: '',
      });
      this.reset();
    },
  },
});
