/* RESPONSIBLE TEAM: team-frontend-tech */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable ember/no-new-mixins */
import { inject as service } from '@ember/service';
import Mixin from '@ember/object/mixin';
export default Mixin.create({
  intercomEventService: service(),
  _trackMessageToSmallUserCount(count, metadata) {
    let type = '';
    if (count === 1) {
      type = 'single';
    } else if (count > 1 && count <= 20) {
      type = '2-to-20';
    } else if (count > 20 && count <= 50) {
      type = '21-to-50';
    } else {
      return;
    }
    let eventName = `message-to-${type}-user${type === 'single' ? '' : 's'}`;
    this.intercomEventService.trackEvent(eventName, metadata);
  },
});
