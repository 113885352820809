/* RESPONSIBLE TEAM: team-customer-data-platform */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable ember/no-controllers */
/* eslint-disable ember/no-classic-classes */
import { reads } from '@ember/object/computed';
import Controller, { inject as controller } from '@ember/controller';
let SegmentsController = Controller.extend({
  appController: controller('apps/app'),
  app: reads('appController.model'),
});

export default SegmentsController;
