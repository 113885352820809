/* RESPONSIBLE TEAM: team-workflows */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable ember/no-controllers */
import { readOnly } from '@ember/object/computed';
import { inject as service } from '@ember/service';
import AppStoreController from '../../base/appstore';
import { computed } from '@ember/object';
import { ternaryToProperty } from '@intercom/pulse/lib/computed-properties';

export default AppStoreController.extend({
  appService: service(),
  commonPrimaryNavBarService: service(),
  app: readOnly('appService.app'),

  installedAppPackages: computed('model.appPackages.@each.isInstalled', 'installed', function () {
    return this.get('model.appPackages').filter((app) => app.get('isInstalled'));
  }),
  apps: ternaryToProperty('installed', 'installedAppPackages', 'model.appPackages'),
});
