/* RESPONSIBLE TEAM: team-workflows */
import TaskBotBase from 'embercom/controllers/apps/app/operator/task-bots';

export default TaskBotBase.extend({
  queryParams: ['section'],
  groupedTasks: [
    [
      'team-availability-leads',
      'conversation-routing',
      'contact-collection',
      'qualify-leads',
      'article-suggestions-leads',
    ],
    ['article-auto-reaction-leads'],
    ['conversation-rating-leads'],
  ],
});
