/* RESPONSIBLE TEAM: team-help-desk-experience */
import Controller from '@ember/controller';
import { tracked } from '@glimmer/tracking';

export default class NewConversationController extends Controller {
  queryParams = ['recipient', 'ticketTypeId', 'forwardedPartId'];

  @tracked recipient?: string;
  @tracked ticketTypeId?: string;
  @tracked forwardedPartId?: string;
}
