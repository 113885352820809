/* RESPONSIBLE TEAM: team-pricing-and-packaging */
import Controller, { inject as controller } from '@ember/controller';
import { tracked } from '@glimmer/tracking';

export default class Billing extends Controller {
  @controller('apps.app') appController;

  queryParams = ['addressModalIsOpen', 'creditCardModalIsOpen'];

  @tracked addressModalIsOpen = false;
  @tracked creditCardModalIsOpen = false;

  get app() {
    return this.appController.app;
  }

  get customer() {
    return this.model;
  }
}
