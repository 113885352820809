/* RESPONSIBLE TEAM: team-channels */
import IntegrateBaseController from 'embercom/controllers/apps/app/integrations-hub/integrate-base-controller';
import { inject as service } from '@ember/service';
import type IntlService from 'embercom/services/intl';
import { restartableTask } from 'ember-concurrency-decorators';

export default class OauthTokensController extends IntegrateBaseController {
  @service declare intl: IntlService;
  @service declare notificationsService: $TSFixMe;

  declare model: $TSFixMe;
  declare titleToken: string;

  queryParams = [];

  get oauthTokens() {
    return this.model;
  }

  get showTable() {
    return !!this.model;
  }

  get emptyState() {
    return !this.showTable;
  }

  @restartableTask
  *revoke(oauthToken: $TSFixMe) {
    try {
      yield oauthToken.destroyRecord();
    } catch (err) {
      this.notificationsService.notifyError(this.intl.t('account.oauth-tokens.revoke-error'));
    }
  }
}
