/* RESPONSIBLE TEAM: team-proactive-support */

import AllOutboundListController from 'embercom/controllers/apps/app/outbound/all';
import { inject as service } from '@ember/service';

export default class OutboundViewsController extends AllOutboundListController {
  @service outboundHomeService;

  queryParams = [];
  get bannerData() {
    let bannerData = super.bannerData;

    if (this.outboundHomeService.view) {
      let savedViewData = this.outboundHomeService.view.savedViewData;

      if (savedViewData.translation) {
        bannerData.pageTitle = this.intl.t(
          `content-service.saved-views.${savedViewData.translation}`,
        );
      } else {
        bannerData.pageTitle = this.outboundHomeService.view.name;
      }
    }
    return bannerData;
  }

  _setQueryParams() {
    this.set('hideSeries', null);
    this.set('selectedObjectTypes', null);
  }
}
