/* RESPONSIBLE TEAM: team-workflows */
import Controller from '@ember/controller';
import { tracked } from '@glimmer/tracking';

export default class EditRulesController extends Controller {
  queryParams = ['mode'];
  @tracked mode = '';

  get models(): $TSFixMe {
    return this.model;
  }

  get workflow() {
    return this.models.workflow;
  }

  get actionEditorContext() {
    return {
      isRulePage: false,
      canRemoveAssignee: true,
      allowSlaOnDelete: true,
      isWorkflows: true,
      workflowConnectorActions: this.models.workflowConnectorActions,
      conversationSlas: this.models.conversationSlas,
      officeHoursSchedules: this.models.officeHoursSchedules,
      conversationCustomAttributes: this.models.conversationCustomAttributes,
    };
  }
}
