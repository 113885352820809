/* RESPONSIBLE TEAM: team-workflows */
import Controller, { inject as controller } from '@ember/controller';
import { tracked } from '@glimmer/tracking';

export default class WorkflowsOverviewController extends Controller {
  queryParams = [
    'title',
    'state',
    'audience',
    'tags',
    // https://github.com/emberjs/ember.js/issues/4570#issuecomment-226802158
    { targetChannels: { type: 'array' } },
    { parallel: { type: 'boolean' } },
    { hasFin: { type: 'boolean' } },
  ];
  @tracked title = null;
  @tracked state = null;
  @tracked audience = null;
  @tracked tags = null;
  @tracked targetChannels = null;
  @tracked parallel = null;
  @tracked hasFin = null;
  @controller('apps.app.automation') automationController;
}
