/* RESPONSIBLE TEAM: team-product-guidance */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
/* eslint-disable ember/no-actions-hash */
/* eslint-disable ember/no-classic-classes */
import Controller from '@ember/controller';
import { inject as service } from '@ember/service';
import { readOnly } from '@ember/object/computed';
import { computed, action } from '@ember/object';
import { task } from 'ember-concurrency';
import ajax, { put } from 'embercom/lib/ajax';
import FOOTER_CONTENT from 'embercom/lib/home/guide-footer-content';
import {
  ALL_OF_INTERCOM,
  ALL_OF_INTERCOM_VBP,
  ONBOARD_AND_ENGAGE,
  GUIDES_WITH_VIDEO_HEADER,
} from 'embercom/lib/home/guides';

const ALL_OF_INTERCOM_GUIDE_IDENTIFIERS = [ALL_OF_INTERCOM, ALL_OF_INTERCOM_VBP];

export default Controller.extend({
  appService: service(),
  intercomEventService: service(),
  app: readOnly('appService.app'),
  queryParams: ['app_package_code'],
  app_package_code: null,

  initiallyOpenStep: null,
  resetModalOpen: false,

  guideIdentifier: readOnly('model.identifier'),

  guideIsAllOfIntercom: computed('guideIdentifier', function () {
    return ALL_OF_INTERCOM_GUIDE_IDENTIFIERS.includes(this.guideIdentifier);
  }),

  isGuideWithVideoHeader: computed('guideIdentifier', function () {
    return GUIDES_WITH_VIDEO_HEADER.includes(this.guideIdentifier);
  }),

  guideIsDefault: readOnly('guideIsAllOfIntercom'),
  shouldShowEscapeHatch: computed(
    'guideIsDefault',
    'isGuideWithVideoHeader',
    'model.forceAllGuide',
    function () {
      return (this.model.forceAllGuide || !this.guideIsDefault) && !this.isGuideWithVideoHeader;
    },
  ),

  footerContent: computed('guideIdentifier', function () {
    return FOOTER_CONTENT[this.guideIdentifier];
  }),

  isOnboardAndEngage: computed('guideIdentifier', function () {
    return this.guideIdentifier === ONBOARD_AND_ENGAGE;
  }),

  resetOnboardingHome: task(function* () {
    if (confirm('Are you sure you want to reset your onboarding home guide?')) {
      yield ajax({
        url: '/ember/onboarding/home/guide/reset',
        type: 'POST',
        data: JSON.stringify({
          app_id: this.get('app.id'),
        }),
      }).then(() => {
        window.location.reload();
      });
    }
  }),

  trackFooterVideoAnalyticsEvent: action(function () {
    this.intercomEventService.trackAnalyticsEvent({
      action: 'clicked',
      object: 'video_link',
      section: 'footer',
      context: this.model.id,
    });
  }),

  setForceAllGuide: task(function* (force, ev) {
    ev.preventDefault();
    this.intercomEventService.trackAnalyticsEvent({
      action: 'clicked',
      object: 'escape_hatch',
      section: 'footer',
      context: this.model.id,
      force_all_guide: force,
    });
    yield put('/ember/onboarding/home/guide_assignments', {
      app_id: this.app.id,
      force_all_guide: force,
    }).then(() => window.location.reload());
  }),

  actions: {
    setAppPackage(appPackage) {
      this.set('selectedAppPackage', appPackage);
      this.set('app_package_code', appPackage.id);
    },

    clearAppPackage() {
      this.set('selectedAppPackage', null);
      this.set('app_package_code', null);
    },
  },
});
