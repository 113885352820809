/* RESPONSIBLE TEAM: team-data-interop */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
/* eslint-disable ember/no-controllers */
/* eslint-disable ember/no-classic-classes */
import Controller, { inject as controller } from '@ember/controller';
import { inject as service } from '@ember/service';
import { alias } from '@ember/object/computed';
import { task } from 'ember-concurrency';

export default Controller.extend({
  intercomEventService: service(),
  notificationsService: service(),
  developerHub: service(),
  appPackage: alias('developerHub.appPackage'),
  webhookController: controller('apps.app.developerHub.appPackages.appPackage.webhooks'),
  addAnalyticsEvent(ping_outcome) {
    this.intercomEventService.trackAnalyticsEvent({
      object: 'app_package',
      app_package_id: this.appPackage.id,
      action: 'pinged',
      ping_outcome,
    });
  },

  sendPing: task(function* () {
    try {
      yield this.appPackage.pingWebhookDefinition();
      this.addAnalyticsEvent('success');
      this.notificationsService.notifyConfirmation('Test request sent successfully');
    } catch (e) {
      this.addAnalyticsEvent('failed');
      this.notificationsService.notifyError(
        'Unsuccessful test request. Please check that your Endpoint URL can receive notifications and check for errors.',
      );
    }
  }).drop(),
});
