/* RESPONSIBLE TEAM: team-tickets-1 */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable ember/no-controllers */
/* eslint-disable ember/use-brace-expansion */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-jquery */
import $ from 'jquery';
import { computed } from '@ember/object';
import { readOnly } from '@ember/object/computed';
import { inject as service } from '@ember/service';
import Controller, { inject as controller } from '@ember/controller';
import { fmtStyle } from '@intercom/pulse/lib/computed-properties';

export default Controller.extend({
  appService: service(),
  app: readOnly('appService.app'),
  admin: readOnly('app.currentAdmin'),

  appController: controller('apps/app'),
  respondController: controller('apps/app/inbox'),

  top: computed(
    'app.canUseFree',
    'admin.reassign_conversations',
    'app.{impersonation_session,shouldShowIntercomradeWarning}',
    'respondController.{searchIsSelected,hideAudioNotificationsBlockedBanner}',
    function () {
      let top = 0;
      if (this.get('app.impersonation_session')) {
        top += $('.interpersonation__banner').outerHeight(true) || 0;
      }

      if (this.get('app.shouldShowIntercomradeWarning')) {
        top += $('.js__intercomrade-warning').outerHeight(true) || 0;
      }

      if (this.get('admin.reassign_conversations')) {
        top += $('[data-inbox-away-mode-notifier]').outerHeight(true) || 0;
      }

      if (this.get('respondController.searchIsSelected')) {
        top += $('.nav-vertical__search__wrapper').outerHeight(true) || 0;
      }

      if (this.get('app.canUseFree')) {
        top += $('[data-inbox-inbox-essential-upgrade-banner]').outerHeight(true) || 0;
      }

      if (!this.get('respondController.hideAudioNotificationsBlockedBanner')) {
        top += $('[data-inbox-blocked-audio-notifier]').outerHeight(true) || 0;
      }

      return top;
    },
  ),
  computedStyle: fmtStyle('top: %@px;', 'top'),
});
