/* RESPONSIBLE TEAM: team-proactive-support */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
/* eslint-disable ember/no-controllers */
import Controller from '@ember/controller';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';

export default class EffectivenessController extends Controller {
  @service appService;
  @service intercomEventService;
  @service reportingService;
  @service store;
  @service intl;
  @tracked range;

  scopingTagIds = null;
  tag = null;
  teammateId = null;
  teamOrTeammate = null;

  get app() {
    return this.appService.app;
  }

  get customAttributeIds() {
    return this.conversationAttributeDescriptors.map((descriptor) => descriptor.id);
  }

  get dashboardName() {
    return this.intl.t('apps.app.reports.customer-support.effectiveness.effectiveness-title');
  }

  @action
  updateRange(range) {
    this.reportingService.updateRange(range);

    let params = {
      action: 'filtered_by_date',
      object: this.reportName,
      date_filter: this.range.selectedRange,
      start_date: range.startMoment.format('YYYY/MM/DD'),
      end_date: range.endMoment.format('YYYY/MM/DD'),
    };
    let teamOrTeammate = this.teamOrTeammate;
    if (teamOrTeammate) {
      params.models = [teamOrTeammate];
    }
    this.intercomEventService.trackAnalyticsEvent(params);
  }

  @action
  trackPageView() {
    let models = [];
    if (this.teammate) {
      models = [this.teammate];
    }
    if (this.teamOrTeammate) {
      models = [...models, this.teamOrTeammate];
    }

    this.intercomEventService.trackAnalyticsEvent({
      action: 'viewed',
      object: this.reportName,
      context: this.reportContext,
      start_date: this.range.startMoment.format('YYYY/MM/DD'),
      end_date: this.range.endMoment.format('YYYY/MM/DD'),
      filtered_tag: this.get('tag.name'),
      models,
    });
  }

  @action
  filterBarSelectionChanged(filterType, filterValues, operator) {
    //backwards compatible with existing teammate filters before the filter bar was introduced
    if (filterType === 'teammatesParticipated') {
      this.set('teammateId', filterValues);
    } else if (this.customAttributeIds.includes(filterType)) {
      this.customAttributes.updateValues(filterType, filterValues, operator);
      this.set('customAttributes', this.customAttributes.clone());
    } else {
      this.set(filterType, filterValues);
    }
  }
}
