/* RESPONSIBLE TEAM: team-workflows */
import { readOnly } from '@ember/object/computed';
import { computed } from '@ember/object';
import TaskBotBase from 'embercom/controllers/apps/app/operator/task-bots';
import { assetUrl } from '@intercom/pulse/helpers/asset-url';

export default TaskBotBase.extend({
  queryParams: ['section'],
  gitHubEnabled: readOnly('app.hasEnabledGithubIntegration'),

  previewUrl: computed('openSectionName', function () {
    switch (this.openSectionName) {
      case 'article-suggestions-inbox':
        return assetUrl('/assets/images/operator/article-suggestion-skill.png');
      case 'github-updates':
        return assetUrl('/assets/images/operator/github-skill.png');
      case 'duplicate-conversation-detection':
        return assetUrl('/assets/images/operator/duplicate-conversation-detection-skill.png');
      default:
        return assetUrl('/assets/images/operator/github-skill.png');
    }
  }),
});
