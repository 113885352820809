/* RESPONSIBLE TEAM: team-ai-agent */
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import OutboundBaseListController from 'embercom/lib/outbound/base-list-controller';
import {
  objectTypes,
  matchBehaviors,
} from 'embercom/models/data/matching-system/matching-constants';
import { EVERYONE_PREDICATE } from 'embercom/lib/operator/custom-bots/constants';
import { resolutionBotBillingFeature } from 'embercom/components/operator/answer-bot/billing-helper';

export default class AppsAppOperatorResolutionBotBehaviorsIndexController extends OutboundBaseListController {
  @service router;
  @service intl;
  @service appService;

  transitionToShowPage() {
    this.router.transitionTo(
      this.model.firstObject.routeForShowPage,
      this.model.firstObject.contentWrapperId,
    );
  }

  @action
  createBehavior() {
    let clientData =
      this.model.length <= 1
        ? { title: this.intl.t('ai-agent.profiles.editor.default-title') }
        : {};
    let rulesetParams = {
      app_id: this.app.id,
      object_type: objectTypes.resolutionBotBehavior,
      match_behavior: matchBehaviors.transient,
      object_data: {},
      role_predicate_group: { predicates: [EVERYONE_PREDICATE] },
      client_data: clientData,
    };

    this.showContentCreationModal = false;
    this.router.transitionTo('apps.app.content.new', rulesetParams);
  }

  get hasAIAnswersBehaviorLive() {
    return this.model.some((behavior) => {
      let content = behavior.contents.firstObject;
      return content?.contentData?.use_ai_answers === true && content?.isLive;
    });
  }

  get billingFeature() {
    return resolutionBotBillingFeature(this.appService.app);
  }
}
