/* RESPONSIBLE TEAM: team-proactive-support */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
/* eslint-disable ember/no-actions-hash */
/* eslint-disable ember/no-classic-classes */
import { readOnly, gt } from '@ember/object/computed';
import { inject as service } from '@ember/service';
import { computed } from '@ember/object';
import Controller from '@ember/controller';
import percent from 'embercom/lib/percentage-formatter';
import { EFFECTIVE_ANSWER_KEYS } from 'embercom/components/answers/reporting/resolution-rate';
import { ternary } from '@intercom/pulse/lib/computed-properties';

function countEffectiveAnswersFromconversationsWithAnswers(conversationsWithAnswers) {
  let aggregatedByOutcome =
    conversationsWithAnswers.aggregateSignalAsStackablePercentageData().totalsPerKey;

  return EFFECTIVE_ANSWER_KEYS.reduce((total, key) => {
    return total + (aggregatedByOutcome[key] ? aggregatedByOutcome[key] : 0);
  }, 0);
}

export default Controller.extend({
  reportingService: service(),
  appService: service(),
  app: readOnly('appService.app'),
  intl: service(),

  timezone: readOnly('app.timezone'),

  queryParams: ['conversationSource', 'resolutionBotSetting'],
  conversationSource: 'all',
  resolutionBotSetting: 'all',

  hasAnswers: gt('answerCounts.all', 0),
  hasLiveAnswers: gt('answerCounts.live', 0),

  hasConversationsWithAnswers: gt('signals.conversationsWithAnswers.value', 0),

  conversationsWithAnswers: readOnly('signals.conversationsWithAnswers.value'),

  get noAnswersClaimCopy() {
    return {
      heading: this.intl.t(
        'apps.app.reports.customer-support.resolution-bot.resolve-customer-questions',
      ),
      description: this.intl.t(
        'apps.app.reports.customer-support.resolution-bot.resolve-questions-description',
      ),
      articleText: this.intl.t('apps.app.reports.customer-support.resolution-bot.resolution-bot'),
      articleURL:
        'https://www.intercom.com/help/operator/answer-bot/provide-instant-resolutions-247-with-answer-bot',
      button: this.intl.t('apps.app.reports.customer-support.resolution-bot.set-up-resolution-bot'),
      route: 'apps.app.fin-ai-agent.custom-answers',
      analyticsObject: 'answer_bot_banner',
    };
  },

  get noLiveAnswersClaimCopy() {
    return {
      heading: this.intl.t('apps.app.reports.customer-support.resolution-bot.get-more'),
      description: this.intl.t(
        'apps.app.reports.customer-support.resolution-bot.answer-set-up-description',
      ),
      articleText: this.intl.t(
        'apps.app.reports.customer-support.resolution-bot.write-effective-answers',
      ),
      articleURL: 'https://www.intercom.com/help/en/articles/2408760-create-resolution-bot-answers',
      button: this.intl.t('apps.app.reports.customer-support.resolution-bot.set-up-resolution-bot'),
      route: 'apps.app.fin-ai-agent.custom-answers',
      analyticsObject: 'answer_bot_banner',
    };
  },

  get noDataClaimCopy() {
    return {
      heading: this.intl.t('apps.app.reports.customer-support.resolution-bot.get-more'),
      description: this.intl.t(
        'apps.app.reports.customer-support.resolution-bot.answer-set-up-description',
      ),
      articleText: this.intl.t(
        'apps.app.reports.customer-support.resolution-bot.write-effective-answers',
      ),
      articleURL: 'https://www.intercom.com/help/en/articles/2408760-create-resolution-bot-answers',
      button: this.intl.t('apps.app.reports.customer-support.resolution-bot.create-more-answers'),
      route: 'apps.app.fin-ai-agent.custom-answers.new',
      analyticsObject: 'answer_bot_banner',
    };
  },

  conversationsSent: computed(
    'conversationsWithAnswers',
    'signals.conversationsSent.value',
    function () {
      let conversationsWithAnswers = this.conversationsWithAnswers;
      let conversationsSent = this.get('signals.conversationsSent.value');
      if (conversationsWithAnswers > conversationsSent) {
        return conversationsWithAnswers;
      }
      return conversationsSent;
    },
  ),

  claimCopy: computed(
    'hasAnswers',
    'hasLiveAnswers',
    'noAnswersClaimCopy',
    'noDataClaimCopy',
    'noLiveAnswersClaimCopy',
    function () {
      if (!this.hasAnswers) {
        return this.noAnswersClaimCopy;
      } else if (!this.hasLiveAnswers) {
        return this.noLiveAnswersClaimCopy;
      }
      return this.noDataClaimCopy;
    },
  ),

  bannerVariation: ternary('hasLiveAnswers', 'live', 'draft'),

  effectiveConversationWithAnswerCount: computed('signals.conversationsWithAnswers', function () {
    return countEffectiveAnswersFromconversationsWithAnswers(this.signals.conversationsWithAnswers);
  }),

  effectiveInboundConversationWithAnswerCount: computed(
    'signals.inboundConversationsWithAnswers',
    function () {
      return countEffectiveAnswersFromconversationsWithAnswers(
        this.signals.inboundConversationsWithAnswers,
      );
    },
  ),

  effectiveOutboundConversationWithAnswerCount: computed(
    'signals.outboundConversationsWithAnswers',
    function () {
      return countEffectiveAnswersFromconversationsWithAnswers(
        this.signals.outboundConversationsWithAnswers,
      );
    },
  ),

  resolutionRate: computed(
    'signals.conversationsSent.value',
    'effectiveConversationWithAnswerCount',
    function () {
      let totalConversations = this.signals.conversationsSent.value;
      let effectiveConversationWithAnswerCount = this.effectiveConversationWithAnswerCount;
      return Math.round(percent(totalConversations, effectiveConversationWithAnswerCount));
    },
  ),

  actions: {
    updateRange(range) {
      this.reportingService.updateRange(range);
    },
    transitionToNewAnswer() {
      this.transitionToRoute('apps.app.fin-ai-agent.custom-answers.new');
    },
    transitionToRoute(route) {
      this.transitionToRoute(route);
    },
  },
});
