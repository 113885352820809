/* RESPONSIBLE TEAM: team-tickets-1 */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable ember/no-actions-hash */
/* eslint-disable ember/no-controllers */
/* eslint-disable ember/no-classic-classes */
import { reads, sort, filterBy, notEmpty, empty, and, readOnly } from '@ember/object/computed';
import Controller, { inject as controller } from '@ember/controller';
import { inject as service } from '@ember/service';
import { AVAILABLE_EMOJI } from 'embercom/lib/available-emoji';
import shuffle from '@intercom/pulse/lib/shuffle';

export default Controller.extend({
  store: service(),
  router: service(),
  // eslint-disable-next-line @intercom/intercom/no-legacy-modal
  modalService: service(),
  appController: controller('apps/app'),
  app: reads('appController.model'),
  teams: readOnly('app.assignableTeams'),

  sortKey: ['isAppTeam:desc'],
  sortedTeamsByAppTeam: sort('teams', 'sortKey'),
  sortedTeams: sort('sortedTeamsByAppTeam', function (a, b) {
    // Sort teams using the old persisted name to prevent team cards in the UI from being
    // reordered while editing the name.
    let aName = a.name;
    let bName = b.name;
    if (a.changedAttributes().name) {
      aName = a.changedAttributes().name[0];
    }
    if (b.changedAttributes().name) {
      bName = b.changedAttributes().name[0];
    }
    return aName.localeCompare(bName);
  }),

  isBeingEdited: filterBy('sortedTeams', 'isNew', true),
  isNotAddingATeam: empty('isBeingEdited'),

  dirtyTeams: filterBy('sortedTeams', 'hasDirtyAttributes', true),
  areTeamsDirty: notEmpty('dirtyTeams'),

  dirtyTeamSettings: filterBy('model.teamSettings', 'hasDirtyAttributes', true),
  areTeamSettingsDirty: notEmpty('dirtyTeamSettings'),

  isTeammateListUpdated: false,

  noTeamCreated: empty('app.teamsWithoutAppTeam'),
  showEmptyState: and('noTeamCreated', 'isNotAddingATeam'),

  _randomEmoji() {
    return shuffle(AVAILABLE_EMOJI)[0];
  },

  hasUnsavedChanges() {
    return this.areTeamsDirty || this.areTeamSettingsDirty || this.isTeammateListUpdated;
  },

  _buildTeam() {
    if (this.isNotAddingATeam) {
      this.sortedTeams.pushObject(
        this.store.createRecord('team', {
          name: '',
          is_team: true,
          member_ids: [],
          avatar_emoji: this._randomEmoji(),
          active: true,
        }),
      );
    }
  },

  changeSelectedTab(value) {
    if (value === 'roles') {
      this.transitionToRoute('apps.app.settings.workspace.teammates', {
        queryParams: { tab: 'roles' },
      });
      return;
    }
    this.set('tab', value);
  },

  hasRouteTransitioned() {
    let transitionedTab = this.router?.currentRoute?.queryParams?.tab;
    if (transitionedTab) {
      // unset the transition tab
      this.router.currentRoute.queryParams.tab = null;
    }
    return transitionedTab;
  },

  actions: {
    revertNewSelectedTeam(team) {
      this.sortedTeams.popObject(team);
    },
    buildTeam() {
      this._buildTeam();
    },
    deleteSelectedTeam(model) {
      this.modalService.openModal('settings/modals/team-delete-modal', model, {
        app: this.app,
      });
    },
    setTeammateUpdateStatus(isUpdated) {
      this.set('isTeammateListUpdated', isUpdated);
    },
  },
});
