/* RESPONSIBLE TEAM: team-ai-agent */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable ember/no-controllers */
import Controller from '@ember/controller';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { task } from 'ember-concurrency-decorators';

// The "visual" query param is used for targetting urls in monitoring
// services, e.g. Honeycomb. When visual builder is released to all
// customers we can remove this query param
export const VISUAL_BUILDER_QP_VALUES = {
  disabled: 'disabled',
  enabled: 'enabled',
};

export default class AnswerController extends Controller {
  @service intercomEventService;
  @service notificationsService;
  @service intl;
  @service appService;

  queryParams = ['visual'];
  @tracked visual = VISUAL_BUILDER_QP_VALUES.disabled;

  get afterSaveRouteName() {
    return this.transitionBackTo || `${this.appService.app.answersRoute}.answer.show`;
  }

  @task
  *save() {
    let answer = this.model;

    try {
      if (answer.isDraft && !answer.hasContent) {
        this.notificationsService.notifyError(
          this.intl.t('operator.resolution-bot-answers.notifications.to-save-draft'),
        );
        return;
      }

      this.notificationsService.clear();
      yield answer.validate();

      if (answer.isValid) {
        yield answer.save();

        try {
          this.trackSaveEvent(answer);
          this.notificationsService.notifyConfirmation(
            this.intl.t('operator.resolution-bot-answers.notifications.save-successful'),
          );
          this.transitionToRoute(this.afterSaveRouteName);
        } catch (e) {
          console.error(e);
        }
      } else {
        answer.validations.messages.forEach((message) =>
          this.notificationsService.notifyError(message),
        );
      }
    } catch (error) {
      console.error(error);
      let messages = error?.jqXHR?.responseJSON?.errors?.map((e) => e.message);
      this.notificationsService.notifyError(
        messages || this.intl.t('operator.resolution-bot-answers.notifications.couldnt-save'),
      );
    }
  }

  changeVisualBuilderQueryParam(answer) {
    this.visual = answer?.visualBuilderObject
      ? VISUAL_BUILDER_QP_VALUES.enabled
      : VISUAL_BUILDER_QP_VALUES.disabled;
  }

  trackSaveEvent(answer) {
    if (answer.isDraft || answer.isLive) {
      this.intercomEventService.trackEvent(`answer-bot-answer-saved-${answer.status}`);
    }
  }
}
