/* RESPONSIBLE TEAM: team-proactive-support */

import OutboundBaseLoadingController from 'embercom/lib/outbound/base-list-controller';
import { inject as service } from '@ember/service';

export default class LoadingSeriesController extends OutboundBaseLoadingController {
  @service intl;

  get bannerData() {
    return {
      button: this.intl.t('outbound.series.banner.new-series'),
    };
  }
}
