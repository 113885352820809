/* RESPONSIBLE TEAM: team-pricing-and-packaging */
import Controller from '@ember/controller';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';

export default class BillingDetailsIndexController extends Controller {
  @service declare customerService: $TSFixMe;
  @service declare purchaseAnalyticsService: $TSFixMe;
  @service declare appService: $TSFixMe;

  queryParams = ['selectedTab', 'isEditing'];

  @tracked selectedTab = 'current';
  @tracked isEditing = false;
  @tracked showUnsavedChangesModal = false;
  context = 'billing';
  previousTransition = null;

  get app() {
    return this.appService.app;
  }

  get place() {
    return this.isEditing ? 'edit_subscription' : 'current_subscription';
  }

  @action
  revert() {
    this.purchaseAnalyticsService.trackEvent({
      action: 'clicked',
      object: 'revert_edit_subscription',
      context: this.context,
      place: this.place,
    });
    this.customerService.resetAll();
    this.isEditing = false;
  }

  @action unsavedChangesModalTransition() {
    // perform the previously attempted transition
    this.revert();
    let previousTransition = this.previousTransition as $TSFixMe;
    if (previousTransition) {
      this.previousTransition = null;
      previousTransition.retry();
    }
  }
}
