/* RESPONSIBLE TEAM: team-proactive-support */
import Controller from '@ember/controller';
import { tracked } from '@glimmer/tracking';

export default class ShowController extends Controller {
  queryParams = ['view', 'filter'];

  @tracked view = 'content';
  @tracked filter = '';
}
