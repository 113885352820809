/* RESPONSIBLE TEAM: team-proactive-support */
import Controller, { type QueryParamConfig } from '@ember/controller';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { isEmpty } from '@ember/utils';
import UserAgentDetector from '@intercom/pulse/lib/user-agent-detector';
import { type TaskGenerator } from 'ember-concurrency';
import { dropTask } from 'ember-concurrency-decorators';
import { taskFor } from 'ember-concurrency-ts';
import { encodedVapidToken } from 'embercom/lib/vapid-token';
import Admin from 'embercom/models/admin';
import Metrics from 'embercom/models/metrics';
import { type NotificationsRouteType } from 'embercom/routes/apps/app/settings/personal/notifications';

export default class NotificationsController extends Controller {
  queryParams: (string | Record<string, string | QueryParamConfig | undefined>)[] = [];
  declare model: ModelFromRoute<NotificationsRouteType>;

  @service declare intercomEventService: $TSFixMe;
  @service declare notificationsService: $TSFixMe;
  @service declare sounds: $TSFixMe;
  // eslint-disable-next-line @intercom/intercom/no-legacy-modal
  @service declare modalService: $TSFixMe;
  @service declare intl: $TSFixMe;
  @service declare appService: $TSFixMe;

  get app() {
    return this.appService.app;
  }

  get admin() {
    return this.appService.app.currentAdmin;
  }

  get canUseInboxInAnyApp() {
    // Typecasting admin to be Admin type as overriding legacy in modern controller is not working
    return (this.admin as Admin).canUseInboxInAnyApp;
  }

  get hasInboxAccess() {
    return (this.admin as Admin).hasInboxAccess;
  }

  get inboxProductName() {
    return this.app.inboxProduct.name;
  }

  get requiresInboxSeatAccess() {
    return this.app.requiresInboxSeatAccess;
  }

  get hasAccountMarketingBillingFeature() {
    return this.app.hasAccountMarketingBillingFeature;
  }

  get title() {
    return this.intl.t('new-settings.submenu.personal.notifications');
  }

  get noWebPushSupport() {
    return isEmpty(window.PushManager);
  }

  get noWebPushSupportMessage() {
    return this.intl.t('account.notifications.notifications-not-supported');
  }

  get hasWebPushSupport() {
    return !this.noWebPushSupport;
  }

  get accountSpecificNotificationUrl() {
    return this.model.accountOwnerSpecificPageNotificationUrl;
  }

  get accountSpecificNotificationEnabled() {
    return this.model.accountOwnerSpecificPageNotificationEnabled;
  }

  get accountSpecificNotificationDisabled() {
    return !this.accountSpecificNotificationEnabled;
  }

  get accountSpecificNotificationUrlHasError() {
    return isEmpty(this.accountSpecificNotificationUrl) && this.accountSpecificNotificationEnabled;
  }

  get specificPageNotificationEditorAttrs() {
    return {
      accountSpecificNotificationUrl: this.accountSpecificNotificationUrl,
      updateSpecificPageNotificationUrl: this.updateSpecificPageNotificationUrl,
      parentContext: this,
    };
  }

  get notificationSoundOptions() {
    return [
      {
        text: this.intl.t('account.notifications.notification-sound-names.default'),
        value: 'default',
      },
      { text: this.intl.t('account.notifications.notification-sound-names.blip'), value: 'blip' },
      { text: this.intl.t('account.notifications.notification-sound-names.bop'), value: 'bop' },
      { text: this.intl.t('account.notifications.notification-sound-names.clack'), value: 'clack' },
      { text: this.intl.t('account.notifications.notification-sound-names.hiss'), value: 'hiss' },
      { text: this.intl.t('account.notifications.notification-sound-names.shake'), value: 'shake' },
      { text: this.intl.t('account.notifications.notification-sound-names.tink'), value: 'tink' },
    ];
  }

  get selectedNotificationSoundId() {
    let soundId = this.model.notificationSound;

    if (soundId && this.sounds.soundIds.includes(soundId)) {
      return soundId;
    }
    return 'default';
  }

  get hasChanges() {
    return this.model.hasDirtyAttributes;
  }

  get showRespondTooltip() {
    return (
      this.admin.hasMoreThanOneApp &&
      this.admin.inboxIsNotActiveOnEveryApp &&
      this.admin.inboxIsActiveOnAnyApp
    );
  }

  get showGlobalNotificationNote() {
    return this.hasChanges && this.admin.hasMoreThanOneApp;
  }

  get receivesDailySignupEmail() {
    return this.model.dailySummaryEmailFor !== null;
  }

  set receivesDailySignupEmail(value: $TSFixMe) {
    this.model.set('dailySummaryEmailFor', value ? 'users' : null);
  }

  get encodedVapidToken() {
    return encodedVapidToken();
  }

  @action
  async savePushSubscription(pushSubscription: $TSFixMe, notify = true) {
    try {
      await this.model.savePushSubscription(this.app, this.admin, pushSubscription);
      if (notify) {
        this.notificationsService.notifyConfirmation(
          this.intl.t('account.notifications.save-confirmation'),
        );
      }
    } catch (err) {
      this.notificationsService.notifyError(this.intl.t('account.notifications.save-error'));
    }
  }

  @dropTask
  *enablePushNotifications(): TaskGenerator<void> {
    let serviceWorkerRegistration: any = yield navigator.serviceWorker.ready;
    let pushManager = serviceWorkerRegistration.pushManager;
    let options: any = { userVisibleOnly: true };
    if (UserAgentDetector.isChrome()) {
      options.applicationServerKey = this.encodedVapidToken;
    }
    let permissionState = yield pushManager.permissionState(options);
    // Permission has been previously denied, nothing we can do here.
    if (permissionState === 'denied') {
      return this.modalService.openModal('account/notifications-denied-modal');
    }
    let existingPushSubscription = yield pushManager.getSubscription();
    if (existingPushSubscription) {
      if (existingPushSubscription.endpoint.startsWith('https://android.googleapis.com/gcm/send')) {
        Metrics.capture({ increment: ['gcm_unsubscribe_token'] });
        yield existingPushSubscription.unsubscribe();
      } else {
        this.savePushSubscription(existingPushSubscription, false);
        return;
      }
    }
    // User is going to be prompted, show an informational modal
    if (permissionState === 'prompt') {
      this.modalService.openModal('account/notifications-ask-modal');
    }
    try {
      let pushSubscription = yield pushManager.subscribe(options);
      this.modalService.closeModal();
      this.savePushSubscription(pushSubscription);
    } catch (e) {
      // permission was denied
      this.modalService.openModal('account/notifications-denied-modal');
    }
  }

  afterSave() {
    Admin.meWithoutRedirect();
  }

  @action
  saveChanges() {
    this.save();
  }

  @action
  webPushSettingsChanged(notificationType: any, event: any | boolean) {
    let isChecked = event;
    if (typeof event !== 'boolean') {
      isChecked = event.target.checked;
    }
    this.set(notificationType, isChecked);
    if (isChecked && window.PushManager !== undefined) {
      taskFor(this.enablePushNotifications).perform();
    }
  }

  @action
  toggleBrowserAudioAlerts() {
    this.model.toggleProperty('browserAudioAlerts');
  }

  @action
  updateSpecificPageNotificationUrl(url: string) {
    this.model.set('accountOwnerSpecificPageNotificationUrl', url);
  }

  @action
  toggleSpecificPageSetting(value: boolean) {
    this.model.set('accountOwnerSpecificPageNotificationEnabled', value);
  }

  @action
  setNotificationSound(value: string) {
    this.model.set('notificationSound', value);
    this.sounds.adminNotificationSounds[value].play();
  }

  @action
  revertUnsavedChanges() {
    this.model.rollbackAttributes();
  }

  @action
  async save() {
    try {
      await this.model.save();
      this.notificationsService.notifyConfirmation(
        this.intl.t('account.notifications.settings-saved'),
      );
    } catch (error) {
      this.notificationsService.notifyError(
        this.intl.t('account.base.account-controller-base.save-error'),
      );
    }
  }
}
