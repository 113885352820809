/* RESPONSIBLE TEAM: team-help-desk-experience */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
/* eslint-disable ember/no-deeply-nested-dependent-keys-with-each */
/* eslint-disable ember/no-new-mixins */
/* eslint-disable ember/no-jquery */
import { inject as service } from '@ember/service';
import { debounce } from '@ember/runloop';
import $ from 'jquery';
import { computed } from '@ember/object';
import { alias, gt, sort } from '@ember/object/computed';
import { on } from '@ember/object/evented';
import Mixin from '@ember/object/mixin';
import pluralise from 'embercom/lib/inflector';
import ENV from 'embercom/config/environment';

let SavedReplySearch = Mixin.create({
  appService: service(),
  app: alias('appService.app'),
  savedReplySearchService: service(),
  intercomEventService: service(),
  savedReplyFilterQuery: '',
  somethingTyped: gt('savedReplyFilterQuery.length', 0),
  sortedReplies: sort('replies', 'repliesSortKey'),
  repliesSortKey: ['name'],
  searchResults: [],
  initSearchResults: on('init', function () {
    this.set(
      'searchResults',
      this.savedReplySearchService.searchUsingRegex(this.sortedReplies, ''),
    );
  }),
  filteredReplies: computed(
    'sortedReplies.@each.{isNew,name,summary,admin.name}',
    'savedReplyFilterQuery',
    'searchResults',
    'initSearchResults',
    function () {
      return this.savedReplySearchService.searchUsingRegex(
        this.sortedReplies,
        this.savedReplyFilterQuery,
      );
    },
  ),

  hasFilteredSavedReplies: gt('filteredReplies.length', 0),
  searchPlaceHolderText: computed('filteredReplies.length', 'repliesLabel', function () {
    return `${this.filteredReplies.length} ${this.repliesLabel}…`;
  }),
  repliesLabel: computed('filteredReplies.length', function () {
    return pluralise('macro', this.get('filteredReplies.length'));
  }),

  setFilterQuery(value) {
    if (this.isDestroying) {
      return;
    }
    this.set('savedReplyFilterQuery', value);
    this.updateSavedReplyFilterQuery(value);
    this.intercomEventService.trackAnalyticsEvent({
      owner: 'inbox',
      action: 'searched',
      object: 'saved_reply',
      place: 'inbox',
      context: 'saved_reply_inserter',
      searched_from: this.analyticsContext,
      query_keywords: value,
      number_of_results: this.searchResults.length,
    });
    if (this._onQueryUpdate) {
      this._onQueryUpdate();
    }
  },

  updateSavedReplyFilterQuery(query) {
    this.set('savedReplyFilterQuery', query);
    let results = this.savedReplySearchService.searchUsingRegex(this.sortedReplies, query);
    this.set('searchResults', results);
  },

  actions: {
    clearSearch() {
      this.set('savedReplyFilterQuery', '');
      $('.m__saved-replies__list-item__search-input').val('').focus();
    },
    updateSearch(value) {
      this.intercomEventService.trackAnalyticsEvent({
        owner: 'inbox',
        action: 'search_initiated',
        object: 'saved_reply',
        place: 'inbox',
        context: 'saved_reply_inserter',
        searched_from: this.analyticsContext,
        query_keywords: value,
      });
      debounce(this, this.setFilterQuery, value, ENV.APP._200MS, false);
    },
  },
});

export default SavedReplySearch;
