import EmberObject from '@ember/object';
import { A } from '@ember/array';
import { inject as service } from '@ember/service';
import Controller from '@ember/controller';
import ajax from 'embercom/lib/ajax';
import type IntlService from 'embercom/services/intl';
import type IntercomConfirmService from 'embercom/services/intercom-confirm-service';
import type Store from '@ember-data/store';
import { restartableTask } from 'ember-concurrency-decorators';
import { action } from '@ember/object';
import { type TaskGenerator } from 'ember-concurrency';
import { taskFor } from 'ember-concurrency-ts';

/* RESPONSIBLE TEAM: team-app-security */

export default class AccountAccessController extends Controller {
  @service declare store: Store;
  @service declare intl: IntlService;
  @service declare notificationsService: $TSFixMe;
  @service declare intercomConfirmService: IntercomConfirmService;
  @service declare appService: $TSFixMe;

  queryParams = ['createFromMessengerConversation'];

  declare model: $TSFixMe;
  declare impersonationConsents: $TSFixMe;
  declare sessions: $TSFixMe;
  declare titleToken: string;

  get app() {
    return this.appService.app;
  }

  get isImpersonationSession() {
    return this.app.impersonation_session;
  }

  hasUnsavedChanges() {
    return this.model.hasDirtyAttributes;
  }

  revertUnsavedChanges() {
    this.model.rollbackAttributes();
  }

  get columns() {
    return A([
      EmberObject.create({
        label: this.intl.t('account.account-access.approved'),
        canExpandToFit: true,
        component: 'table-cells/impersonation-consents/approved-at-cell',
      }),
      EmberObject.create({
        label: this.intl.t('account.account-access.status'),
        canExpandToFit: true,
        component: 'table-cells/impersonation-consents/status-cell',
      }),
    ]);
  }

  get rows() {
    return this.impersonationConsents
      .map((consent: $TSFixMe) => this.buildRow(consent))
      .compact()
      .reverse();
  }

  buildRow(consent: $TSFixMe) {
    if (!consent.get('createdAt')) {
      return;
    }
    return EmberObject.create({ consent });
  }

  get isTerminateSessionRunning() {
    return taskFor(this.terminateSession).isRunning;
  }

  @restartableTask
  *terminateSession(session: $TSFixMe): TaskGenerator<any> {
    let options = {
      title: this.intl.t('account.account-access.sessions.terminate-modal-title'),
      body: this.intl.t('account.account-access.sessions.terminate-modal-confirm-text'),
      confirmButtonText: this.intl.t(
        'account.account-access.sessions.terminate-modal-confirm-button-text',
      ),
    };

    let confirmed = yield this.intercomConfirmService.confirm(options);
    if (!confirmed) {
      return;
    }

    try {
      yield ajax({
        type: 'POST',
        url: '/ember/session_device_details/invalidate_session',
        data: JSON.stringify({
          id: session.get('id'),
          app_id: this.app.id,
        }),
      });
      this.refreshActiveSessions();
      this.notificationsService.notifyConfirmation(
        this.intl.t('account.account-access.sessions.terminated-successfully'),
      );
    } catch (error) {
      this.notificationsService.notifyError(
        this.intl.t('account.account-access.sessions.termination-error'),
      );
    }
  }

  refreshActiveSessions() {
    let activeSessions = this.store.query('session-device-detail', {
      app_id: this.app.id,
    });

    this.set('sessions', activeSessions);
  }

  @action
  giveConsent() {
    try {
      let consent = this.model.save();
      this.notificationsService.notifyConfirmation(
        this.intl.t('account.account-access.access-confirmation'),
      );
      this.impersonationConsents.pushObject(consent);
    } catch (error) {
      this.notificationsService.notifyError(this.intl.t('account.account-access.access-error'));
    }
  }

  @action
  async revokeConsent() {
    try {
      await this.model.destroyRecord();
      this.notificationsService.notifyConfirmation(
        this.intl.t('account.account-access.revoke-confirmation'),
      );
      this.set('model', this.store.createRecord('admin-impersonation-consent'));
    } catch (error) {
      this.notificationsService.notifyError(this.intl.t('account.account-access.revoke-error'));
    }
  }
}
