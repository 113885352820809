/* RESPONSIBLE TEAM: team-proactive-support */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable ember/no-actions-hash */
import OutboundBaseListController from 'embercom/lib/outbound/base-list-controller';
import { readOnly } from '@ember/object/computed';
import { inject as service } from '@ember/service';
import { computed } from '@ember/object';
import { PAGE_SIZE, WILDCARD_TITLE_SEARCH } from 'embercom/models/data/outbound/constants';
import { behaviorMapDynamicFixed } from 'embercom/models/data/matching-system/matching-constants';
import { isEqual } from '@ember/utils';

export default OutboundBaseListController.extend({
  outboundHomeService: service(),
  appService: service(),
  intercomEventService: service(),
  app: readOnly('appService.app'),

  queryParams: ['type', { searchString: 'q' }],
  searchString: '',
  searchIcon: 'search',
  isLoading: false,
  items: [],

  type: 'dynamic',
  tabs: ['dynamic', 'fixed'],
  matchBehaviors: computed('type', function () {
    return behaviorMapDynamicFixed[this.type];
  }),
  isOngoingOrDynamic: computed('type', function () {
    return isEqual(this.type, 'ongoing') || isEqual(this.type, 'dynamic');
  }),

  _request({ searchString, perPage, pageFrom }) {
    return {
      app_id: this.app.id,
      object_types: this.objectTypes,
      per_page: perPage,
      page_from: pageFrom,
      match_behaviors: this.matchBehaviors,
      title: searchString,
      statistics: this.statistics,
      wildcard_title_search: WILDCARD_TITLE_SEARCH,
    };
  },

  async search(searchString) {
    this.set('searchString', searchString);
    this.set('isLoading', true);
    this.intercomEventService.trackEvent('outbound-search', {
      searchTerm: searchString,
      messageType: this.type,
    });

    let response = await this.outboundHomeService.contentSearch(
      this._request({ searchString, perPage: PAGE_SIZE, pageFrom: 0 }),
    );

    this.setProperties({
      searchResults: response.contentWrappers,
      pageFrom: response.pageFrom,
      perPage: response.perPage,
      totalCount: response.totalCount,
      totalPages: response.totalPages,
      lastPageHit: response.lastPageHit,
    });
    this.set('isLoading', false);
  },

  actions: {
    async doSearch(item) {
      if (item && item.name.length > 0) {
        await this.search(item.name);
      }
    },

    async selectTab(type) {
      this.set('type', type);
      if (this.searchString) {
        await this.search(this.searchString);
      }
    },

    async loadMore(perPage, pageFrom) {
      this.setProperties({ perPage, pageFrom });

      let response = await this.outboundHomeService.contentSearch(
        this._request({ searchString: this.searchString, perPage, pageFrom }),
      );

      this.setProperties({
        searchResults: [...this.searchResults, ...response.contentWrappers],
        totalCount: response.totalCount,
        totalPages: response.totalPages,
        lastPageHit: response.lastPageHit,
      });
    },

    resetSearch() {
      this.set('searchString', '');
      this.set('searchResults', []);
      this.set('isLoading', false);
    },
  },
});
