/* RESPONSIBLE TEAM: team-messenger */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
/* eslint-disable ember/no-actions-hash */
/* eslint-disable promise/prefer-await-to-then */
/* eslint-disable ember/no-controllers */
import IntegrateBaseController from 'embercom/controllers/apps/app/integrations-hub/integrate-base-controller';
import { readOnly } from '@ember/object/computed';
import { INSTALLATION_DOCS_URL } from 'embercom/lib/installation-docs-url';

export default IntegrateBaseController.extend({
  admin: readOnly('appController.admin'),
  sdkApp: readOnly('model'),
  isSdkAppIdentityVerified: readOnly('sdkApp.identityVerified'),
  docsUrl: INSTALLATION_DOCS_URL,

  deleteSdkApp(sdkApp) {
    let confirmationMessage =
      'Are you sure? Deleting the App API key will stop any active installations of the Intercom SDK from working in your apps.';
    if (!confirm(confirmationMessage)) {
      return;
    }

    sdkApp.destroyRecord().then(
      function () {
        this.set('model', null);
      }.bind(this),
    );
  },

  actions: {
    deleteSdkApp() {
      this.deleteSdkApp(this.sdkApp);
    },
  },
});
