/* RESPONSIBLE TEAM: team-customer-data-platform */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable ember/no-actions-hash */
/* eslint-disable ember/no-controllers */
/* eslint-disable ember/no-classic-classes */
import { alias, gt, and, empty } from '@ember/object/computed';
import Controller, { inject as controller } from '@ember/controller';
import SegmentActions from 'embercom/controllers/mixins/users-and-companies/segment-actions';
import SegmentQueryParams from 'embercom/controllers/mixins/users-and-companies/segment-query-params';
import TrackFilteringEvents from 'embercom/controllers/mixins/users-and-companies/track-filtering-events';
import { ternaryToProperty } from '@intercom/pulse/lib/computed-properties';
import { action } from '@ember/object';

export default Controller.extend(SegmentActions, SegmentQueryParams, TrackFilteringEvents, {
  place: 'user_list',
  appController: controller('apps/app'),
  app: alias('appController.model'),
  hasStandardPredicates: gt('model.activePredicates.length', 0),
  hasNestedPredicates: gt('model.nestedPredicates.length', 0),
  hasValidPredicates: ternaryToProperty(
    'model.isNestedGroup',
    'hasNestedPredicates',
    'hasStandardPredicates',
  ),

  isDirtyAndHasValidPredicates: and('model.hasDirtyAttributes', 'hasValidPredicates'),

  searchController: controller('apps.app.users'),
  searchModel: alias('searchController.search'),
  searchEmpty: empty('searchModel.text'),
  searchActive: alias('searchController.searchActive'),

  createSegment(segment) {
    this.send('createSegment', segment);
  },

  handleWarnOnNavigation: action(function (shouldWarnOnNavigation) {
    this.send('warnOnNavigation', shouldWarnOnNavigation);
  }),

  actions: {
    segmentFiltersChanged(action) {
      this.send('addFiltersToUrl', this.model);
      this.processEvent(action);
    },
  },
});
