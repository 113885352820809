/* RESPONSIBLE TEAM: team-proactive-support */
/* eslint-disable ember/no-controllers */
import Controller, { inject as controller } from '@ember/controller';
import { stats, failureListCategories } from 'embercom/models/data/stats-system/stats-constants';

export default class EmailDeliverabilityOpenedController extends Controller {
  @controller('apps.app.reports.outbound-engagement.email-deliverability') parentController;

  get range() {
    return this.parentController.range;
  }

  get stats() {
    return stats;
  }

  get failureCategories() {
    return failureListCategories;
  }
}
