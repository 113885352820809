/* RESPONSIBLE TEAM: team-workflows */
import Controller, { inject as controller } from '@ember/controller';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';

export default class AppsAppAutomationBasicsController extends Controller {
  @service appService;

  queryParams = ['for', 'section'];
  @tracked for = 'users';
  @tracked section;
  @controller('apps.app.automation') automationController;

  get app() {
    return this.appService.app;
  }

  @action
  switchTab(forRole) {
    this.section = null;
    this.for = forRole;
  }

  @action
  openSection(sectionId) {
    this.section = sectionId;
  }
}
