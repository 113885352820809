/* RESPONSIBLE TEAM: team-proactive-support */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
/* eslint-disable ember/no-actions-hash */
/* eslint-disable ember/no-controllers */
/* eslint-disable ember/no-classic-classes */
import Controller from '@ember/controller';

import { inject as service } from '@ember/service';

export default Controller.extend({
  notificationsService: service(),
  intersectionService: service(),

  actions: {
    openIntersection() {
      this.intersectionService.openWindow('http://intercom-js.test');
      this.intersectionService.registerEventHandler('selector-chosen', (options) => {
        this.notificationsService.notifyConfirmation(
          `You chose ${options.selector}, you should be very proud.`,
        );
      });
    },
  },
});
