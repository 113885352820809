/* RESPONSIBLE TEAM: team-proactive-support */
import ReportBase from 'embercom/controllers/apps/app/reports/base/report';
import { action } from '@ember/object';
import storage from 'embercom/vendor/intercom/storage';
import { inject as service } from '@ember/service';
import { VISUALIZATION_TYPES } from 'embercom/models/reporting/custom/visualization-options';

export default class SlasController extends ReportBase {
  @service router;
  @service appService;
  @service intl;

  get SLA_COPY() {
    return {
      heading: this.intl.t('apps.app.reports.customer-support.slas.set-up'),
      description: this.intl.t('apps.app.reports.customer-support.slas.set-up-description'),
      button: this.intl.t('apps.app.reports.customer-support.slas.create-sla'),
      route: 'apps.app.settings.inbox-rules.list',
      articleText: this.intl.t('apps.app.reports.customer-support.slas.how-to-set-up'),
      articleURL:
        'https://www.intercom.com/help/en/articles/6546152-hit-your-response-time-targets-with-sla-rules-in-the-inbox',
      analyticsObject: 'sla_reporting_rules_banner',
    };
  }

  get inboxRulesLink() {
    return 'apps.app.settings.inbox-rules.list';
  }

  get hasSLAs() {
    return this.conversationSLAs.length > 0;
  }

  get hasCurrentPeriodConversationsWithSLA() {
    return this.signals?.slaConversations.value > 0;
  }

  get hasPreviousPeriodConversationsWithSLA() {
    return this.signals?.slaConversations.previousValue > 0;
  }

  get hasConversationsWithSLA() {
    return this.hasCurrentPeriodConversationsWithSLA || this.hasPreviousPeriodConversationsWithSLA;
  }

  get hasSLAsSetup() {
    return this.hasSLAs || this.hasConversationsWithSLA;
  }

  queryParams = ['slaId'];
  slaId = null;
  claimCopy = this.SLA_COPY;

  get hitRateOverTimeChartDefinition() {
    return {
      id: 'sla_hit_rate_over_time',
      visualization_type: VISUALIZATION_TYPES.LINE,
      chart_series: [
        {
          metric_id: 'sla_hit.rate',
        },
      ],
      view_by: 'time',
      segment_by: 'sla_target_type',
    };
  }

  get claimBannerVariation() {
    return this.hasSLAs ? 'no-rules' : 'no-slas';
  }

  get conversationSLAsList() {
    return [
      {
        isFilterable: true,
        items: this.conversationSLAs.map(({ name, id }) => ({
          text: name,
          value: id,
          isSelected: id.toString() === this.selectedConversationSLA.id,
        })),
      },
    ];
  }

  get customAttributeIds() {
    return this.conversationAttributeDescriptors.map((descriptor) => descriptor.id);
  }

  @action
  selectConversationSLA(sla) {
    this.set('slaId', sla);
    storage.set('inbox-reporting-last-viewed-sla-id', sla);
  }

  @action
  transitionToRoute(route) {
    this.router.transitionTo(route);
  }

  @action
  filterBarSelectionChanged(filterType, filterValues, operator) {
    //backwards compatible with existing teammate filters before the filter bar was introduced
    if (filterType === 'teammatesParticipated') {
      this.set('teammateId', filterValues);
    } else if (this.customAttributeIds.includes(filterType)) {
      this.customAttributes.updateValues(filterType, filterValues, operator);
      this.set('customAttributes', this.customAttributes.clone());
    } else {
      this.set(filterType, filterValues);
    }
  }
}
