/* RESPONSIBLE TEAM: team-proactive-support */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
/* eslint-disable ember/require-computed-property-dependencies */
/* eslint-disable ember/no-controllers */
import ReportBase from 'embercom/controllers/apps/app/reports/base/report';
import { computed, action } from '@ember/object';
import { mapContextItemToModelByKey } from 'embercom/lib/reporting/signal-transformations';
import { mapBy, readOnly, gt } from '@ember/object/computed';
import { decamelize } from '@ember/string';
import { task } from 'ember-concurrency';
import {
  CONVERSATION_STARTED_BY,
  TEAMMATES_PARTICIPATED,
  TEAM_PARTICIPATED,
  CONVERSATION_TAG,
  USER_TAG_IDS,
} from 'embercom/lib/reporting/conversation-event-blocks';
import { inject as service } from '@ember/service';
import { post } from 'embercom/lib/ajax';
import ExportHelper from 'embercom/lib/reporting/export-helper';
import { DATE_WITHOUT_TIME, CSV_EMPTY_LINE } from 'embercom/lib/reporting/export-helper';
import { getOwner } from '@ember/application';

const TEAMMATE_FILTER_KEYS = ['teammateParticipated', 'teamParticipated'];

const TEAMMATE_FILTER_QUERY_REPORTING = {
  query: {
    name: 'default_query',
    debug_name: 'replied_to',
    document_type: 'conversation',
    range_field: 'first_user_conversation_part_created_at',
    aggregation_type: 'value_count',
    aggregation_field: 'conversation_id',
    filters: {
      exists_fields: ['conversation_tag_ids'],
    },
  },
};

const BASE_QUERY_REPORTING = {
  query: {
    name: 'default_query',
    debug_name: 'volume',
    document_type: 'conversation',
    range_field: 'first_user_conversation_part_created_at',
    aggregation_type: 'value_count',
    aggregation_field: 'conversation_id',
    filters: {
      exists_fields: ['conversation_tag_ids'],
    },
  },
};

export default ReportBase.extend({
  csv: service(),
  appService: service(),
  intercomEventService: service(),
  app: readOnly('appService.app'),

  selectedFilters: [],

  hasTags: gt('app.tags.length', 0),

  timezone: readOnly('app.timezone'),

  filtersIncludeTeammates: computed('selectedFilters', function () {
    return this.selectedFilters.find((filter) => TEAMMATE_FILTER_KEYS.includes(filter.key));
  }),

  baseQuery: computed('filtersIncludeTeammates', function () {
    return this.filtersIncludeTeammates ? TEAMMATE_FILTER_QUERY_REPORTING : BASE_QUERY_REPORTING;
  }),

  query: computed('baseQuery', function () {
    return { ...this.baseQuery.query, nested_keys_are_dates: false, keys_are_dates: false };
  }),

  signalWithoutFilteredTags(selectedFilters) {
    return ({ context, value }) => {
      let selectedTags = selectedFilters.filter((sf) => sf.key === 'tags');
      let selectedTagIds = selectedTags.map((st) => st.value);
      return {
        context: context.filter((item) => !selectedTagIds.includes(item.key)),
        value,
      };
    };
  },

  transformations: computed('app.tags', 'selectedFilters', function () {
    return [
      mapContextItemToModelByKey({ models: this.app.tags, valuePath: 'name' }),
      this.signalWithoutFilteredTags(this.selectedFilters),
    ];
  }),
  teammates: readOnly('app.humanAdmins'),
  teams: readOnly('app.teams'),

  blocks: computed('teammates', 'app.tags', function () {
    return [
      CONVERSATION_TAG(this.app.tags),
      USER_TAG_IDS(this.app.tags),
      TEAMMATES_PARTICIPATED(this.teammates),
      TEAM_PARTICIPATED(this.teams),
      CONVERSATION_STARTED_BY,
    ];
  }),

  blockKeys: mapBy('blocks', 'key'),

  blocksByKey: computed('blocks', function () {
    return this.blocks.reduce(
      (blocksByKey, block) => ({
        ...blocksByKey,
        [block.key]: block,
      }),
      {},
    );
  }),

  triggerFiltersChangedAnalyticsEvent(newFilters, currentFilters) {
    let currentFiltersNormalized = currentFilters.map((f) => ({ type: f.key, value: f.value }));
    let newFiltersNormalized = newFilters.map((f) => ({ type: f.key, value: f.value }));

    if (newFiltersNormalized.length < currentFiltersNormalized.length) {
      this.triggerAnalyticsEvent({ action: 'filter_removed' });
    } else {
      let newFilter = newFiltersNormalized.find((nfn) => {
        let isNew = !currentFiltersNormalized.some(
          (cfn) => cfn.type === nfn.type && cfn.value === nfn.value,
        );

        if (isNew) {
          return nfn;
        }
      });

      if (newFilter) {
        this.triggerAnalyticsEvent({
          action: 'filter_added',
          filterType: newFilter.type,
          depth: newFilters.length,
        });
      }
    }
  },

  triggerAnalyticsEvent(payload) {
    let normalizedPayload = Object.keys(payload).reduce(
      (result, key) => ({
        ...result,
        [decamelize(key)]: payload[key],
      }),
      {},
    );

    let params = {
      object: this.reportName,
      ...normalizedPayload,
    };

    this.intercomEventService.trackAnalyticsEvent(params);
  },

  updateFilters: action(function ({ filters }) {
    this.triggerFiltersChangedAnalyticsEvent(filters, this.selectedFilters);
    this.set('selectedFilters', filters);
    this.set('tagsSearch', '');
  }),

  addToTagFilters: action(function (data) {
    let filters = [
      ...this.selectedFilters,
      {
        key: 'tags',
        value: data.key,
        name: CONVERSATION_TAG(this.app.tags).name,
        valueLabel: data.name,
      },
    ];
    this.updateFilters({ filters });
  }),

  onAnalyticsEvent: action(function (payload) {
    this.triggerAnalyticsEvent(payload);
  }),

  exportCSVTask: task(function* (signal) {
    let exportHelper = new ExportHelper(getOwner(this));
    let filename = `conversation_tags_${exportHelper.dateFormatter(
      this.range.startMoment,
      DATE_WITHOUT_TIME,
    )}_${exportHelper.dateFormatter(this.range.endMoment, DATE_WITHOUT_TIME)}.csv`;

    let title = [['Conversation tags']];
    let data = title
      .concat(CSV_EMPTY_LINE)
      .concat(exportHelper.csvHeaders(this.range))
      .concat(CSV_EMPTY_LINE)
      .concat(this.csvFilters())
      .concat(CSV_EMPTY_LINE)
      .concat(this.csvTableData(signal));
    return yield this.csv.export(data, {
      fileName: filename,
      withSeparator: false,
    });
  }),

  exportCSV: action(function (signal) {
    return this.exportCSVTask.perform(signal);
  }),

  csvFilters() {
    let title = ['Filters'];
    let groupedFilters = this.selectedFilters.reduce((filters, filter) => {
      filters[filter.name] = filters[filter.name] || [];
      filters[filter.name].push(filter.valueLabel);
      return filters;
    }, {});
    let formattedFilters = Object.entries(groupedFilters).map(
      ([filterName, filterValues]) => `${filterName}:${filterValues.join('|')}`,
    );
    return [title, formattedFilters];
  },

  csvTableData(signal) {
    let headers = [['Tag', 'Number of conversations']];
    let data = signal.context.map(({ name, value }) => [name, value]);
    return headers.concat(data);
  },

  openSideDrawer: action(function (conversationsCount) {
    this.set('showSideDrawer', true);
    this.set('conversationsChartCount', conversationsCount);

    let analyticsPayload = {
      action: 'opened',
      object: 'conversations_list',
      currentFilters: this.selectedFilters,
    };

    this.triggerAnalyticsEvent(analyticsPayload);
  }),

  fetchConversationIds: task(function* (globalFilters) {
    this.currentFilterCount = globalFilters.length;

    let params = {
      app_id: this.app.id,
      document_type: 'conversation',
      aggregation_type: 'value_count',
      aggregation_field: 'conversation_id',
      range_field: 'first_user_conversation_part_created_at',
      range_start: this.range.start,
      range_end: this.range.end,
      sorting_field: { conversation_id: { order: 'desc' } },
      filters: {
        exists_fields: ['conversation_tag_ids'],
        list_filters: globalFilters,
      },
    };

    return yield post('/ember/reporting/conversations/ids_by_filters', params);
  }),
});
