/* RESPONSIBLE TEAM: team-proactive-support */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
/* eslint-disable ember/no-controllers */
/* eslint-disable ember/use-brace-expansion */
/* eslint-disable ember/no-classic-classes */
import { computed } from '@ember/object';
import { filterBy, map, alias, setDiff } from '@ember/object/computed';
import { inject as service } from '@ember/service';
import Controller from '@ember/controller';
import { rejectByProperty } from '@intercom/pulse/lib/computed-properties';

export default Controller.extend({
  appService: service(),
  intl: service(),
  app: alias('appService.app'),
  adminToReassign: null,

  sendersWithoutAdminToReassign: rejectByProperty('app.humanAdmins', 'id', 'adminToReassign.id'),

  assignableAdminsExcludingAdminToReassign: computed(
    'app.assignableAdmins.@each.{id,name}',
    'adminToReassign.id',
    function () {
      let adminToReassignId = this.get('adminToReassign.id');

      return this.get('app.assignableAdmins').reject((admin) => {
        return admin.get('id') === adminToReassignId || admin.get('name') === 'Unassigned';
      });
    },
  ),

  teamsWithOneAdmin: filterBy('assignableTeams', 'teamMemberCount', 1),
  teamsWithOnlyAdminToBeReassigned: computed(
    'teamsWithOneAdmin.[]',
    'teamsWithOneAdmin.@each.firstTeamMemberId',
    'adminToReassign.id',
    function () {
      let adminToReassignId = this.get('adminToReassign.id');
      return this.teamsWithOneAdmin.filter(
        (team) => team.get('firstTeamMemberId').toString() === adminToReassignId,
      );
    },
  ),

  assignableTeammates: filterBy('assignableAdminsExcludingAdminToReassign', 'isTeam', false),
  assignableTeams: setDiff('assignableAdminsExcludingAdminToReassign', 'assignableTeammates'),
  assignableTeamsExcludingTeamsWithOnlyAdminToBeReassigned: setDiff(
    'assignableTeams',
    'teamsWithOnlyAdminToBeReassigned',
  ),

  // Items for use in ic-select
  teamItems: map('assignableTeamsExcludingTeamsWithOnlyAdminToBeReassigned', function (team) {
    return this.buildItem(team);
  }),

  teammateItems: map('assignableTeammates', function (teammate) {
    return this.buildItem(teammate);
  }),

  teamsAndTeammatesItems: computed('teamItems', 'teammateItems', 'intl.locale', function () {
    return [
      {
        hasItemsMarkableAsSelected: true,
        heading: this.intl.t(
          'controllers.apps.app.settings.modals.reassign-messages-base.headings.teammates',
        ),
        items: this.teammateItems,
      },
      {
        hasItemsMarkableAsSelected: true,
        heading: this.intl.t(
          'controllers.apps.app.settings.modals.reassign-messages-base.headings.teams',
        ),
        items: this.teamItems,
      },
    ];
  }),

  sendersWithoutAdminToReassignItems: map('sendersWithoutAdminToReassign', function (sender) {
    return this.buildItem(sender);
  }),

  buildItem(teamOrTeammate) {
    return {
      text: teamOrTeammate.get('name'),
      value: teamOrTeammate,
      component: 'settings/teams/team-or-teammate-dropdown-item',
      componentAttrs: teamOrTeammate,
    };
  },
});
