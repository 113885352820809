/* RESPONSIBLE TEAM: team-growth-opportunities */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable ember/no-controllers */
import Controller, { inject as controller } from '@ember/controller';

export default class PlanController extends Controller {
  @controller('apps/app/teams-checkout') teamsController;
}
