/* RESPONSIBLE TEAM: team-channels */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable ember/no-controllers */
import { readOnly, mapBy, setDiff } from '@ember/object/computed';
import { computed } from '@ember/object';
import { next } from '@ember/runloop';
import AccountControllerBase from 'embercom/controllers/apps/app/account/base/account-controller-base';
import { inject as service } from '@ember/service';

export default AccountControllerBase.extend({
  intl: service(),
  tags: readOnly('app.tags'),
  tagIds: mapBy('tags', 'id'),
  visibleTags: computed('admin.visible_tag_ids.[]', 'tags', function () {
    return this.get('admin.visible_tag_ids')
      .map((id) => this.tags.findBy('id', id))
      .compact();
  }),
  hiddenTags: setDiff('tags', 'visibleTags'),
  showSaveSuccessfulNotification: false,
  showSaveErrorNotification: false,
  columns(hiddenOrVisible) {
    return [
      {
        label: this.intl.t('account.visibility.tags.tag-name'),
        valueComponent: 'visibility/tags/table-cells/name',
      },
      {
        label: this.intl.t('account.visibility.tags.created-by'),
        valueComponent: 'visibility/tags/table-cells/created-by',
      },
      {
        label: this.intl.t('account.visibility.tags.created'),
        valueComponent: 'visibility/tags/table-cells/created',
      },
      {
        valueComponent: 'visibility/tags/table-cells/links',
      },
      {
        valueComponent: `visibility/tags/table-cells/actions-for-${hiddenOrVisible}`,
      },
    ];
  },

  scrollToBottomOfVisibleTagsTable() {
    let tableBody = document.querySelector('.visibility__tags-table tbody');
    if (tableBody) {
      tableBody.scrollIntoView(false);
    }
  },

  showTag(tag) {
    this.admin.addTagIdsToAdminsVisibleTagIds([tag.get('id')]);
    next(() => this.scrollToBottomOfVisibleTagsTable());
    this.save();
  },
  hideTag(tag) {
    this.admin.removeTagIdsFromAdminsVisibleTagIds([tag.get('id')]);
    this.save();
  },
});
