/* RESPONSIBLE TEAM: team-tickets-1 */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable ember/no-classic-classes */
import Controller from '@ember/controller';
import { inject as service } from '@ember/service';
import { not } from '@ember/object/computed';
import { REPORTING_RANGE_UPDATED } from 'embercom/services/reporting-service';

export default Controller.extend({
  bootOptionsService: service(),
  reportingService: service(),
  appService: service(),
  commonPrimaryNavBarService: service(),
  showNavBar: not('bootOptionsService.embeddedContent'),

  queryParams: {
    rangeStart: {
      scope: 'controller',
    },
    rangeEnd: {
      scope: 'controller',
    },
  },
  rangeStart: null,
  rangeEnd: null,

  init() {
    this._super(...arguments);
    this.reportingService.on(REPORTING_RANGE_UPDATED, () => {
      let { start, end } = this.reportingService.range;
      this.setProperties({
        rangeStart: start,
        rangeEnd: end,
      });
    });
  },
});
