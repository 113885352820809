/* RESPONSIBLE TEAM: team-customer-data-platform */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/require-snake-case-analytics */
/* eslint-disable ember/no-actions-hash */
/* eslint-disable ember/require-computed-property-dependencies */
/* eslint-disable ember/no-controllers */
/* eslint-disable ember/no-classic-classes */
import { computed } from '@ember/object';
import { alias, bool, filterBy } from '@ember/object/computed';
import Controller, { inject as controller } from '@ember/controller';
import { inject as service } from '@ember/service';
import { task } from 'ember-concurrency';

export default Controller.extend({
  intercomEventService: service(),
  notificationsService: service(),
  attributeService: service(),
  intl: service(),

  appController: controller('apps/app'),
  app: alias('appController.model'),
  isCurrentlyMappingColumn: false,
  csvController: controller('apps.app.import.csv'),
  numberOfSkippedColumns: computed('attributeMappings.@each.columnIndex', function () {
    return this.attributeMappings.filter(function (attributeMapping) {
      return attributeMapping.columnIndex === null;
    }).length;
  }),
  numberOfMappedAttributes: computed('attributeMappings.@each.columnIndex', function () {
    return this.attributeMappings.filter(function (attributeMapping) {
      return attributeMapping.columnIndex !== undefined;
    }).length;
  }),
  hasNoMoreColumnsToMap: computed(
    'model.previewColumns',
    'numberOfSkippedColumns',
    'numberOfMappedAttributes',
    function () {
      return (
        this.get('model.previewColumns').length +
          this.numberOfSkippedColumns -
          this.numberOfMappedAttributes <=
        0
      );
    },
  ),

  finishedMapping: false,

  currentAttributeMapping: computed('predefinedAttributeMappings.@each.columnIndex', function () {
    let attributeMappings = this.predefinedAttributeMappings;

    return attributeMappings.find(function (attributeMapping) {
      return attributeMapping.columnIndex === undefined;
    });
  }),

  currentAttributeMappingIsRequired: bool('currentAttributeMapping.require'),

  previousAttributeMapping: computed(
    'currentAttributeMapping',
    'attributeMappings.@each.columnIndex',
    function () {
      let currentAttributeMapping = this.currentAttributeMapping;
      let attributeMappings = this.attributeMappings;

      if (currentAttributeMapping) {
        let currentAttributeMappingIndex = attributeMappings.indexOf(currentAttributeMapping);

        return attributeMappings[currentAttributeMappingIndex - 1];
      } else {
        return attributeMappings.get('lastObject');
      }
    },
  ),

  previousAttributeMappingIsPredefined: bool('previousAttributeMapping.predefined'),

  currentAttributeMappingNumber: computed(
    'currentAttributeMapping',
    'predefinedAttributeMappings',
    'totalNumberOfAttributesToMap',
    function () {
      if (this.allPredefinedAttributesMapped) {
        return this.totalNumberOfAttributesToMap;
      } else {
        return this.predefinedAttributeMappings.indexOf(this.currentAttributeMapping) + 1;
      }
    },
  ),

  totalNumberOfAttributesToMap: computed('predefinedAttributeMappings', function () {
    return this.predefinedAttributeMappings.length + 1;
  }),

  allPredefinedAttributesMapped: computed(
    'predefinedAttributeMappings.@each.columnIndex',
    function () {
      return this.predefinedAttributeMappings.every(function (attributeMapping) {
        return attributeMapping.columnIndex !== undefined;
      });
    },
  ),

  predefinedAttributeMappings: filterBy('attributeMappings', 'predefined', true),

  customAttributes: computed('attributeService.attributes', 'attributeMappings', function () {
    let identifiers = this.attributeMappings.mapBy('name');

    let attributes = [];
    this.get('attributeService.attributes').forEach(function (attribute) {
      if (!attribute.get('displayable')) {
        return;
      }

      if (!attribute.get('isUserCustomData')) {
        return;
      }

      if (identifiers.indexOf(attribute.get('identifier')) > -1) {
        return;
      }

      attributes.push(attribute);
    });

    return attributes;
  }),

  // Could increase performance issues w/ larger CSVs
  hasHeader: alias('model.hasHeader'),

  attributeMappings: computed('intl.locale', 'model.recordType', function () {
    if (this.get('model.recordType') === 'leads') {
      let mappings = [
        {
          name: 'anonymous_email',
          type: 'String',
          label: this.intl.t('apps.app.import.csv.mapping.lead-email.label'),
          icon: 'email',
          instruction: this.intl.t('apps.app.import.csv.mapping.lead-email.instruction'),
          explanation: this.intl.t('apps.app.import.csv.mapping.lead-email.explanation'),
          columnIndex: undefined,
          predefined: true,
          required: true,
        },
        {
          name: 'name',
          type: 'String',
          label: this.intl.t('apps.app.import.csv.mapping.lead-name.label'),
          icon: 'multiple-people',
          instruction: this.intl.t('apps.app.import.csv.mapping.lead-name.instruction'),
          explanation: this.intl.t('apps.app.import.csv.mapping.lead-name.explanation'),
          columnIndex: undefined,
          predefined: true,
        },
        {
          name: 'phone',
          type: 'String',
          label: this.intl.t('apps.app.import.csv.mapping.lead-phone.label'),
          icon: 'phone',
          instruction: this.intl.t('apps.app.import.csv.mapping.lead-phone.instruction'),
          explanation: this.intl.t('apps.app.import.csv.mapping.lead-phone.explanation'),
          columnIndex: undefined,
          predefined: true,
        },
      ];

      return mappings;
    } else {
      let mappings = [
        {
          name: 'email',
          type: 'String',
          label: this.intl.t('apps.app.import.csv.mapping.user-email.label'),
          icon: 'email',
          instruction: this.intl.t('apps.app.import.csv.mapping.user-email.instruction'),
          explanation: this.intl.t('apps.app.import.csv.mapping.user-email.explanation'),
          columnIndex: undefined,
          predefined: true,
          required: true,
        },
        {
          name: 'user_id',
          type: 'String',
          label: this.intl.t('apps.app.import.csv.mapping.user-id.label'),
          icon: 'multiple-people',
          instruction: this.intl.t('apps.app.import.csv.mapping.user-id.instruction'),
          explanation: this.intl.t('apps.app.import.csv.mapping.user-id.explanation'),
          columnIndex: undefined,
          predefined: true,
        },
        {
          name: 'name',
          type: 'String',
          label: this.intl.t('apps.app.import.csv.mapping.user-name.label'),
          icon: 'multiple-people',
          instruction: this.intl.t('apps.app.import.csv.mapping.user-name.instruction'),
          explanation: this.intl.t('apps.app.import.csv.mapping.user-name.explanation'),
          columnIndex: undefined,
          predefined: true,
        },
        {
          name: 'created_at',
          type: 'Date',
          label: this.intl.t('apps.app.import.csv.mapping.user-date.label'),
          icon: 'calendar',
          instruction: this.intl.t('apps.app.import.csv.mapping.user-date.instruction'),
          explanation: this.intl.t('apps.app.import.csv.mapping.user-date.explanation'),
          columnIndex: undefined,
          predefined: true,
        },
        {
          name: 'phone',
          type: 'String',
          label: this.intl.t('apps.app.import.csv.mapping.user-phone.label'),
          icon: 'phone',
          instruction: this.intl.t('apps.app.import.csv.mapping.user-phone.instruction'),
          explanation: this.intl.t('apps.app.import.csv.mapping.user-phone.explanation'),
          columnIndex: undefined,
          predefined: true,
        },
      ];

      return mappings;
    }
  }),

  updateManualTagName(csvImport) {
    return csvImport.reload();
  },

  completeMapping: task(function* () {
    try {
      let attributeMappings = this.attributeMappings.filter((attributeMapping) => {
        let columnIndex = attributeMapping.columnIndex;
        return columnIndex !== undefined && columnIndex !== null;
      });

      let columnAssignments = attributeMappings.map((attributeMapping) => {
        return {
          external_user_field: attributeMapping.name,
          column_index: attributeMapping.columnIndex,
          data_type: attributeMapping.type,
        };
      });

      let params = { csv_import_column_assignments: columnAssignments };
      let assignments = yield this.model.createColumnAssignments(params);

      if (assignments) {
        this.intercomEventService.trackAnalyticsEvent({
          action: 'csv-mapping-completed',
          object: 'csv-import',
        });
        this.transitionToRoute('apps.app.import.csv.tag', this.model);
      }
    } catch (error) {
      if (error && error.jqXHR) {
        this.notificationsService.notifyError(error.jqXHR.responseJSON.error);
      } else {
        this.notificationsService.notifyError(
          this.intl.t('apps.app.import.csv.mapping.failed-to-upload'),
        );
      }
    }
  }),

  actions: {
    unMapPreviousAttribute() {
      if (this.previousAttributeMappingIsPredefined) {
        this.set('previousAttributeMapping.columnIndex', undefined);
        this.csvController.set('currentStep', 2);
      } else {
        this.attributeMappings.removeObject(this.previousAttributeMapping);
        this.csvController.set('currentStep', 2);
      }
    },

    skipMappingAttribute() {
      this.set('currentAttributeMapping.columnIndex', null);
    },

    editAttributeMappings() {
      this.set('finishedMapping', false);
      this.csvController.set('currentStep', 2);
    },

    finishMappingAttributes() {
      this.set('finishedMapping', true);
      this.csvController.set('currentStep', 2.5);
    },

    setAttributeMapping(mapping) {
      if (mapping.label) {
        this.attributeMappings.pushObject(mapping);
      } else {
        this.set('currentAttributeMapping.columnIndex', mapping.columnIndex);
      }

      if (this.hasNoMoreColumnsToMap) {
        this.completeMapping.perform();
      }
    },
  },
});
