/* RESPONSIBLE TEAM: team-help-desk-experience */
import Controller from '@ember/controller';
import { tracked } from '@glimmer/tracking';
import {
  ConversationsViewType,
  LOCALSTORAGE_CONVERSATION_VIEW_KEY,
} from 'embercom/services/inbox-state';
import storage from 'embercom/vendor/intercom/storage';

export default class WorkspaceInboxController extends Controller {
  queryParams = ['view', 'source', 'product_tour_id', { openCopilot: 'open_copilot' }];

  @tracked private _view?: ConversationsViewType;

  get view() {
    return this._view;
  }

  set view(value: any) {
    // We don't want to persist a value on first load, only when the view
    // changes. When the view has no value, that likely means it's the first
    // load.
    if (this._view && this._view !== value && value in ConversationsViewType) {
      storage.set(LOCALSTORAGE_CONVERSATION_VIEW_KEY, value);
    }

    this._view = value;
  }
}
