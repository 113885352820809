/* RESPONSIBLE TEAM: team-customer-data-platform */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
/* eslint-disable ember/no-new-mixins */
import { and } from '@ember/object/computed';
import { computed } from '@ember/object';
import Mixin from '@ember/object/mixin';
import { ternaryToProperty } from '@intercom/pulse/lib/computed-properties';

export default Mixin.create({
  defaultGeoipData: computed(function () {
    return {
      latitude: 0,
      longitude: 0,
    };
  }),
  geoipDataAvailable: and('modelGeoipData.latitude', 'modelGeoipData.longitude'),
  geoipData: ternaryToProperty('geoipDataAvailable', 'modelGeoipData', 'defaultGeoipData'),
  mapUrl: computed('geoipData.{longitude,latitude}', function () {
    return `https://api.mapbox.com/styles/v1/patrickod/cjbcj1mh978pd2rkd15d5aauf/static/${this.geoipData.longitude},${this.geoipData.latitude},2/1280x186?access_token=pk.eyJ1IjoicGF0cmlja29kIiwiYSI6ImY1LVY4WkUifQ.WK9SrChxuv4vz1NxPDooSw&attribution=false&logo=false`;
  }),
});
