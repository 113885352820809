/* RESPONSIBLE TEAM: team-help-desk-experience */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
/* eslint-disable ember/no-actions-hash */
/* eslint-disable ember/no-controllers */
import { computed } from '@ember/object';
import { readOnly, equal, filterBy, mapBy, or } from '@ember/object/computed';
import { inject as controller } from '@ember/controller';
import { task } from 'ember-concurrency';
import AttributeSettingsController from 'embercom/controllers/apps/app/settings/base/attribute-settings-controller';
import { inject as service } from '@ember/service';
import { ternaryToProperty } from '@intercom/pulse/lib/computed-properties';

export default AttributeSettingsController.extend({
  store: service(),
  notificationsService: service(),
  initialSelectedIdentifiers: [],
  selectedTab: 'people',
  appController: controller('apps/app'),
  app: readOnly('appController.model'),

  peopleIsSelected: equal('selectedTab', 'people'),
  companyIsSelected: equal('selectedTab', 'company'),
  peopleAttributes: filterBy('attributeService.displayableAttributes', 'attribute.isUser', true),
  companyAttributes: filterBy(
    'attributeService.displayableAttributes',
    'attribute.isCompany',
    true,
  ),
  attributesAndRelatedObjects: ternaryToProperty(
    'peopleIsSelected',
    'peopleAttributes',
    'companyAttributes',
  ),
  attributes: mapBy('attributesAndRelatedObjects', 'attribute'),
  isSaving: or(
    'addQualificationAttributeTask.isRunning',
    'removeQualificationAttributeTask.isRunning',
  ),
  initialSelectedAttributes: computed('initialSelectedIdentifiers', 'attributes', function () {
    return this.attributes.filter((attribute) => {
      return this.initialSelectedIdentifiers.includes(attribute.get('identifier'));
    });
  }),
  initialDeselectedAttributes: computed('initialSelectedIdentifiers', 'attributes', function () {
    return this.attributes.reject((attribute) => {
      return this.initialSelectedIdentifiers.includes(attribute.get('identifier'));
    });
  }),

  addQualificationAttributeTask: task(function* (attribute) {
    try {
      yield this.store
        .createRecord('people/qualification-attribute', {
          identifier: attribute.get('identifier'),
        })
        .save();
    } catch (e) {
      this.notificationsService.notifyResponseError(e, {
        default: `Something went wrong and we couldn't add this lead acquisition attribute.`,
      });
    }
  }),
  removeQualificationAttributeTask: task(function* (attribute) {
    let qualificationAttribute = this.model.findBy('identifier', attribute.get('identifier'));
    try {
      this.model.removeObject(qualificationAttribute);
      yield qualificationAttribute.destroyRecord();
    } catch (e) {
      this.notificationsService.notifyResponseError(e, {
        default: `Something went wrong and we couldn't delete this lead qualification attribute.`,
      });
      this.model.pushObject(qualificationAttribute);
    }
  }),

  actions: {
    switchTab(selectedTab) {
      this.set('selectedTab', selectedTab);
    },
    toggleLeadAttribute(checked, attribute) {
      let taskName = checked ? 'addQualificationAttributeTask' : 'removeQualificationAttributeTask';
      this.get(taskName).perform(attribute);
    },
  },
});
