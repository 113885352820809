/* RESPONSIBLE TEAM: team-knowledge-and-data-setup */
import Controller from '@ember/controller';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import type RouterService from '@ember/routing/router-service';
import safeWindowOpen from 'embercom/lib/safe-window-open';
import type { EditorMode } from 'embercom/components/knowledge-hub/content-editor/drawer-editor';

export default class CollectionsController extends Controller {
  @service declare appService: any;
  @service declare helpCenterService: any;
  @service declare router: RouterService;
  containerReference: any;

  @tracked createNewCollectionAction: any;

  // Code to get the preview appearing alongside collections
  get uploadHelper() {
    return this.model.uploadHelper;
  }

  get groupList() {
    let items = this.helpCenterService.site.selectedLocalesOrdered.map(
      (locale: $TSFixMe) => locale.data,
    );

    items.forEach((locale: $TSFixMe) => {
      locale.text = locale.name;
    });

    return [{ items }];
  }

  @action
  updateLocale(locale: $TSFixMe) {
    this.helpCenterService.updateLocale(locale.localeId);
  }

  declare model: {
    allCollections: any;
    allArticlesSummaries: any;
    uploadHelper: any;
  };

  queryParams = ['collectionId', 'activeContentId', 'activeContentType', 'editorMode'];

  @tracked activeContentId?: number;
  @tracked activeContentType?: string;
  @tracked editorMode?: EditorMode;
  @tracked collectionId: string | undefined;

  @action removeActiveContentId() {
    this.router.transitionTo({
      queryParams: {
        ...this.queryParams,
        activeContentId: null,
        activeContentType: null,
        editorMode: null,
      },
    });
  }

  @action
  getContainerRef() {
    return this.containerReference;
  }

  get app() {
    return this.appService.app;
  }

  get helpCenterSite() {
    return this.helpCenterService.site;
  }

  get collectionsWithoutHome() {
    return this.allCollections.rejectBy('isHome', true);
  }

  get collections() {
    return this.collectionsWithoutHome.sortBy('order');
  }

  get selectedLocaleId() {
    return this.helpCenterService.currentlySelectedLocaleId;
  }

  get defaultLocaleId() {
    return this.helpCenterSite.locale;
  }

  get selectedLocaleName() {
    let localeCount = '';
    if (this.helpCenterService.site.selectedLocalesOrdered.length > 1) {
      localeCount = ` (${this.helpCenterService.site.selectedLocalesOrdered.length})`;
    }
    return `${this.helpCenterService.getLocaleNameFromLocaleCode(this.selectedLocaleId)}${localeCount}`;
  }

  get allCollections() {
    return this.model.allCollections;
  }

  get allArticlesSummaries() {
    return this.model.allArticlesSummaries;
  }

  get selectedCollection() {
    if (!this.collectionId) {
      return null;
    }

    return this.collectionsWithoutHome.findBy('id', this.collectionId);
  }

  get shouldShowHeader() {
    return !(this.app.newHcSetupFlow && this.collections.length === 0);
  }

  get shouldShowPreview() {
    return this.app.newHcSetupFlow ? this.collections.length > 0 : true;
  }

  @action
  refreshData() {
    this.send('refreshModel');
  }

  @action
  goToAllArticles() {
    let url = this.router.urlFor('apps.app.articles');
    safeWindowOpen(url, '_blank');
  }

  @action
  createNewCollection() {
    if (this.createNewCollectionAction) {
      this.createNewCollectionAction();
    }
  }
}
