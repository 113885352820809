/* RESPONSIBLE TEAM: team-data-interop */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
/* eslint-disable ember/no-controllers */
/* eslint-disable ember/use-brace-expansion */
/* eslint-disable ember/no-classic-classes */
import Controller from '@ember/controller';
import { inject as service } from '@ember/service';
import { alias, bool } from '@ember/object/computed';
import { computed, action } from '@ember/object';
import { task } from 'ember-concurrency';
import reviewStateMachine from 'embercom/lib/developer-hub/review-state-machine';
import { isBlank } from '@ember/utils';

export default Controller.extend({
  intercomEventService: service(),
  notificationsService: service(),
  developerHub: service(),
  intercomConfirmService: service(),
  appPackage: alias('developerHub.appPackage'),
  isOnEdit: alias('developerHub.isOnEditPage'),
  useOAuthFlow: bool('appPackage.changeRequest.appPackageConfig.oauthClient.useOAuthFlow'),
  isOnNoAppPartner: alias('developerHub.isOnNoAppPartner'),
  showEditButton: computed(function () {
    return !'isOnNoAppPartner' && 'useOAuthFlow';
  }),
  showDiscardButton: computed(
    'appPackage',
    'appPackage.changeRequest',
    'appPackage.changeRequest.appPackageConfig',
    function () {
      let reviewState = reviewStateMachine(this.appPackage);
      return reviewState.displayDiscardButton;
    },
  ),
  isPublicApp: alias('developerHub.isPublicApp'),
  isOauthClienthasChanged: alias('developerHub.oauthClientHasChanges'),
  addAnalyticsEvent(action) {
    this.intercomEventService.trackAnalyticsEvent({
      object: 'app_package',
      app_package_id: this.get('appPackage.id'),
      app_metadata: this.get('appPackage.changeRequest'),
      action,
    });
  },

  discardChangesTask: task(function* () {
    let isConfirmed = yield this.intercomConfirmService.confirm({
      title: 'Discard changes?',
      confirmButtonText: 'Discard',
      cancleButtonText: 'Cancel',
      body: 'Discarding changes will withdraw your app from the review process.',
      primaryButtonType: 'primary-destructive',
    });
    if (!isConfirmed) {
      return;
    }
    try {
      yield this.appPackage.cancelReview();
      this.addAnalyticsEvent('discard_changes');
      this.transitionToRoute('apps.app.developer-hub.app-packages.app-package.basic-info');
    } catch (e) {
      this.notificationsService.notifyError('There was a problem');
    }
  }),

  isChangeRequestInvalid: task(function* () {
    let { validations } = yield this.get('appPackage.changeRequest').validate();
    if (
      this.isPublicApp &&
      !this.isOauthClienthasChanged &&
      isBlank(this.get('appPackage.changeRequest.videoUrl'))
    ) {
      return this.get('appPackage.changeRequest.validations.attrs.description.isInvalid');
    } else {
      return validations.get('isInvalid');
    }
  }),

  submitReviewTask: task(function* () {
    try {
      let isInvalid = yield this.isChangeRequestInvalid.perform();
      if (isInvalid) {
        return;
      }

      yield this.appPackage.submitReview();
      this.addAnalyticsEvent('submitted');
      this.notificationsService.notifyConfirmation(
        'Your app review has been submitted to Intercom.',
      );
      this.transitionToRoute('apps.app.developer-hub.app-packages.app-package.review');
    } catch (e) {
      this.notificationsService.notifyError('There was a problem submitting your review.');
    }
  }),
  clickLearnMoreLink: action(function (event) {
    this.intercomEventService.trackAnalyticsEvent({
      action: 'clicked',
      object: 'more_about_publishing_apps_link',
      place: 'developer_hub',
      section: 'submit_for_review',
    });
  }),
});
