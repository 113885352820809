/* RESPONSIBLE TEAM: team-data-interop */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable ember/no-controllers */
/* eslint-disable ember/no-classic-classes */
import { and, not, equal, readOnly } from '@ember/object/computed';
import Controller from '@ember/controller';
import { inject as service } from '@ember/service';
import { ternary } from '@intercom/pulse/lib/computed-properties';

export default Controller.extend({
  developerHub: service(),
  appPackage: readOnly('developerHub.appPackage'),
  draftState: equal('appPackage.changeRequest.state', 'draft'),
  noPendingChanges: not('developerHub.appPackageHasChanges'),
  privateAppPackageUsingOauth: and(
    'appPackage.isPrivateApp',
    'appPackage.oauthClient.useOAuthFlow',
  ),
  notPrivateAppPackageUsingOauth: not('privateAppPackageUsingOauth'),
  hideReviewBanner: and('draftState', 'noPendingChanges', 'notPrivateAppPackageUsingOauth'),

  contentOutletClass: ternary(
    'hideReviewBanner',
    'developer-hub__content-outlet',
    'developer-hub__content-outlet-with-banner',
  ),
});
