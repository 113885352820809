/* RESPONSIBLE TEAM: team-product-guidance */
import Controller from '@ember/controller';
import { tracked } from '@glimmer/tracking';

export default class PreviewFinController extends Controller {
  queryParams = ['token', 'source'];

  @tracked token = '';
  @tracked source = '';
}
