/* RESPONSIBLE TEAM: team-data-interop */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
/* eslint-disable ember/no-controllers */
/* eslint-disable ember/no-classic-classes */
import { reads, alias } from '@ember/object/computed';
import Evented from '@ember/object/evented';
import Controller, { inject as controller } from '@ember/controller';
import { notImplemented } from '@intercom/pulse/lib/computed-properties';
import { inject as service } from '@ember/service';
import { task } from 'ember-concurrency';

export default Controller.extend(Evented, {
  intercomEventService: service(),
  notificationsService: service(),
  intercomConfirmService: service(),
  appController: controller('apps/app'),
  app: reads('appController.model'),
  integration: alias('model.integration'),

  deleteModelTask: notImplemented(),
  confirmRemoveIntegration() {
    let options = {
      title: 'Remove integration',
      body: `Are you sure you want to remove ${this.get('integration.name')} integration?`,
      confirmButtonText: 'Remove',
    };

    return this.intercomConfirmService.confirm(options);
  },

  removeIntegration: task(function* () {
    let confirmed = yield this.confirmRemoveIntegration();

    if (!confirmed) {
      return;
    }
    yield this.destroyIntegrationRecordAndRedirect.perform();
  }).drop(),

  destroyIntegrationRecordAndRedirect: task(function* () {
    try {
      this.intercomEventService.trackEvent(
        `dx-removed-${this.get('integration.code')}-integration`,
        {
          action: 'removed',
          object: `${this.get('integration.code')}-integration`,
          owner: 'dx',
          place: 'integrations-hub',
          integration_id: this.get('integration.code'),
        },
      );
      yield this.deleteModelTask.perform();

      let deletedRecord = this.get('integration.connectedIntegration');

      if (deletedRecord.content) {
        deletedRecord.content.unloadRecord();
      }

      this.transitionToRoute('apps.app.integrations-hub');
    } catch (e) {
      console.error(e);
      this.notificationsService.notifyError(
        `An error occurred when deleting ${this.get('integration.name')} credentials`,
      );
    }
  }).drop(),
});
