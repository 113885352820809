/* RESPONSIBLE TEAM: team-channels */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable ember/no-actions-hash */
/* eslint-disable promise/prefer-await-to-then */
/* eslint-disable ember/no-controllers */
/* eslint-disable ember/no-classic-classes */
import { inject as service } from '@ember/service';
import { reads, alias, filterBy, union } from '@ember/object/computed';
import Controller, { inject as controller } from '@ember/controller';
import AdminObjectVisibility from 'embercom/components/mixins/admin-object-visibility';
import { computed } from '@ember/object';

export default Controller.extend(AdminObjectVisibility, {
  intl: service(),
  appController: controller('apps/app'),
  app: reads('appController.model'),
  admin: alias('model'),
  showSaveSuccessfulNotification: true,
  showSaveErrorNotification: true,
  saveSuccessNotificationMessage: computed('intl.locale', function () {
    return this.intl.t('account.base.account-controller-base.save-success');
  }),
  saveErrorNotificationMessage: computed('intl.locale', function () {
    return this.intl.t('account.base.account-controller-base.save-error');
  }),
  notificationsService: service('notificationsService'),
  nonEditableSegments: filterBy('segments', 'is_editable', false),
  allVisibleSegments: union('nonEditableSegments', 'visibleSegments'),

  beforeSave() {
    // Implemented by subclass
  },

  afterSave() {
    // Implemented by subclass
  },

  hasUnsavedChanges() {
    return this.get('model.hasDirtyAttributes');
  },

  revertUnsavedChanges() {
    this.model.revert();
  },

  save() {
    this.beforeSave();
    this.setProperties({
      isSaving: true,
      hasErrored: false,
    });
    this.model
      .save()
      .then(this._handleSaveSuccessful.bind(this))
      .catch(this._handleSaveError.bind(this));
  },

  _handleSaveSuccessful() {
    this.setProperties({
      isSaving: false,
      hasErrored: false,
    });
    this.afterSave();
    if (this.showSaveSuccessfulNotification) {
      this.notificationsService.notifyConfirmation(this.saveSuccessNotificationMessage);
    }
  },

  _handleSaveError(e) {
    this.setProperties({
      isSaving: false,
      hasErrored: true,
    });
    if (this.showSaveErrorNotification) {
      this.notificationsService.notifyError(this.saveErrorNotificationMessage);
    }
  },

  actions: {
    save() {
      this.save();
    },
    segmentColumns(hiddenOrVisible) {
      return [
        {
          label: this.intl.t('account.base.account-controller-base.segment-name'),
          valueComponent: 'visibility/segments/table-cells/name',
        },
        {
          label: this.intl.t('account.base.account-controller-base.created-by'),
          valueComponent: 'visibility/segments/table-cells/created-by',
        },
        {
          label: this.intl.t('account.base.account-controller-base.created'),
          valueComponent: 'visibility/segments/table-cells/created',
        },
        {
          valueComponent: `visibility/segments/table-cells/actions-for-${hiddenOrVisible}`,
        },
      ];
    },
  },
});
