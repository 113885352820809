/* RESPONSIBLE TEAM: team-pricing-and-packaging */
import Controller from '@ember/controller';
import { action } from '@ember/object';
import { type Router } from '@ember/routing';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import type IntlService from 'ember-intl/services/intl';
import { confirmSubscriptionChanges } from 'embercom/lib/admins/inbox-seat-info';
import { confirmMultipleSeatOveragesIncrease } from 'embercom/lib/admins/multiple-seats-info';
import type IntercomConfirmService from 'embercom/services/intercom-confirm-service';
import { COPILOT_SEAT_TYPE as COPILOT } from 'embercom/lib/settings/seats/constants';

export default class EditTeammateInvite extends Controller {
  queryParams = [];

  @service declare appService: $TSFixMe;
  @service declare notificationsService: $TSFixMe;
  @service declare intercomConfirmService: IntercomConfirmService;
  @service declare intl: IntlService;
  @service declare permissionsMutatorService: $TSFixMe;
  @service declare router: Router;
  @service declare intercomEventService: $TSFixMe;

  @tracked initialUsage: $TSFixMe;
  @tracked updatedUsage: $TSFixMe;
  declare model: $TSFixMe;

  async saveChanges() {
    try {
      let invite = this.model.invite;
      await invite.save();
      this.intercomEventService.trackAnalyticsEvent({
        action: 'updated',
        // `intercomEventService.trackAnalyticsEvent` requires the `object` value to be lowercase & snake_case
        // however, since this object is already in codebase and most probably, data is already being collected against this property
        // so we are leaving it as is and disabling the error so analytics won't break
        // eslint-disable-next-line @intercom/intercom/require-snake-case-analytics
        object: 'teammate-invite',
        place: 'teammate-list',
      });
      this.notificationsService.notifyConfirmation(
        this.intl.t('settings.teammates.invite.notification.edit-success-generic', {
          email: invite.email,
        }),
      );
      let transition = this.router.transitionTo('apps.app.settings.workspace.teammates', {
        queryParams: {
          tab: 'invited',
        },
      });
      // @ts-ignore
      transition.data.selectedTab = 'invites';
    } catch (error) {
      if (error.jqXHR?.responseJSON?.error_code === 'seat_limit_reached') {
        this.notificationsService.notifyErrorWithButton(
          this.intl.t('pricing-and-packaging.multi_workspace_seat_limit_reached.error_message'),
          {
            label: this.intl.t(
              'pricing-and-packaging.multi_workspace_seat_limit_reached.button_label',
            ),
            action: this.redirectToBilling.bind(this),
          },
          10000,
        );
      } else {
        this.notificationsService.notifyError(
          this.intl.t('settings.teammates.invite.notification.unexpected-error'),
        );
      }
      console.error(error);
    }
  }

  redirectToBilling() {
    this.router.transitionTo('apps.app.billing');
  }

  get numberOfSeatsRequired() {
    let invite = this.model.invite;
    return invite.hasDirtyAttributes &&
      invite.has_inbox_access &&
      invite.changedAttributes().has_inbox_access
      ? 1
      : 0;
  }

  @action
  setInitialUsage(usage: $TSFixMe) {
    this.initialUsage = usage;
  }

  @action
  onChangeLocale(locale: string) {
    this.model.invite.locale = locale;
  }

  @action
  setUpdatedUsage(usage: $TSFixMe) {
    this.updatedUsage = usage;
  }

  @action
  async confirmAndSaveChanges() {
    if (
      (await this.confirmMultipleSeatOveragesIncreaseIfHasSeats()) &&
      (await confirmSubscriptionChanges(
        this.numberOfSeatsRequired,
        this.showConfirmModal,
        {
          confirmationButtonLabel: this.intl.t(
            'settings.teammates.invite.save-changes-modal.save-and-fill-seat',
          ),
          editFlow: true,
        },
        false,
        this.appService.app.canUseFinAiCopilotAddon &&
          this.model.invite.seatTypes.includes(COPILOT),
        this.model.copilotData,
      ))
    ) {
      await this.saveChanges();
    }
  }

  @action
  async showConfirmModal(options: $TSFixMe) {
    return this.intercomConfirmService.confirm(options);
  }

  async confirmMultipleSeatOveragesIncreaseIfHasSeats() {
    if (this.appService.app.hasMultipleSeatTypes) {
      return await confirmMultipleSeatOveragesIncrease(
        this.appService.app,
        {
          seatTypes: this.model.invite.seatTypes,
          initialUsage: this.initialUsage,
          updatedUsage: this.updatedUsage,
          teammateCount: 1,
          isInvite: true,
          haveSeatsChanged: false,
        },
        // @ts-ignore
        this.showConfirmModal,
      );
    }
    return true;
  }
}
