/* RESPONSIBLE TEAM: team-phone */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable ember/no-controllers */

import Controller from '@ember/controller';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import safeWindowOpen from 'embercom/lib/safe-window-open';

export default class AmazonConnectCti extends Controller {
  queryParams = ['contactCentreUrl'];
  @service amazonCtiService;

  @action
  initialize() {
    this.amazonCtiService.initialize(this.contactCentreUrl);
  }

  get isInitialised() {
    return this.amazonCtiService.isInitialised;
  }

  @action
  openCtiSignIn() {
    safeWindowOpen(this.amazonCtiService.instanceUrl).focus();
    window.location.reload();
  }
}
