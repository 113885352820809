/* RESPONSIBLE TEAM: team-workflows */
import Controller from '@ember/controller';
import { inject as service } from '@ember/service';
import storage from 'embercom/vendor/intercom/storage';

const STORAGE_KEY = 'rules-opt-in-started';

export default class IndexRulesController extends Controller {
  queryParams = [];

  @service declare appService: $TSFixMe;

  get models(): $TSFixMe {
    return this.model;
  }

  get app() {
    return this.appService.app;
  }

  get taskStartedBefore() {
    let optInStarted = storage.get(STORAGE_KEY)?.optInStarted;
    return optInStarted || false;
  }

  get workflows() {
    return this.models.workflows;
  }

  get actionContext() {
    return {
      conversationSlas: this.models.conversationSlas,
      officeHoursSchedules: this.models.officeHoursSchedules,
      workflowConnectorActions: this.models.workflowConnectorActions,
    };
  }

  get launchPaywall() {
    return this.models.launchPaywall;
  }
}
