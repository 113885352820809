/* RESPONSIBLE TEAM: team-help-desk-experience */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable ember/no-controllers */
import Controller from '@ember/controller';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { task } from 'ember-concurrency-decorators';
import { inject as service } from '@ember/service';
import { isValidEmail } from 'embercom/lib/email';

export default class SamlController extends Controller {
  @service store;
  @service redirectService;
  @service notificationsService;
  @service intl;

  @tracked samlEmail = '';
  @tracked samlAuths = null;

  get signInButtonDisabled() {
    return !isValidEmail(this.samlEmail);
  }

  get emailInputIsInvalid() {
    return this.samlEmail !== '' && this.signInButtonDisabled;
  }

  get locale() {
    return navigator.language;
  }

  @action
  redirectToIdP(samlAuth) {
    this.redirectService.redirect(`/saml/${samlAuth.id}`);
  }

  @action
  redirectToPricing() {
    this.redirectService.redirect(`/pricing`);
  }

  @action
  changeEmail() {
    this.samlEmail = '';
    this.samlAuths = null;
  }

  @task
  *startAuth() {
    try {
      this.samlAuths = yield this.store.query('saml-auth', { email: this.samlEmail.trim() });
      if (this.samlAuths.length === 1) {
        this.redirectToIdP(this.samlAuths.firstObject);
      }
    } catch (e) {
      if (e.jqXHR.status === 404 || e.jqXHR.status === 400) {
        this.notificationsService.notifyError(e.jqXHR.responseJSON.error);
      } else {
        this.notificationsService.notifyError(
          this.intl.t('saml.error-notification', { locale: this.locale }),
        );
      }
    }
  }
}
