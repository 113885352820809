/* RESPONSIBLE TEAM: team-knowledge-and-data-setup */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable ember/no-actions-hash */
/* eslint-disable ember/no-classic-classes */
import { match, readOnly, reads } from '@ember/object/computed';
import { inject as service } from '@ember/service';
import Controller from '@ember/controller';
import RouteRegexes from 'embercom/lib/route-regexes';

export default Controller.extend({
  navbarCollapsingService: service(),
  intercomEventService: service(),
  appService: service(),
  identityVerificationRiskStateService: service(),
  helpCenterService: service(),
  router: service(),

  app: readOnly('appService.app'),
  navbarCollapsed: readOnly('navbarCollapsingService.collapsed'),

  isOnArticlesListAllRoute: match('router.currentRouteName', RouteRegexes.articleListAll),
  showSearch: reads('isOnArticlesListAllRoute'),

  queryParams: {
    selectedLocaleId: {
      as: 'language',
      scope: 'controller',
    },
    selectedTagValues: {
      as: 'tag_ids',
      scope: 'controller',
    },
    externalStep: {
      as: 'external_step',
      scope: 'controller',
    },
    request: {
      as: 'request',
      scope: 'controller',
    },
  },

  externalStep: null,

  actions: {
    toggleSearch() {
      this.toggleProperty('showSearch');

      if (!this.showSearch || !this.isOnArticlesListAllRoute) {
        this._resetSearch();
      }
    },

    transitionToSettingsPage(helpCenterSite) {
      this.router.transitionTo('apps.app.articles.site.settings.setting', helpCenterSite.id);
    },
  },

  _resetSearch() {
    let toRouteName = this.isOnArticlesListAllRoute
      ? this.router.currentRouteName
      : 'apps.app.articles.articles.list.all';

    this.transitionToRoute(toRouteName, {
      queryParams: {
        search_term: null,
        url_partials: [],
      },
    });
  },
});
