/* RESPONSIBLE TEAM: team-frontend-tech */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable ember/no-actions-hash */
/* eslint-disable ember/no-classic-classes */
import { alias } from '@ember/object/computed';
import Evented from '@ember/object/evented';
import Controller, { inject as controller } from '@ember/controller';
import EmbercomBootDataModule from 'embercom/lib/embercom-boot-data';
import { inject as service } from '@ember/service';

export default Controller.extend(Evented, {
  router: service(),
  intercomEventService: service(),
  queryParams: ['ember_rev', 'show_l10n', 'debug'],
  tooltipComponent: null,
  ember_rev: null,
  show_l10n: null,
  model: {},
  shouldWarnOnNavigation: false,
  appController: controller('apps/app'),
  app: alias('appController.model'),
  isBootedInPublicAppStoreMode: EmbercomBootDataModule.isBootedInPublicAppStoreMode(),
  // eslint-disable-next-line @intercom/intercom/no-legacy-modal
  modalService: service(),
  intercomConfirmService: service(),
  clientProfilerService: service(),

  actions: {
    toggleKeyboardShortcutsModal() {
      if (
        !this.router.currentRouteName.startsWith('inbox.workspace') &&
        !this.router.currentRouteName.startsWith('inbox_loading')
      ) {
        this.send('toggleModal', 'keyboard-shortcuts-modal', {});
      }
    },
  },
});
