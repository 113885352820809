/* RESPONSIBLE TEAM: team-customer-data-platform */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable ember/require-computed-property-dependencies */
/* eslint-disable ember/no-new-mixins */
import { computed } from '@ember/object';
import { filterBy, gt, notEmpty, reads, not } from '@ember/object/computed';
import Mixin from '@ember/object/mixin';
import LegacySelectionState from 'embercom/models/legacy-selection-state';

export default Mixin.create({
  selected: filterBy('model', 'selected', true),
  unselected: filterBy('model', 'selected', false),
  isSelectAllMode: false,

  selectAll: computed('model.@each.selected', {
    get() {
      let model = this.model;
      return model.get('length') > 0 && model.isEvery('selected', true);
    },
    set(key, value) {
      this.model.setEach('selected', value);
      this.set('isSelectAllMode', value);
      return value;
    },
  }),

  someSelected: computed('model.@each.selected', function () {
    let model = this.model;
    return model.isAny('selected', true) && !model.isEvery('selected', true);
  }),
  hasSelectedUsers: gt('selectedCount', 0),
  hasUsers: notEmpty('model'),
  bulkOperationsEnabled: reads('hasUsers'),
  bulkOperationsDisabled: not('bulkOperationsEnabled'),
  selectedCount: reads('selectionState.count'),
  selectionState: computed(
    'isSelectAllMode',
    'selected',
    'unselected',
    'predicates',
    'search.text',
    function () {
      let selectionState = LegacySelectionState.create({
        isSelectAllMode: this.isSelectAllMode,
        selected: this.selected,
        unselected: this.unselected,
        predicates: this.predicates,
        totalCount: this.totalCount,
        search: this.get('search.text'),
        countSuccessful: this.countSuccessful,
      });
      return selectionState;
    },
  ),
});
