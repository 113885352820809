/* RESPONSIBLE TEAM: team-channels */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable ember/no-actions-hash */
/* eslint-disable promise/prefer-await-to-then */
/* eslint-disable ember/no-controllers */
import { notEmpty, equal } from '@ember/object/computed';
import ajax from 'embercom/lib/ajax';
import { inject as service } from '@ember/service';
import AccountControllerBase from 'embercom/controllers/apps/app/account/base/account-controller-base';

export default AccountControllerBase.extend({
  notificationsService: service(),
  intl: service(),
  validationCode: null,
  hasValidationCode: notEmpty('validationCode'),
  hasSixDigitValidationCode: equal('validationCode.length', 6),

  _handleErrorResponse(error) {
    if (error.jqXHR.status === 403) {
      this.notificationsService.notifyError(
        this.intl.t('apps.app.account.two-factor-auth.could-not-validate'),
      );
    } else {
      this.notificationsService.notifyError(
        this.intl.t('apps.app.account.two-factor-auth.try-again'),
      );
    }
  },
  actions: {
    enable2FA() {
      if (this.hasSixDigitValidationCode) {
        return ajax({
          type: 'PUT',
          url: '/ember/admins/enable_two_factor_auth',
          data: JSON.stringify({
            id: this.get('admin.id'),
            app_id: this.get('admin.current_app_id'),
            validation_code: this.validationCode,
          }),
        }).then(
          function () {
            this.notificationsService.notifyConfirmation(
              this.intl.t('apps.app.account.two-factor-auth.verification-enabled'),
            );
            this.set('admin.has_enabled_2fa', true);
            this.transitionToRoute('apps.app.account.general');
          }.bind(this),
          function (error) {
            this._handleErrorResponse(error);
            this.set('validationCode', null);
          }.bind(this),
        );
      } else if (this.hasValidationCode && !this.hasSixDigitValidationCode) {
        this.notificationsService.notifyWarning(
          this.intl.t('apps.app.account.two-factor-auth.six-digit-length'),
        );
      } else {
        this.notificationsService.notifyWarning(
          this.intl.t('apps.app.account.two-factor-auth.code-empty'),
        );
      }
    },
  },
});
