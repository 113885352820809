/* RESPONSIBLE TEAM: team-knowledge-and-data-setup */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable ember/require-computed-property-dependencies */
/* eslint-disable ember/no-controllers */
/* eslint-disable ember/no-classic-classes */
import { set } from '@ember/object';
import { action, computed } from '@ember/object';
import { reads, equal, readOnly, gt } from '@ember/object/computed';
import Controller, { inject as controller } from '@ember/controller';
import { makeStatsParametersForArticle } from 'embercom/helpers/stats-system/stats-parameters';
import { inject as service } from '@ember/service';

export default Controller.extend({
  helpCenterService: service(),
  intl: service(),

  conversations: true,
  reaction: null,
  range: null,
  localizedContentId: null,
  helpCenterId: '',

  showController: controller('apps/app/articles/articles/article/show'),
  app: reads('showController.app'),
  appsController: controller('apps'),
  site: readOnly('helpCenterService.site'),
  admin: reads('appsController.model'),
  stat: reads('showController.displaying'),
  isReactedStat: computed('stat', function () {
    return ['reacted', 'reaction_0', 'reaction_1', 'reaction_2'].some(
      (param) => param === this.stat,
    );
  }),
  isViewsStat: equal('stat', 'views'),
  statsParameters: computed(
    'range.{start,end}',
    'localizedContentId',
    'helpCenterId',
    'showController.article',
    function () {
      return makeStatsParametersForArticle(
        this.showController.article,
        this.range,
        this.app.timezone,
        this.localizedContentId,
        this.helpCenterId,
      );
    },
  ),
  reactionMap: {
    reaction_0: '\uD83D\uDE03',
    reaction_1: '\uD83D\uDE10',
    reaction_2: '\uD83D\uDE1E',
  },
  reactionFilter: computed('reaction', function () {
    return this.reactionMap[this.reaction];
  }),

  changeReactionFilter: action(function (_, value) {
    if (value === '') {
      this.transitionToRoute('apps.app.articles.articles.article.show.stats', {
        queryParams: { displaying: 'reacted', reaction: null },
      });
    } else {
      let reaction = Object.keys(this.reactionMap).find((stat) => this.reactionMap[stat] === value);
      this.transitionToRoute('apps.app.articles.articles.article.show.stats', {
        queryParams: { displaying: 'reacted', reaction },
      });
    }
  }),
  onUpdateDateRange: action(function (range) {
    set(this, 'range', range);
  }),

  filterByLocalizedContentId: action(function (localizedContentId) {
    set(this, 'localizedContentId', localizedContentId);
  }),

  filterByHelpCenterId: action(function (helpCenterId) {
    set(this, 'helpCenterId', helpCenterId);
  }),

  localeOptions: computed('intl.locale', 'showController.article.articleContents', function () {
    let noFilter = [
      {
        value: '',
        text: this.intl.t('articles.show.stats.locale-filter.all'),
      },
    ];
    let localeFilter = this.showController.article.articleContents.map((articleContent) => {
      return {
        value: articleContent.id,
        text: this.getLocaleNameFromLocaleCode(articleContent.locale),
      };
    });
    return [...noFilter, ...localeFilter];
  }),

  helpCenterOptions: computed('intl.locale', 'helpCenterService.allSites', function () {
    let options = [
      {
        value: '',
        text: this.intl.t('articles.show.stats.help-center-filter.all'),
      },
    ];
    return options.concat(
      this.helpCenterService.allSites.map((site) => {
        return {
          value: site.id,
          text: site.name,
        };
      }),
    );
  }),

  // "All Help Centers" option + 1 help center
  showHelpCenterFilter: gt('helpCenterOptions.length', 2),

  getLocaleNameFromLocaleCode(locale) {
    return this.helpCenterService.getLocaleNameFromLocaleCode(locale);
  },
});
