/* RESPONSIBLE TEAM: team-tickets-1 */

import OldTeamsController from '../old-teams';
import { inject as service } from '@ember/service';
import { objectTypes, states } from 'embercom/models/data/matching-system/matching-constants';
import { task } from 'ember-concurrency-decorators';
import { type TaskGenerator } from 'ember-concurrency';
import { action } from '@ember/object';
import type Team from 'embercom/models/team';
import { trackedFunction } from 'ember-resources/util/function';

export default class TeamsController extends OldTeamsController {
  @service declare intercomEventService: $TSFixMe;
  @service declare outboundHomeService: $TSFixMe;
  @service declare appService: $TSFixMe;
  @service declare modalService: $TSFixMe;
  titleToken: $TSFixMe;

  get assignableTeams() {
    return this.appService.app.assignableTeams;
  }

  get _model() {
    return this.model as $TSFixMe;
  }

  get schedules() {
    return this._model.schedules;
  }

  get teamSettings() {
    return this._model.teamSettings;
  }

  @action
  deleteSelectedTeam(model: $TSFixMe) {
    this.modalService.openModal('settings/modals/team-delete-modal', model, {
      app: this.appService.app,
    });
  }

  @task({ drop: true })
  *buildTeamTask() {
    yield this._buildTeam();
  }

  @action
  buildTeam() {
    this._buildTeam();
  }

  @action
  setTeammateUpdateStatus(isUpdated: boolean) {
    this.set('isTeammateListUpdated', isUpdated);
  }

  @action
  revertNewSelectedTeam(team: Team) {
    let sortedTeams = this.sortedTeams as $TSFixMe;
    sortedTeams.popObject(team);
  }

  get liveWorkflows() {
    return this.liveWorkflowsLoader.value;
  }

  private liveWorkflowsLoader = trackedFunction(this, async () => {
    try {
      return this.outboundHomeService.contentSearch({
        object_types: [
          objectTypes.customBot,
          objectTypes.inboundCustomBot,
          objectTypes.buttonCustomBot,
          objectTypes.triggerableCustomBot,
        ],
        states: [states.live],
        match_behaviors: [],
        app_id: this.appService.app.id,
        per_page: 1,
      });
    } catch (e) {
      console.error(e);
    }
  });

  @task({ drop: true })
  *getLiveWorkflows(): TaskGenerator<void> {
    try {
      let liveWorkflowsSearch: $TSFixMe = yield this.outboundHomeService.contentSearch({
        object_types: [
          objectTypes.customBot,
          objectTypes.inboundCustomBot,
          objectTypes.buttonCustomBot,
          objectTypes.triggerableCustomBot,
        ],
        states: [states.live],
        match_behaviors: [],
        app_id: this.appService.app.id,
        per_page: 1,
      });
      this.set('liveWorkflows', liveWorkflowsSearch);
    } catch (e) {
      console.error(e);
    }
  }

  get hasNoLiveWorkflows() {
    return this.liveWorkflows?.totalCount < 1;
  }

  get hasCreatedTeamsOtherThanExamples() {
    let nonExampleTeams = this.assignableTeams.filter(
      (team: $TSFixMe) => !team.name.includes('[Example]'),
    );

    // eslint-disable-next-line @intercom/intercom/no-bare-strings
    let preCannedTeams = ['Support', 'Technical'];
    return (
      nonExampleTeams.filter((team: $TSFixMe) => !preCannedTeams.includes(team.name))?.length > 0
    );
  }

  showTeamsArticle = () => {
    window.Intercom('showArticle', 197); // https://www.intercom.com/help/en/articles/197-organize-your-inbox-by-team
    this.triggerAnalytics('how_to_setup_and_use_teams_dropdown_item');
  };

  showRouteConversationsArticle = () => {
    window.Intercom('showArticle', 6560715); // https://www.intercom.com/help/en/articles/6560715-automatically-route-conversations-to-teammates
    this.triggerAnalytics('how_to_route_conversations_discovery_banner');
  };

  showAssignmentArticle = () => {
    window.Intercom('showArticle', 6553774); // https://www.intercom.com/help/en/articles/6553774-balanced-assignment-deep-dive
    this.triggerAnalytics('balance_workload_article_dropdown_item');
  };

  showAssignArticle = () => {
    window.Intercom('showArticle', 6561699); // https://www.intercom.com/help/en/articles/6561699-assign-conversations-to-teammates-and-teams
    this.triggerAnalytics('assign_conversations_article_dropdown_item');
  };

  showPermissionsArticle = () => {
    window.Intercom('showArticle', 3659500); // https://www.intercom.com/help/en/articles/3659500-manage-permissions-effortlessly-with-custom-roles
    this.triggerAnalytics('manage_permissions_article_dropdown_item');
  };

  triggerAnalytics(object: $TSFixMe, section = 'dropdown_item') {
    this.intercomEventService.trackAnalyticsEvent({
      action: 'clicked',
      object,
      place: 'app.settings.teams',
      context: 'empty_state.discovery_banner.create_teams',
      section,
    });
  }
}
