/* RESPONSIBLE TEAM: team-proactive-support */
import ReportBase from 'embercom/controllers/apps/app/reports/base/report';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { objectNames, objectTypes } from 'embercom/models/data/matching-system/matching-constants';
import { stats, failureListCategories } from 'embercom/models/data/stats-system/stats-constants';

const BEST_PRACTICES_ARTICLE_ID = '235';

export default class EmailDeliverabilityReport extends ReportBase {
  @service reportingService;
  @service intercomEventService;

  @action
  updateRange(range) {
    this.reportingService.updateRange(range);

    let params = {
      action: 'filtered_by_date',
      object: this.reportName,
      date_filter: range.selectedRange,
      start_date: range.startMoment.format('YYYY/MM/DD'),
      end_date: range.endMoment.format('YYYY/MM/DD'),
    };
    this.intercomEventService.trackAnalyticsEvent(params);
  }

  @action
  showArticle() {
    window.Intercom('showArticle', BEST_PRACTICES_ARTICLE_ID);
  }

  get emailsSentQuery() {
    return {
      name: 'default_query',
      debug_name: 'outbound_emails_opened',
      document_type: 'events',
      range_field: 'stat_receipt.created_at',
      aggregation_type: 'value_count',
      aggregation_field: 'event.type',
    };
  }

  get emailsSentFilters() {
    return {
      'event.type': 'stats_receipt',
      'stat_receipt.content_type': objectNames[objectTypes.email],
    };
  }

  get stats() {
    return stats;
  }

  get failureListCategories() {
    return failureListCategories;
  }
}
