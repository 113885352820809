/* RESPONSIBLE TEAM: team-proactive-support */
import Controller from '@ember/controller';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { PLAN_DATA, SURVEYS_BASE_ID } from 'embercom/lib/billing';

export default class AppsAppOutboundSurveyController extends Controller {
  queryParams = ['onboardingVideoDisplayed'];

  @tracked onboardingVideoDisplayed = false;

  get wistiaId() {
    return PLAN_DATA[SURVEYS_BASE_ID].trialOnboardingWistiaId;
  }

  @action
  onModalClose() {
    this.onboardingVideoDisplayed = false;
  }
}
