/* RESPONSIBLE TEAM: team-messenger */
import Controller from '@ember/controller';

export default class General extends Controller {
  queryParams = [];

  declare model: {
    settings: any;
    identity: any;
  };
}
