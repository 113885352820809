/* RESPONSIBLE TEAM: team-knowledge-and-data-setup */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable ember/require-computed-property-dependencies */
/* eslint-disable ember/no-controllers */
/* eslint-disable ember/no-classic-classes */
import { inject as service } from '@ember/service';
import { computed } from '@ember/object';
import { readOnly } from '@ember/object/computed';
import Controller, { inject as controller } from '@ember/controller';

export default Controller.extend({
  store: service(),
  appController: controller('apps/app'),
  app: readOnly('appController.model'),

  collections: computed(function () {
    return this.store.peekRecord('articles/article-group', 'home').get('children');
  }),
  private: computed(function () {
    return [this.home];
  }),
  home: computed(function () {
    return this.store.peekRecord('articles/article-group', 'home');
  }),
});
