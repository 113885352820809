/* RESPONSIBLE TEAM: team-proactive-support */
import Controller, { type QueryParamConfig } from '@ember/controller';
import { tracked } from '@glimmer/tracking';

export default class CustomizationController extends Controller {
  queryParams: (string | Record<string, string | QueryParamConfig | undefined>)[] = [];
  declare model: { clickTrackingDomainOptOut: any; messagingSettings: any };

  @tracked expandDomainEditor = false;
}
