/* RESPONSIBLE TEAM: team-frontend-tech */
import Controller from '@ember/controller';
import { inject as service } from '@ember/service';
import { task } from 'ember-concurrency-decorators';
import { timeout } from 'ember-concurrency';

export default class AppsAppVideoController extends Controller {
  @service intercomEventService;

  queryParams = ['v'];

  get videoKey() {
    return this.v;
  }

  init() {
    super.init(...arguments);
    this.trackVideoEvent.perform();
  }

  @task
  *trackVideoEvent() {
    yield timeout(2000);

    let eventData = {
      videoKey: this.videoKey,
    };

    this.intercomEventService.trackEvent('videogen_video_started', eventData);
  }
}
