/* RESPONSIBLE TEAM: team-tickets-1 */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
/* eslint-disable ember/no-actions-hash */
/* eslint-disable promise/prefer-await-to-then */
/* eslint-disable ember/require-computed-property-dependencies */
/* eslint-disable ember/no-classic-classes */
import Controller, { inject as controller } from '@ember/controller';
import { inject as service } from '@ember/service';
import { computed } from '@ember/object';
import { and, equal, not, or, readOnly, alias } from '@ember/object/computed';
import { equalToProperty, findBy } from '@intercom/pulse/lib/computed-properties';
import { assetUrl } from '@intercom/pulse/helpers/asset-url';

export default Controller.extend({
  intercomEventService: service(),
  notificationsService: service(),
  appService: service(),
  inboxService: service(),
  bootOptionsService: service(),
  router: service(),
  onboardingHomeExternalStepService: service(),
  permissionsService: service(),
  audioNotificationService: service(),

  queryParams: ['simulator', 'product_tour_id'],
  simulator: 'false',
  product_tour_id: null,
  paywallImage: assetUrl('/assets/images/inbox/paywall.png'),

  forcePaywallInactive: and('app.doesNotRequiresInboxSeatAccess', 'app.inboxIsActive'),

  appController: controller('apps/app'),
  appsController: controller('apps'),

  app: readOnly('appService.app'),
  me: readOnly('app.currentAdmin'),
  currentPath: readOnly('router.currentRouteName'),

  canUseFree: readOnly('app.canUseFree'),

  iframeSource: computed('app', function () {
    return `//${window.location.host}/client/test/${this.get('app.id')}`;
  }),
  isSimulator: equal('simulator', 'true'),
  showNavBar: computed('bootOptionsService.embeddedContent', 'isSimulator', function () {
    return !this.get('bootOptionsService.embeddedContent') && !this.isSimulator;
  }),

  product: readOnly('app.inboxProduct'),

  isViewingSingleConversation: equalToProperty('currentPath', 'app.inboxConversationRoute'),
  isViewingInbox: computed('currentPath', 'app.inboxBaseRoute', function () {
    return this.currentPath.includes(this.get('app.inboxBaseRoute'));
  }),

  appDoesNotRequireInboxSeatAccess: not('app.requiresInboxSeatAccess'),
  shouldNotShowPricingAccessBanner: or(
    'app.currentAdminHasInboxAccess',
    'appDoesNotRequireInboxSeatAccess',
  ),
  shouldShowPricingAccessBanner: not('shouldNotShowPricingAccessBanner'),
  requiresSeat: not('app.currentAdminHasInboxAccess'),
  audioNotificationsBlocked: readOnly('audioNotificationService.audioNotificationsBlocked'),
  hideAudioNotificationsBlockedBanner: alias(
    'audioNotificationService.hideAudioNotificationsBlockedBanner',
  ),

  activeInbox: computed(
    'isViewingInbox',
    'isViewingSingleConversation',
    'singleConversationAssignee',
    'inboxService.inbox',
    function () {
      if (this.isViewingInbox) {
        return this.isViewingSingleConversation
          ? this.singleConversationAssignee
          : this.get('inboxService.inbox');
      }
    },
  ),

  inboxes: readOnly('model.inboxes'),
  singleConversationAssignee: findBy('inboxes', 'identifier', 'all'),

  inboxViews: readOnly('inboxService.inboxViews'),
  viewBeingCreated: false,
  viewBeingUpdated: null,
  viewBeingDeleted: null,

  actions: {
    inboxesUpdated(inboxes) {
      if (this.navListComponent) {
        this.navListComponent.send('inboxesUpdated', inboxes);
      }
    },
    turnOffAwayMode() {
      let admin = this.me;
      admin.setProperties({
        away_mode_enabled: false,
        reassign_conversations: false,
      });
      admin
        .saveOutOfOfficeSettings()
        .then(() => {
          this.notificationsService.notifyConfirmation(
            'You’re active again! We’ve stopped reassigning your active conversations',
          );
          this.intercomEventService.trackAnalyticsEvent({
            action: 'disabled',
            object: 'reassign_conversation_setting',
            context: 'from_inbox',
          });
        })
        .catch(() => {
          admin.set('reassign_conversations', true);
          this.notificationsService.notifyError('Oops, something went wrong. Please try again');
        });
    },
    createView() {
      this.permissionsService.checkPermission('can_manage_inbox_views').then(() => {
        this.set('viewBeingCreated', true);
      });
    },
    viewCreationFinished({ success, inboxView }) {
      this.set('viewBeingCreated', false);
      if (success && inboxView) {
        this.transitionToRoute(this.app.inboxConversationsRoute, `view:${inboxView.id}`);
      }
    },
    updateView(viewId) {
      this.permissionsService.checkPermission('can_manage_inbox_views').then(() => {
        let id = viewId.substring(5);
        let view = this.inboxViews.findBy('id', id);
        this.set('viewBeingUpdated', view);
      });
    },
    viewUpdateFinished() {
      this.set('viewBeingUpdated', null);
    },
    deleteView(view) {
      this.set('viewBeingUpdated', null);
      this.set('viewBeingDeleted', view);
    },
    viewDeletionFinished({ success }) {
      let view = this.viewBeingDeleted;
      this.set('viewBeingDeleted', null);

      if (success && this.inboxService.inbox.id === `view:${view.id}`) {
        this.transitionToRoute(this.app.inboxRoute);
      } else if (!success) {
        this.set('viewBeingUpdated', view);
      }
    },
  },
});
