/* RESPONSIBLE TEAM: team-customer-data-platform */
import Controller from '@ember/controller';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { task } from 'ember-concurrency-decorators';
import type IntlService from 'ember-intl/services/intl';
import EmberObject from '@ember/object';
import { taskFor } from 'ember-concurrency-ts';

export default class PersonalDetailsController extends Controller {
  queryParams = [];
  @service declare intl: IntlService;
  @service declare appService: $TSFixMe;
  @service declare conversationFeedService: $TSFixMe;

  @tracked isPaginating = false;
  @tracked hasMoreConversationsToLoad = true;
  @tracked pendingUpdateConversationIds = [];
  @tracked conversations: $TSFixMe = this.model;
  @tracked currentlyLoading = true;
  @tracked hasErrored = false;

  get app() {
    return this.appService.app;
  }

  get admin() {
    return this.app.currentAdmin;
  }

  get emptyState() {
    return this.intl.t('admins.admin.index.no-conversations');
  }

  get conversationHeaderText() {
    return this.admin.is_me
      ? this.intl.t('admins.admin.index.your-conversations')
      : this.intl.t('admins.admin.index.their-conversations');
  }

  get emptyStateText() {
    return this.admin.is_me
      ? this.intl.t('admins.admin.index.no-conversations-assigned-to-you')
      : this.intl.t('admins.admin.index.no-conversations-assigned-to-name', {
          firstName: this.admin.first_name,
        });
  }

  _searchOptions() {
    let appId = this.app.id;
    let adminId = this.admin.id;
    let pageFrom = null;
    let currentConversations = null;

    return EmberObject.create({ appId, adminId, pageFrom, currentConversations });
  }

  @task({ drop: true })
  *fetchPage(
    searchOptions: ReturnType<typeof this._searchOptions>,
  ): Generator<Promise<any>, void, any> {
    try {
      let result = yield this.conversationFeedService.paginate(searchOptions);
      this.conversations = result.conversations;
      this.hasMoreConversationsToLoad = result.hasMoreConversationsToLoad;
    } catch (e) {
      this.hasErrored = true;
    } finally {
      this.hasErrored = false;
      this.isPaginating = false;
      this.currentlyLoading = false;
    }
  }

  @action
  paginate() {
    if (this.hasMoreConversationsToLoad) {
      this.isPaginating = true;
      let searchOptions = this._searchOptions();
      searchOptions.set('pageFrom', this.conversations.lastObject.updated_at);
      searchOptions.set('currentConversations', this.conversations);

      taskFor(this.fetchPage).perform(searchOptions);
    }
  }
}
