/* RESPONSIBLE TEAM: team-app-security */

import Controller from '@ember/controller';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import type IntlService from 'ember-intl/services/intl';
import { request } from 'embercom/lib/inbox/requests';
import { action } from '@ember/object';

export const AUTH_OPTIONS = {
  all: 'all_auth',
  google: 'google_auth',
  saml: 'saml_auth',
};

export const SAML_AUTH_SUB_OPTIONS = {
  none: 'none',
  all: 'all_auth',
  googleAnd2fa: 'google_and_2fa',
  google: 'google_auth',
};

export const SAML_PROVISIONING_METHODS = {
  jit: 'jit',
  scim: 'scim',
  none: 'none',
};

export default class SecurityController extends Controller {
  queryParams = ['tab', 'section'];

  @service declare appService: $TSFixMe;
  @service declare notificationsService: $TSFixMe;
  @service declare intl: IntlService;

  @tracked attachmentSettings: $TSFixMe;
  @tracked userSources: $TSFixMe;
  @tracked sdkApps: $TSFixMe;
  @tracked iosSecret: $TSFixMe;
  @tracked androidSecret: $TSFixMe;
  @tracked pingDomains: $TSFixMe;
  @tracked reminder: $TSFixMe;
  @tracked idvSettings: $TSFixMe;

  @tracked useLinkWarnings: $TSFixMe;
  @tracked localTrustedDomainsList: $TSFixMe;
  @tracked savedTrustedDomainsList: $TSFixMe;
  @tracked trustedDomainSettings: $TSFixMe;
  @tracked initialProvisioningMethod = '';
  @tracked selectedProvisioningMethod = '';
  @tracked tab = 'general';
  @tracked section = '';

  declare model: {
    userSource: $TSFixMe;
    sdkApps: $TSFixMe;
    attachmentSettings: $TSFixMe;
    ios: $TSFixMe;
    android: $TSFixMe;
    pingDomains: $TSFixMe;
    settings: $TSFixMe;
    idvSettings: $TSFixMe;
    reminder: $TSFixMe;
  };

  get selectedTab() {
    return this.tab;
  }

  @action
  changeTab(tab: string, section = '') {
    this.set('tab', tab);
    this.tab = tab;
    this.set('section', section);
    this.section = section;
  }

  get apiSecrets() {
    return {
      ios: this.iosSecret,
      android: this.androidSecret,
    };
  }

  get settings() {
    return this.model.settings;
  }

  get samlAccount() {
    return this.settings.samlAccount;
  }

  get scimSettings() {
    return this.settings.scimSettings;
  }

  get isScimSettingsDirty() {
    return this.scimEnabled && this.scimSettings.hasDirtyAttributes;
  }

  get scimEnabled() {
    return this.selectedProvisioningMethod === SAML_PROVISIONING_METHODS.scim;
  }

  get hasChangedProvisioningMethod() {
    return this.initialProvisioningMethod !== this.selectedProvisioningMethod;
  }

  get isDirty() {
    return (
      this.settings.hasDirtyAttributes ||
      this.samlAccount?.hasDirtyAttributes ||
      this.isScimSettingsDirty ||
      this.hasChangedProvisioningMethod
    );
  }

  async loadURLVerificationSettings() {
    try {
      let response = await request(
        `/ember/trusted_domains_settings/show?app_id=${this.appService.app.id}`,
      );
      response = await response.json();

      this.useLinkWarnings = response.enabled;
      this.localTrustedDomainsList = response.trusted_domains;
      this.savedTrustedDomainsList = response.trusted_domains;

      this.updateTrustedDomainSettings(response);
    } catch (error) {
      this.notificationsService.notifyResponseError(error, {
        default: this.intl.t('apps.app.settings.attachment-settings.load-failed'),
      });
    }
  }

  updateTrustedDomainSettings(response: $TSFixMe) {
    if (!response.updated_by_admin_id) {
      this.trustedDomainSettings = {
        enabled: response.enabled,
        updatedAt: response?.updated_at ? response.updated_at : '',
        adminName: '',
      };
      return;
    }
    let id = `${response.updated_by_admin_id}`;
    let admin = this.appService.app.admins.findBy('id', id);
    this.trustedDomainSettings = {
      enabled: response.enabled,
      updatedAt: response?.updated_at ? response.updated_at : '',
      adminName: admin?.name ? admin.name : '',
    };
  }

  _initializeCurrentProvisioningMethod() {
    if (this.samlAccount?.jitEnabled) {
      return SAML_PROVISIONING_METHODS.jit;
    } else if (this.scimSettings) {
      return SAML_PROVISIONING_METHODS.scim;
    } else {
      return SAML_PROVISIONING_METHODS.none;
    }
  }
}
