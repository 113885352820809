/* RESPONSIBLE TEAM: team-knowledge-interop */
import Controller from '@ember/controller';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { type EntityType } from 'embercom/models/data/entity-types';
import { AI_CONTENT_ENTITIES_NAMES } from 'embercom/lib/ai-content-library/constants';
import {
  objectNames,
  objectNamesToKeys,
} from 'embercom/models/data/matching-system/matching-constants';
import { inject as service } from '@ember/service';
import type ContentImportService from 'embercom/services/content-import-service';

export default class FinContentController extends Controller {
  @service declare appService: any;
  @service declare contentImportService: ContentImportService;

  selectedContentQueryParams = Object.values(AI_CONTENT_ENTITIES_NAMES);
  queryParams = [
    'searchTerm',
    'selectedHelpCenterIds',
    'selectedObjectTypes',
    'state',
    'locale',
    'source',
    { selectedContentType: 'content' },
    { selectedContentId: 'id' },
    'createNewSnippet',
  ];

  @tracked declare searchTerm?: string;
  @tracked declare selectedObjectTypes?: string;
  @tracked declare selectedHelpCenterIds?: string;
  @tracked declare state: string | null;
  @tracked declare locale: string | null;
  @tracked declare source?: string;
  @tracked declare predicates?: Array<any>;
  @tracked declare selectedContentType?: string;
  @tracked declare selectedContentId?: number;
  @tracked declare createNewSnippet?: boolean;
  @tracked declare sortBy: string | null;
  @tracked declare sortDirection: string | null;

  get app() {
    return this.appService.app;
  }

  get convertedSelectedObjectTypes(): number[] | undefined {
    return this.selectedObjectTypes?.split(',').map((type) => Number(type));
  }

  get convertedSelectedHelpCenterIds(): string[] | undefined {
    return this.selectedHelpCenterIds?.split(',').map((id) => String(id));
  }

  @action
  resetFilters(): void {
    this.searchTerm = undefined;
    this.selectedObjectTypes = undefined;
    this.state = null;
    this.locale = null;
    this.source = undefined;
    this.selectedHelpCenterIds = undefined;
    this.predicates = undefined;
    this.sortBy = null;
    this.sortDirection = null;
  }

  @action
  setSearchTerm(searchTerm?: string): void {
    this.searchTerm = searchTerm;
  }

  @action
  setSelectedObjectTypes(selectedObjectTypes?: Array<EntityType>): void {
    this.selectedObjectTypes =
      selectedObjectTypes && selectedObjectTypes?.length > 0
        ? selectedObjectTypes?.join(',')
        : undefined;
  }

  @action
  setSelectedHelpCenterIds(selectedHelpCenterIds?: Array<string>): void {
    this.selectedHelpCenterIds =
      selectedHelpCenterIds && selectedHelpCenterIds?.length > 0
        ? selectedHelpCenterIds?.join(',')
        : undefined;
  }

  @action
  setState(state: string | null): void {
    this.state = state;
  }

  @action
  setLocale(locale: string | null): void {
    this.locale = locale;
  }

  @action
  setSelectedSource(source?: string): void {
    this.source = source;
  }

  @action
  setPredicates(predicates?: Array<any>): void {
    this.predicates = predicates;
  }

  @action
  setSelectedContent(content: { contentType: EntityType; contentId: number }) {
    this.selectedContentType = objectNames[content.contentType];
    this.selectedContentId = content.contentId;
  }

  @action
  setSortBy(sortBy: string | null): void {
    this.sortBy = sortBy;
  }

  @action
  setSortDirection(sortDirection: string | null): void {
    this.sortDirection = sortDirection;
  }

  get selectedContent(): { contentType?: EntityType; contentId?: number } | null {
    if (!this.selectedContentType || !this.selectedContentId) {
      return null;
    }
    return {
      contentType: Number(objectNamesToKeys[this.selectedContentType]),
      contentId: this.selectedContentId,
    };
  }
}
