/* RESPONSIBLE TEAM: team-product-exploration */

import Controller from '@ember/controller';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
// @ts-ignore
import { isValidEmail } from '@intercom/pulse/lib/computed-properties';
import { getDomainName, getUserName } from 'embercom/lib/email';
import { getAdminProfile } from 'embercom/lib/admins/admin-profiles';
import type IntlService from 'ember-intl/services/intl';
import type CommonPrimaryNavBarService from 'embercom/services/common-primary-nav-bar-service';

export default class PersonalDetailsComponent extends Controller {
  queryParams = [];
  @service declare intl: IntlService;
  @service declare intercomEventService: any;
  @service declare permissionsService: any;
  @service declare store: any;
  @service declare socialAccountService: any;
  @service declare commonPrimaryNavBarService: CommonPrimaryNavBarService;
  @service declare notificationsService: any;
  @service declare appService: any;

  declare model: any;

  get app() {
    return this.appService.app;
  }

  get currentAdmin() {
    return this.app.currentAdmin;
  }

  get accountHeaderText() {
    return this.model.is_me
      ? this.intl.t('admins.admin.you')
      : this.intl.t('admins.admin.teammate');
  }

  get createdAtFromNow() {
    return this.intl.formatRelative(this.model.created_at);
  }

  get stampText() {
    return this.model.is_me
      ? this.intl.t('admins.admin.you')
      : this.intl.t('admins.admin.teammate');
  }

  get lastActive() {
    return this.model.last_active * 1000;
  }

  get email() {
    return this.model.email;
  }

  get emailUserName() {
    return getUserName(this.email);
  }

  get emailDomainForLink() {
    return `http://${this.emailDomainName}`;
  }

  get emailDomainName() {
    return getDomainName(this.email);
  }

  get isNotEmailDomain() {
    let emailDomain = this.emailDomainName;
    return !['gmail.com', 'yahoo.com', 'hotmail.com', 'outlook.com'].includes(emailDomain);
  }

  get isValidEmail() {
    return isValidEmail(this.email);
  }

  get linkToEmailDomain() {
    return this.isValidEmail && this.isNotEmailDomain;
  }

  @action
  async updateAttributes(editedAttributes: any) {
    try {
      this.model.setProperties(editedAttributes);
      let profile = this.model.profile;
      profile.setProperties({
        job_title: editedAttributes.job_title,
        department: editedAttributes.profile_department,
        phone_number: editedAttributes.phone_number,
        biography: editedAttributes.biography,
      });

      await this.model.save();
      let adminProfile = await getAdminProfile(this.store, this.app.id, this.model.id);
      this.model.set('profile', adminProfile);
      this.notifyConfirmation(this.intl.t('admins.admin.changes-saved'), 'profileSaveSuccess');
    } catch (error) {
      this.notifyErrors(error.jqXHR.responseJSON.errors, 'profileSaveErrors');
    }
  }

  @action
  notifyErrors(message: string, key: string) {
    this.notificationsService.notifyWarning(message, 60000, key);
  }

  @action
  notifyConfirmation(message: string, key: string) {
    this.notificationsService.notifyConfirmation(message, 3000, key);
  }

  @action
  async openTeamSettingsPage() {
    let hasPermission = await this.permissionsService.checkPermission(
      'can_access_workspace_settings',
    );
    if (hasPermission) {
      this.model.transitionToRoute('apps.app.settings.teams');
    }
  }

  @action
  handleWarnOnNavigation(shouldWarnOnNavigation: boolean) {
    this.send('handleWarnOnNavigation', shouldWarnOnNavigation);
  }

  @action
  saveProfileInfo(editedAttributes: any) {
    this.updateAttributes(editedAttributes);
  }

  @action
  async oAuthSuccess(provider: string) {
    let socialAccounts = await this.socialAccountService.oAuthSuccess(this.model, provider);
    this.intercomEventService.trackEvent(`teammate-social-profile-added-${provider}`);
    this.model.set('socialAccounts', socialAccounts);
  }

  @action
  oAuthFailure(provider: string) {
    this.socialAccountService.oAuthFailure(provider);
  }

  @action
  async deleteSocialAccount(model: any, provider: string) {
    await this.socialAccountService.deleteSocialAccount(model, this.model, provider);
    // eslint-disable-next-line @intercom/intercom/no-bare-strings
    this.model.set(`${provider}Account`, null);
    this.intercomEventService.trackEvent(`teammate-social-profile-removed-${provider}`);
  }
}
