/* RESPONSIBLE TEAM: team-growth-opportunities */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable ember/no-classic-classes */
import Controller, { inject as controller } from '@ember/controller';
import { action, computed } from '@ember/object';
import { alias, equal, not, readOnly, reads } from '@ember/object/computed';
import { inject as service } from '@ember/service';
import { ternaryToProperty } from '@intercom/pulse/lib/computed-properties';
import ajax from 'embercom/lib/ajax';
import { plansFromSolution, PRICING_5_SOLUTION_IDS } from 'embercom/lib/billing';
import { MARKETO_FORM_IDS } from 'embercom/lib/gtm';
import moment from 'moment-timezone';

export default Controller.extend({
  gtmService: service(),
  purchaseAnalyticsService: service(),
  customerService: service(),
  redirectService: service(),
  appService: service(),
  media: service(),
  earlyStageService: service(),
  intl: service(),
  cardlessTrialService: service(),

  customerHasEverHadSubscription: alias('teamsController.customerHasEverHadSubscription'),
  appId: readOnly('appService.app.id'),
  appController: controller('apps.app'),
  signupController: controller('signup'),
  app: readOnly('appController.app'),
  products: readOnly('app.products'),
  isEarlyStage: alias('teamsController.isEarlyStage'),
  heading: ternaryToProperty('isEarlyStage', 'earlyStageHeading', 'normalHeading'),
  subHeading: ternaryToProperty('isEarlyStage', 'earlyStageSubHeading', 'normalSubHeading'),
  earlyStageDiscount: alias('teamsController.solutionDiscount'),
  isPricing5EarlyStageSolution: alias('teamsController.isPricing5EarlyStageSolution'),
  normalHeading: computed(
    'cardlessTrialService.isInCardlessTrial',
    'intl.locale',
    'mobileDevice',
    'customerHasEverHadSubscription',
    function () {
      if (this.mobileDevice) {
        return this.intl.t('teams-checkout.confirm.add_billing_info');
      }
      if (this.cardlessTrialService.isInCardlessTrial || this.customerHasEverHadSubscription) {
        return this.intl.t('teams-checkout.confirm.start_subscription');
      }
      return this.intl.t('teams-checkout.confirm.start_free_trial');
    },
  ),
  normalSubHeading: computed(
    'cardlessTrialService.isInCardlessTrial',
    'endOfTrialDate',
    'intl.locale',
    'customerHasEverHadSubscription',
    function () {
      if (this.cardlessTrialService.isInCardlessTrial || this.customerHasEverHadSubscription) {
        return [this.intl.t('teams-checkout.confirm.will_be_billed_today')];
      }
      return [
        this.intl.t('teams-checkout.confirm.wont_be_billed_today'),
        this.intl.t('teams-checkout.confirm.cancel_anytime_before', {
          endOfTrialDate: this.intl.formatDate(this.endOfTrialDate, {
            day: 'numeric',
            month: 'long',
            year: 'numeric',
          }),
        }),
      ];
    },
  ),
  showTermsAndViewWorkspaceForSubscriptionCheckout: reads('customerHasEverHadSubscription'),
  earlyStageHeading: computed(
    'app.earlyStageApplicationAccepted',
    'cardlessTrialService.canUseEarlyStageDetection',
    'intl.locale',
    function () {
      if (this.cardlessTrialService.canUseEarlyStageDetection) {
        return this.intl.t(
          'teams-checkout.early_stage.application_accepted.heading_early_stage_detection',
          {
            htmlSafe: true,
          },
        );
      }
      if (this.app.earlyStageApplicationAccepted) {
        return this.intl.t('teams-checkout.early_stage.application_accepted.heading', {
          htmlSafe: true,
        });
      }
      return this.intl.t('teams-checkout.early_stage.application_pending.heading', {
        htmlSafe: true,
      });
    },
  ),
  earlyStageSubHeading: computed(
    'app.earlyStageApplicationAccepted',
    'cardlessTrialService.canUseEarlyStageDetection',
    'earlyStageDiscount',
    'intl.locale',
    function () {
      if (this.cardlessTrialService.canUseEarlyStageDetection) {
        return this.intl.t(
          'teams-checkout.early_stage.application_accepted.subheading_early_stage_detection',
          {
            discount: this.earlyStageDiscount,
            htmlSafe: true,
          },
        );
      }
      if (this.app.earlyStageApplicationAccepted) {
        return [
          this.intl.t('teams-checkout.early_stage.application_accepted.subheading_approval', {
            discount: this.earlyStageDiscount,
          }),
        ];
      }
      return this.intl.t('teams-checkout.early_stage.application_pending.subheading_review');
    },
  ),
  annualBillingPeriodSubHeading: computed(
    'app.earlyStageApplicationAccepted',
    'intl.locale',
    'customerHasEverHadSubscription',
    function () {
      if (this.customerHasEverHadSubscription) {
        return this.intl.t(
          'teams-checkout.confirm.privacy_policy_and_terms_annually_for_subscription_checkout',
          {
            htmlSafe: true,
          },
        );
      }
      return this.intl.t(
        'teams-checkout.confirm.privacy_policy_and_terms_annually_for_trial_checkout',
        {
          htmlSafe: true,
        },
      );
    },
  ),
  monthlyBillingPeriodSubHeading: computed(
    'app.{canUseEarlyStageDetection,earlyStageApplicationAccepted}',
    'cardlessTrialService.canUseEarlyStageDetection',
    'customerHasEverHadSubscription',
    'intl.locale',
    'isPricing5EarlyStageSolution',
    'shouldShowPricing5Checkout',
    function () {
      if (this.cardlessTrialService.canUseEarlyStageDetection) {
        return this.intl.t(
          'teams-checkout.confirm.privacy_policy_and_terms_monthly_for_trial_checkout_pay_now',
          {
            htmlSafe: true,
          },
        );
      }
      if (this.isPricing5EarlyStageSolution) {
        return this.intl.t(
          'teams-checkout.confirm.privacy_policy_and_terms_monthly_for_subscription_checkout_pricing5_early_stage',
          {
            htmlSafe: true,
          },
        );
      }
      if (this.shouldShowPricing5Checkout) {
        return this.intl.t(
          'teams-checkout.confirm.privacy_policy_and_terms_monthly_for_subscription_checkout_pricing5',
          {
            htmlSafe: true,
          },
        );
      }
      if (this.customerHasEverHadSubscription) {
        return this.intl.t(
          'teams-checkout.confirm.privacy_policy_and_terms_monthly_for_subscription_checkout',
          {
            htmlSafe: true,
          },
        );
      }
      return this.intl.t(
        'teams-checkout.confirm.privacy_policy_and_terms_monthly_for_trial_checkout_start_intercom',
        {
          htmlSafe: true,
        },
      );
    },
  ),

  showCreditCardSubHeader: computed('isEarlyStage', 'shouldShowPricing5Checkout', function () {
    return !(this.isEarlyStage || this.shouldShowPricing5Checkout);
  }),
  context: 'usecase_signup_flow',
  place: 'start_trial',
  object: 'start_trial',

  stripev3: service(),
  token: null,
  buttonsDisabled: not('token'),
  countryCode: null,
  countryIsUnitedStates: equal('countryCode', 'US'),
  endOfTrialDate: computed('app.trialLengthInDays', function () {
    let trialLength = this.get('app.trialLengthInDays');
    return moment().add(trialLength, 'days').format('MMMM DD, YYYY');
  }),
  stateCode: null,
  queryParams: {
    premium: 'premium',
  },
  shouldShowPricing5Checkout: computed('solutionId', function () {
    return PRICING_5_SOLUTION_IDS.includes(this.solutionId);
  }),
  planIds: computed('solutionId', 'app', function () {
    return plansFromSolution(this.solutionId, this.app).map((x) => parseInt(x, 10));
  }),
  premium: false,
  cardOptions: {
    hidePostalCode: true,
  },
  teamsController: controller('apps/app/teams-checkout'),
  addOnPlanIds: alias('teamsController.addOnPlanIds'),
  addOn: alias('teamsController.addOn'),
  solutionId: alias('teamsController.solutionId'),
  gclid: alias('teamsController.gclid'),
  mobileCart: alias('teamsController.mobileCart'),
  mobileDevice: readOnly('media.isMobile'),
  notMobileDevice: not('mobileDevice'),
  emailSubmissionId: alias('teamsController.emailSubmissionId'),
  solutionPrice: alias('teamsController.solutionPrice'),
  purchaseExperience: readOnly('signupController.purchaseExperience'),
  isMessengerInstalled: readOnly('customerService.isMessengerInstalled'),
  couponCode: reads('teamsController.couponCode'),
  price: alias('teamsController.price'),
  billingPeriodDurationInMonths: alias('teamsController.billingPeriodDurationInMonths'),
  shouldOfferAnnualBillingPeriods: alias('teamsController.shouldOfferAnnualBillingPeriods'),

  fireMarketoEvent() {
    this.gtmService.fireMarketoEvent(MARKETO_FORM_IDS.escapeHatch, {
      App_ID__c: this.appId,
      Email: this.get('appService.app.currentAdmin.email'),
      Company: this.get('appService.app.name'),
    });
  },

  sendServerEvent() {
    ajax({
      url: `/ember/customers/${this.appId}/update_subscription`,
      type: 'post',
      contentType: 'application/json',
      data: JSON.stringify({ platform: true }),
    });
  },

  isInCardlessTrial: alias('cardlessTrialService.isInCardlessTrial'),

  escapeHatch: action(function () {
    this.purchaseAnalyticsService.trackEvent({
      action: 'clicked',
      object: 'escape_hatch',
      context: 'usecase_signup_flow',
      place: this.place,
      solutionId: this.solutionId,
      planIds: this.addOnPlanIds,
      cartAmount: this.totalAmount,
      gclid: this.gclid,
      emailSubmissionId: this.emailSubmissionId,
    });
    this.fireMarketoEvent();

    this.sendServerEvent();
    this.teamsController.redirectToOnboardingHome();
  }),

  redirectAfterCheckout: action(async function () {
    this.teamsController.set('convertCardlessTrialSubscription', '');
    await this.customerService.refreshCustomer();
    await this.cardlessTrialService.loadCustomer(true);

    if (this.mobileDevice) {
      return this.transitionToRoute('apps.app.mobile-welcome');
    }

    let path = this.redirectService.getRedirectPath();
    if (path) {
      this.transitionToRoute(path);
    } else {
      this.teamsController.redirectToOnboardingHome();
    }
  }),
});
