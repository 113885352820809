/* RESPONSIBLE TEAM: team-proactive-support */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable ember/no-controllers */
import PerformanceReportBase from 'embercom/controllers/apps/app/reports/base/performance-report';
import { CONVERSATION_RATINGS } from 'embercom/lib/reporting/queries';
import { action } from '@ember/object';
import Admin from 'embercom/models/admin';
import { inject as service } from '@ember/service';

export default PerformanceReportBase.extend({
  store: service(),
  intercomEventService: service(),
  queries: {
    conversationRatings: CONVERSATION_RATINGS,
  },

  get customAttributeIds() {
    return this.conversationAttributeDescriptors.map((descriptor) => descriptor.id);
  },

  changeTeamOrTeammate: action(function (teamOrTeammateId) {
    let isTeam =
      teamOrTeammateId && Admin.peekAndMaybeLoad(this.store, teamOrTeammateId)?.get('isTeam');

    if (isTeam) {
      this.setProperties({
        teammateId: [],
        teamsParticipated: teamOrTeammateId,
      });
    } else {
      this.setProperties({
        teammateId: teamOrTeammateId,
        teamsParticipated: [],
      });
    }
    this.intercomEventService.trackAnalyticsEvent({
      action: 'filtered_by_teammate',
      object: this.reportName,
    });
  }),

  filterBarSelectionChanged: action(function (filterType, filterValues, operator) {
    //backwards compatible with existing teammate filters before the filter bar was introduced
    if (filterType === 'teammatesParticipated') {
      this.set('teammateId', filterValues);
    } else if (this.customAttributeIds.includes(filterType)) {
      this.customAttributes.updateValues(filterType, filterValues, operator);
      this.set('customAttributes', this.customAttributes.clone());
    } else {
      this.set(filterType, filterValues);
    }
  }),
});
