/* RESPONSIBLE TEAM: team-data-interop */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
/* eslint-disable ember/no-actions-hash */
/* eslint-disable ember/no-controllers */
/* eslint-disable ember/no-classic-classes */
import Controller from '@ember/controller';
import { inject as service } from '@ember/service';
import { alias, not, notEmpty, readOnly } from '@ember/object/computed';
import { task } from 'ember-concurrency';

export default Controller.extend({
  intercomEventService: service(),
  notificationsService: service(),
  intercomConfirmService: service(),
  developerHub: service(),
  appService: service(),
  app: readOnly('appService.app'),
  isOnEdit: alias('developerHub.isOnEditPage'),
  appPackage: readOnly('developerHub.appPackage'),
  hasAppStoreListing: notEmpty('appPackage.appStoreListing'),
  hasError: not('appPackage.changeRequest.appPackageConfig.isNameComplete'),

  isAppPackageConfigInvalid: task(function* () {
    let { validations } = yield this.get('appPackage.changeRequest.appPackageConfig').validate();
    return validations.get('isInvalid');
  }),

  saveAppPackageTask: task(function* () {
    try {
      let isInvalid = yield this.isAppPackageConfigInvalid.perform();
      if (isInvalid) {
        return;
      }

      yield this.appPackage.save();
      this.intercomEventService.trackAnalyticsEvent({
        object: 'app_package',
        app_package_id: this.get('appPackage.id'),
        app_metadata: this.get('appPackage.changeRequest'),
        action: 'saved',
      });
      this.transitionToRoute('apps.app.developer-hub.app-packages.app-package.basic-info');
    } catch (e) {
      this.notificationsService.notifyError('There was a problem applying your changes.');
    }
  }),

  deleteAppPackageTask: task(function* () {
    let isConfirmed = yield this.intercomConfirmService.confirm({
      title: 'Delete this app?',
      confirmButtonText: 'Delete',
      primaryButtonType: 'primary-destructive',
      body: 'Deleting an app is permanent. App will be uninstalled on all workspaces that are using it and all tokens issued for this app will be revoked.',
    });
    if (!isConfirmed) {
      return;
    }

    try {
      yield this.appPackage.destroyRecord();
      this.intercomEventService.trackAnalyticsEvent({
        object: 'app_package',
        app_package_id: this.get('appPackage.id'),
        app_metadata: this.get('appPackage.changeRequest'),
        action: 'deleted',
      });
      this.notificationsService.notifyConfirmation('Your app has been deleted.');
      this.transitionToRoute('apps.app.developer-hub');
    } catch (e) {
      this.notificationsService.notifyError('There was a problem deleting your app.');
    }
  }),

  actions: {
    cancelEditAppPackage() {
      this.appPackage.rollbackAttributes();
      this.transitionToRoute('apps.app.developer-hub.app-packages.app-package.basic-info');
    },
  },
});
