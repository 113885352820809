/* RESPONSIBLE TEAM: team-reporting */
import Controller from '@ember/controller';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import type CustomReportsService from 'embercom/services/custom-reports-service';
import type IntlService from 'ember-intl/services/intl';

export default class ViewController extends Controller {
  queryParams = [];

  @service declare customReportsService: CustomReportsService;
  @service declare intercomEventService: any;
  @service declare permissionsService: any;
  @service declare intl: IntlService;

  @tracked showTemplateModal = false;

  @action async openTemplateModal() {
    this.showTemplateModal = true;
  }

  get newReportActions() {
    return [
      {
        items: [
          {
            text: this.intl.t('reporting.custom-reports.report.use-template'),
            stamp: { text: this.intl.t('reporting.stamps.beta'), color: 'sky' },
            onSelectItem: () => {
              this.openReportTemplateModal();
            },
          },
          {
            text: this.intl.t('reporting.custom-reports.report.from-scratch'),
            onSelectItem: () => {
              this.customReportsService.handleCreateReport();
            },
          },
        ],
      },
    ];
  }

  @action
  openReportTemplateModal() {
    if (!this.customReportsService.canChangeCustomReports) {
      this.customReportsService.loadChangePermissionModal();
    } else {
      this.openTemplateModal();
    }
  }
}
