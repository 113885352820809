/* RESPONSIBLE TEAM: team-customer-data-platform */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable promise/prefer-await-to-then */
/* eslint-disable ember/no-new-mixins */
import { isPresent } from '@ember/utils';
import { gt } from '@ember/object/computed';
import Mixin from '@ember/object/mixin';
import LegacySelectionState from 'embercom/models/legacy-selection-state';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';

/*
  TODO: make this more obvious
  The model argument on openMessageModalOrPromptUpgrade is only a model when we pass a selectionState instance,
  which in general is what we do. It does however accept a user-list-item controller when being called on a single
  user in the user list. This stinks and should be made more consistent.
*/
export default Mixin.create({
  // eslint-disable-next-line @intercom/intercom/no-legacy-modal
  modalService: service(),
  permissionsService: service(),
  intercomEventService: service(),
  determineModal(component, model, options, controllerProps = {}, callbacks = {}) {
    let action = model === null ? 'openMessageModalWithSelectionState' : 'openModal';
    this.send(action, component, model, options, controllerProps, callbacks);
  },

  shouldUseSimpleComposer: gt('selectionState.count', 0),

  _getSelectionState() {
    let selectionState = this.selectionState;
    if (selectionState.get('countIsZero')) {
      selectionState = selectionState.toSelectAllSelection();
    }
    return selectionState;
  },

  openMessageModalOrPromptUpgrade: action(function (
    component,
    model,
    options,
    controllerProps = {},
    callbacks = {},
  ) {
    this.permissionsService
      .checkOneOnOneMessagingPermission(
        'can_send_messages',
        options.isCompany,
        this.get('selectionState.count'),
      )
      .then(() => {
        let hasModel = isPresent(model);
        if (hasModel || this.shouldUseSimpleComposer) {
          let count = hasModel ? 1 : this.get('selectionState.count');
          this.intercomEventService.trackEvent('simple-composer-open', {
            users: count,
          });
        }
        this.determineModal(component, model, options, controllerProps, callbacks);
      });
  }),

  actions: {
    openModal(component, model, options, controllerProps, callbacks) {
      this.modalService.openModal(...arguments);
    },

    openMessageModalWithSelectionState(component, model, options) {
      if (this.isCompany) {
        this.send('openModalWithSelectionState', component, model.get('isCompany'), options);
      } else if (this.shouldUseSimpleComposer) {
        this.send('openModalWithSelectionState', component, model, options);
      }
    },
    openModalWithSelectionState(component, model, options) {
      if (model || this.hasSelectedUsers) {
        this._openModalWithSelectionState(component, model, options);
      } else if (this.totalCount > 0) {
        let selectionState = LegacySelectionState.create({
          isSelectAllMode: true,
          predicates: this.predicates,
          count: this.totalCount,
        });
        // eslint-disable-next-line @intercom/intercom/no-legacy-modal
        this.send('openModal', component, selectionState, options);
      }
    },
  },
  _openModalWithSelectionState(component, model, options) {
    let selectionState = this._getSelectionState();
    this._trackModalEvents(selectionState, this._getAction(component));
    if (selectionState.get('count') > 0) {
      // eslint-disable-next-line @intercom/intercom/no-legacy-modal
      this.send('openModal', component, selectionState, options);
    }
  },
  _getAction(component) {
    return component.slice(component.lastIndexOf('/') + 1) || 'unknown';
  },
  _trackModalEvents(selectionState, action) {
    if (['message', 'export', 'tag', 'delete'].includes(action)) {
      this.intercomEventService.trackEvent(`user-list-${action}`, {
        isUser: selectionState.get('isUserMessage'),
      });
      if (selectionState.get('hasUnselected')) {
        this.intercomEventService.trackEvent(`user-list-${action}-negative-selection`, {
          totalCount: selectionState.get('totalCount'),
          unselectedCount: selectionState.get('unselected.length'),
          isUser: selectionState.get('isUserMessage'),
        });
      }
    }
  },
});
