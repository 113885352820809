/* RESPONSIBLE TEAM: team-knowledge-and-data-setup */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
/* eslint-disable ember/no-actions-hash */
/* eslint-disable ember/no-classic-classes */
import { alias, readOnly, gt, or, equal, reads } from '@ember/object/computed';
import { computed } from '@ember/object';
import Controller, { inject as controller } from '@ember/controller';
import { inject as service } from '@ember/service';
import { task } from 'ember-concurrency';
import ajax from 'embercom/lib/ajax';

const ARTICLES_LIST_ROUTE_NAME = 'apps.app.articles.articles.list.all';

export default Controller.extend({
  appService: service(),
  realTimeEventService: service(),
  notificationsService: service(),
  router: service(),
  intercomEventService: service(),
  intercomConfirmService: service(),
  intl: service(),
  articlesController: controller('apps/app/articles'),
  store: service(),

  app: readOnly('appService.app'),
  queryParams: { search_term: { refreshModel: true }, url_partials: { refreshModel: true } },
  view: reads('model.view'),
  search_term: null,
  url_partials: [],
  isPresetViewChanged: reads('model.isPresetViewChanged'),

  init() {
    this._super(...arguments);
    this.realTimeEventService.on('ImportStarted', this, '_handleStartedImport');
    this.realTimeEventService.on('ImportCompleted', this, '_handleCompletedImport');
    this.realTimeEventService.on('ArticleSyncError', this, '_handleArticleSyncError');
  },

  showSearch: alias('articlesController.showSearch'),
  hasSearchterm: gt('search_term.length', 0),
  hasUrlPartials: gt('url_partials.length', 0),

  hasQuery: or('hasSearchterm', 'hasUrlPartials'),

  hasPerformedSearch: computed('hasQuery', 'isLoading', function () {
    return !this.isLoading && this.hasQuery;
  }),
  request: computed('model.request', 'synchronizationEnabled', function () {
    return this.get('model.request')
      ? JSON.parse(this.get('model.request'))
      : {
          conditions: {
            languages: [],
            status: '',
            collection_ids: [],
            author_ids: [],
            last_edited_by_ids: [],
            sync: this.synchronizationEnabled ? 'false' : null,
          },
          order: { direction: 'desc', key: 'updated_at' },
        };
  }),

  synchronizationEnabled: equal('zendeskSyncSetting.status', 'active'),

  getSyncSettings: task(function* () {
    let syncSettings = yield this.store.findAll('articles/article-sync-setting');
    let zendeskSyncSetting = syncSettings.find((setting) => setting.provider === 'zendesk');
    this.set('zendeskSyncSetting', zendeskSyncSetting);
  }),

  triggerManualSync: task(function* () {
    yield this.zendeskSyncSetting?.triggerManualSync();
  }),

  pauseRegularSync: task(function* () {
    yield this.zendeskSyncSetting?.pause();
    yield this.getSyncSettings.perform();
    return;
  }),

  loadNextPage: task(function* () {
    let articles = this.get('model.articles');
    let currentPage = articles.get('meta') ? articles.get('meta.page') : 0;
    let nextPage = articles.get('meta') ? articles.get('meta.next_page') : 1;
    let selectedContentStatus = this.hasQuery ? 'all' : this.get('model.selectedContentStatus');
    let locale = this.hasQuery || !selectedContentStatus ? null : this.get('model.locale');
    let selectedTagValues = this.hasQuery ? [] : this.get('model.selectedTagValues');
    if (nextPage > currentPage) {
      let articleListParams = {
        state: selectedContentStatus,
        locale,
        page: nextPage,
        phrase: this.search_term,
        url_partials: this.url_partials || [],
        per_page: 20,
        tag_ids: selectedTagValues,
        request: JSON.stringify(this.request),
      };

      let response = yield this.store.query('article-multilingual', articleListParams);
      articles.set('meta', response.meta);
      this.loadStats.perform(response.toArray());
      articles.pushObjects(response.toArray());
    }
  }).restartable(),

  loadStats: task(function* (articles) {
    if (!this.app.canUseFeature('bulk-load-article-stats')) {
      articles.forEach((article) => article.loadStats.perform());
    } else {
      let articleIds = articles.map((article) => article.id);
      let statsResponse = yield ajax({
        url: '/ember/articles/get_stats',
        data: {
          app_id: this.app.id,
          article_ids: articleIds,
        },
      });
      for (let article of articles) {
        let stats = statsResponse[article.id];
        article.set('lastStatsResponse', stats);
      }
    }
  }).restartable(),

  _handleStartedImport(event) {
    this.set('hasActiveApiImport', true);
    this.getSyncSettings.perform();
  },

  _handleCompletedImport(event) {
    this.set('hasActiveApiImport', false);

    if (this.router.currentRouteName === ARTICLES_LIST_ROUTE_NAME) {
      this.getSyncSettings.perform();
      this.notificationsService.notifyConfirmation(
        this.intl.t('articles.collections.import.notification.success'),
      );
      this.send('refreshModel');
    }
  },

  _handleArticleSyncError(event) {
    if (this.router.currentRouteName === ARTICLES_LIST_ROUTE_NAME) {
      this.notificationsService.notifyError(this.intl.t('articles.settings.sync.error.info'));
    }
  },

  actions: {
    updateView({ savedView }) {
      this.set('view', savedView);
      let filters = {
        request: { ...this.request },
      };
      filters.request.conditions = { ...savedView.savedViewData.conditions };
      this.send('updateFilters', filters, true);
    },

    updateFilters(
      filters = {},
      isSavedView = false,
      model = this.router.currentRoute.attributes.viewId || this.app,
    ) {
      let newRoute = this.router.currentRoute.name;
      let articleListParams = {
        language: filters.locale,
        tag_ids: filters.selectedTagValues,
        request: JSON.stringify(filters.request),
        id: model,
      };
      if (isSavedView) {
        model = this.view.id;
        newRoute = `${this.router.currentRoute.parent.name}.views`;
      }
      this.router.transitionTo(newRoute, model, {
        queryParams: articleListParams,
      });
      this.send('refreshModel');
    },

    performSearchTransition(queryParams) {
      this.intercomEventService.trackEvent('educate-event', {
        action: 'searched',
        object: 'article_list',
        place: 'article_list',
        owner: 'educate',
        search_term: queryParams.search_term,
        url_partials: queryParams.url_partials,
      });
      this.router.transitionTo('apps.app.articles.articles.list.all', {
        queryParams,
      });
    },
  },
});
