/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable ember/no-actions-hash */
/* eslint-disable promise/prefer-await-to-then */
/* eslint-disable ember/no-controllers */
/* eslint-disable ember/no-classic-classes */
import EmberObject, { computed } from '@ember/object';
import { A } from '@ember/array';
import { readOnly } from '@ember/object/computed';
import { inject as service } from '@ember/service';
import Controller from '@ember/controller';
import ajax from 'embercom/lib/ajax';
import { task } from 'ember-concurrency';

/* RESPONSIBLE TEAM: team-app-security */

export default Controller.extend({
  store: service(),
  intl: service(),
  notificationsService: service(),
  intercomConfirmService: service(),
  appService: service(),
  app: readOnly('appService.app'),
  isImpersonationSession: readOnly('app.impersonation_session'),

  hasUnsavedChanges() {
    return this.get('model.hasDirtyAttributes');
  },

  revertUnsavedChanges() {
    this.model.rollbackAttributes();
  },

  get columns() {
    return A([
      EmberObject.create({
        label: this.intl.t('account.account-access.approved'),
        canExpandToFit: true,
        component: 'table-cells/impersonation-consents/approved-at-cell',
      }),
      EmberObject.create({
        label: this.intl.t('account.account-access.status'),
        canExpandToFit: true,
        component: 'table-cells/impersonation-consents/status-cell',
      }),
    ]);
  },

  rows: computed('impersonationConsents.[]', function () {
    return this.impersonationConsents
      .map((consent) => this.buildRow(consent))
      .compact()
      .reverse();
  }),

  buildRow(consent) {
    if (!consent.get('createdAt')) {
      return;
    }
    return EmberObject.create({ consent });
  },

  terminateSession: task(function* (session) {
    let options = {
      title: this.intl.t('account.account-access.sessions.terminate-modal-title'),
      body: this.intl.t('account.account-access.sessions.terminate-modal-confirm-text'),
      confirmButtonText: this.intl.t(
        'account.account-access.sessions.terminate-modal-confirm-button-text',
      ),
    };

    let confirmed = yield this.intercomConfirmService.confirm(options);
    if (!confirmed) {
      return;
    }

    try {
      yield ajax({
        type: 'POST',
        url: '/ember/session_device_details/invalidate_session',
        data: JSON.stringify({
          id: session.get('id'),
          app_id: this.app.id,
        }),
      });
      this.refreshActiveSessions();
      this.notificationsService.notifyConfirmation(
        this.intl.t('account.account-access.sessions.terminated-successfully'),
      );
    } catch (error) {
      this.notificationsService.notifyError(
        this.intl.t('account.account-access.sessions.termination-error'),
      );
    }
  }),

  refreshActiveSessions() {
    let activeSessions = this.store.query('session-device-detail', {
      app_id: this.app.id,
    });

    this.set('sessions', activeSessions);
  },

  actions: {
    giveConsent() {
      return this.model
        .save()
        .then((consent) => {
          this.notificationsService.notifyConfirmation(
            this.intl.t('account.account-access.access-confirmation'),
          );
          this.impersonationConsents.pushObject(consent);
        })
        .catch(() => {
          this.notificationsService.notifyError(this.intl.t('account.account-access.access-error'));
        });
    },
    revokeConsent() {
      this.model
        .destroyRecord()
        .then((consent) => {
          this.notificationsService.notifyConfirmation(
            this.intl.t('account.account-access.revoke-confirmation'),
          );
          this.set('model', this.store.createRecord('admin-impersonation-consent'));
        })
        .catch(() => {
          this.notificationsService.notifyError(this.intl.t('account.account-access.revoke-error'));
        });
    },
  },
});
