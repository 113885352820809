/* RESPONSIBLE TEAM: team-workflows */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable ember/no-controllers */
/* eslint-disable ember/no-classic-classes */
import Controller, { inject as controller } from '@ember/controller';
import { readOnly } from '@ember/object/computed';
import { inject as service } from '@ember/service';

export default Controller.extend({
  appService: service(),
  app: readOnly('appService.app'),
  product: readOnly('app.customBotsProduct'),
  operatorController: controller('apps/app/automation'),
  previewConfiguration: readOnly('automationController.previewConfiguration'),
  featureKey: 'bot_workflows',
  hidePaywallBanner: false,
});
