/* RESPONSIBLE TEAM: team-data-interop */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
/* eslint-disable ember/no-actions-hash */
/* eslint-disable ember/require-computed-property-dependencies */
/* eslint-disable ember/no-controllers */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-assignment-of-untracked-properties-used-in-tracking-contexts */
import { computed } from '@ember/object';
import { inject as service } from '@ember/service';
import { alias, and, or, not, readOnly, equal } from '@ember/object/computed';
import Controller from '@ember/controller';
import { includes } from '@intercom/pulse/lib/computed-properties';
import safeWindowOpen from 'embercom/lib/safe-window-open';
import { task } from 'ember-concurrency';
import { isValidEmail } from 'embercom/lib/email';
import { getSamlVerificationErrorMessage, shouldShowLinkToSamlArticle } from 'embercom/lib/saml';
import { TEAMMATE_APP_URL } from 'embercom/lib/teammate-app';
import { buildDefaultScimEndpointUrl } from 'embercom/models/scim-setting';
import { action } from '@ember/object';

const AUTH_METHODS = {
  emailPassword: 'email_password',
  twoFa: 'email_password_otp',
  google: 'google_oauth2',
  saml: 'saml',
};

export const AUTH_OPTIONS = {
  all: 'all_auth',
  google: 'google_auth',
  saml: 'saml_auth',
};

export const SAML_AUTH_SUB_OPTIONS = {
  none: 'none',
  all: 'all_auth',
  googleAnd2fa: 'google_and_2fa',
  google: 'google_auth',
};

export const SAML_PROVISIONING_METHODS = {
  jit: 'jit',
  scim: 'scim',
  none: 'none',
};

export default Controller.extend({
  store: service(),
  init() {
    this._samlVerificationCompletedHandler = this._samlVerificationCompleted.bind(this);
    window.addEventListener('message', this._samlVerificationCompletedHandler);
    this._super(...arguments);
  },

  willDestroy() {
    if (this._samlVerificationCompletedHandler) {
      window.removeEventListener('message', this._samlVerificationCompletedHandler);
    }
    this._super(...arguments);
  },

  notificationsService: service(),
  appService: service(),
  securityAppSettingsService: service(),
  // eslint-disable-next-line @intercom/intercom/no-legacy-modal
  modalService: service(),
  app: alias('appService.app'),
  intl: service(),
  intercomEventService: service(),

  iosSdkApp: readOnly('app.iosSdkApps.firstObject'),
  androidSdkApp: readOnly('app.androidSdkApps.firstObject'),
  pingDomains: alias('model.pingDomains'),

  noDomainsHaveErrors: computed('pingDomains.@each.errorCode', function () {
    return this.pingDomains.isEvery('errorCode', null);
  }),

  webIdentityVerified: readOnly('app.identityVerified'),
  iosIdentityVerified: readOnly('iosSdkApp.identityVerified'),
  androidIdentityVerified: readOnly('androidSdkApp.identityVerified'),
  shouldEnableUnauthenticatedUserMerge: or(
    'webIdentityVerified',
    'iosIdentityVerified',
    'androidIdentityVerified',
  ),

  isScimSettingsDirty: and('scimEnabled', 'scimSettings.hasDirtyAttributes'),
  isDirty: or(
    'model.settings.hasDirtyAttributes',
    'samlAccount.hasDirtyAttributes',
    'isScimSettingsDirty',
    'hasChangedProvisioningMethod',
  ),
  isNotDirty: not('isDirty'),
  isSaveDisabled: or('isInvalidSecurityEmail', 'model.settings.isSaving', 'isNotDirty'),
  isInvalidSecurityEmail: computed('model.settings.securityContactEmail', function () {
    if (
      this.model.settings.securityContactEmail === '' ||
      this.model.settings.securityContactEmail === null
    ) {
      return false;
    }
    let result = !isValidEmail(this.model.settings.securityContactEmail);
    return result;
  }),
  googleSsoHint: computed('intl.locale', 'app.features', function () {
    return this.intl.t('apps.app.settings.security.google-domain-sign-in');
  }),
  shouldShowSecurityContactWarningIcon: computed('model.settings', function () {
    if (
      this.model.settings.hasDirtyAttributes &&
      this.model.settings.changedAttributes().securityContactEmail
    ) {
      return this.model.settings.changedAttributes().securityContactEmail[0] === '';
    }
    return this.model.settings.securityContactEmail === '';
  }),
  canToggleEmailContentSetting: readOnly('model.settings.canToggleNotificationEmailsWithContent'),

  twoFAAuth: [AUTH_METHODS.twoFa, AUTH_METHODS.google],
  googleAuth: [AUTH_METHODS.google],
  allAuth: [AUTH_METHODS.emailPassword, AUTH_METHODS.twoFa, AUTH_METHODS.google],
  samlAuth: [AUTH_METHODS.saml],

  twoFAAllowed: includes('auths', AUTH_METHODS.twoFa),
  googleSsoAllowed: includes('auths', AUTH_METHODS.google),
  emailPassAllowed: includes('auths', AUTH_METHODS.emailPassword),
  samlAllowed: includes('auths', AUTH_METHODS.saml),

  twoFANotAllowed: not('twoFAAllowed'),
  emailPassNotAllowed: not('emailPassAllowed'),
  samlNotAllowed: not('samlAllowed'),

  twoFASelected: and('twoFAAllowed', 'emailPassNotAllowed', 'samlNotAllowed'),
  googleAuthSelected: and(
    'twoFANotAllowed',
    'emailPassNotAllowed',
    'googleSsoAllowed',
    'samlNotAllowed',
  ),
  allAuthSelected: and('twoFAAllowed', 'emailPassAllowed', 'samlNotAllowed', 'googleSsoAllowed'),
  samlSelected: readOnly('samlAllowed'),

  nonSamlMethodAllowed: or('twoFAAllowed', 'googleSsoAllowed', 'emailPassAllowed'),
  samlAndSuboptionSelected: and('nonSamlMethodAllowed', 'samlAllowed'),
  showSamlVerificationModal: false,
  showEnforceSamlModal: false,
  showAllErrors: false,

  auths: alias('model.settings.authorizedSignInMethods'),
  ssoDomainWhitelist: alias('model.settings.ssoDomainWhitelist'),
  samlAccount: alias('model.settings.samlAccount'),
  scimSettings: alias('model.settings.scimSettings'),
  initialProvisioningMethod: undefined, // initiated on route
  selectedProvisioningMethod: undefined, // initiated on route
  scimEnabled: equal('selectedProvisioningMethod', SAML_PROVISIONING_METHODS.scim),

  canEnforceSSO: alias('model.settings.canEnableEnforceSso'),
  canEnforceSaml: alias('model.settings.canEnforceSaml'),

  canEnableSaml: alias('appService.app.canUseSamlSso'),
  canLogSamlEvents: alias('appService.app.canLogSamlEventData'),

  authOptions: AUTH_OPTIONS,
  openSectionId: null,

  authMethodSelected: computed('googleAuthSelected', 'samlSelected', function () {
    if (this.googleAuthSelected) {
      return AUTH_OPTIONS.google;
    } else if (this.samlSelected) {
      return AUTH_OPTIONS.saml;
    }
    return AUTH_OPTIONS.all;
  }),

  _getSamlSubMethod(auths) {
    if (auths.includes(AUTH_METHODS.emailPassword)) {
      return SAML_AUTH_SUB_OPTIONS.all;
    } else if (auths.includes(AUTH_METHODS.twoFa)) {
      return SAML_AUTH_SUB_OPTIONS.googleAnd2fa;
    } else if (auths.includes(AUTH_METHODS.google)) {
      return SAML_AUTH_SUB_OPTIONS.google;
    }
    return SAML_AUTH_SUB_OPTIONS.none;
  },

  samlSubMethodSelected: computed('auths', function () {
    return this._getSamlSubMethod(this.auths);
  }),

  hasChangedSamlSettings: computed(
    'model.settings',
    'samlSelected',
    'samlAccount.hasDirtyAttributes',
    function () {
      let authChanged = 'authorizedSignInMethods' in this.model.settings.changedAttributes();
      return this.samlSelected && (authChanged || this.samlAccount.hasDirtyAttributes);
    },
  ),

  hasChangedProvisioningMethod: computed(
    'initialProvisioningMethod',
    'selectedProvisioningMethod',
    function () {
      return this.initialProvisioningMethod !== this.selectedProvisioningMethod;
    },
  ),

  updateAuthorizedSignInMethods(authorizedMethods, samlSubMethod) {
    if (authorizedMethods === this.samlAuth) {
      switch (samlSubMethod) {
        case SAML_AUTH_SUB_OPTIONS.all:
          authorizedMethods = authorizedMethods.concat(this.allAuth);
          break;
        case SAML_AUTH_SUB_OPTIONS.googleAnd2fa:
          authorizedMethods = authorizedMethods.concat(this.twoFAAuth);
          break;
        case SAML_AUTH_SUB_OPTIONS.google:
          authorizedMethods = authorizedMethods.concat(this.googleAuth);
      }
    }

    this.auths = authorizedMethods;
  },

  _startSamlVerification: task(function* () {
    this.notificationsService.clear();
    try {
      this.intercomEventService.trackAnalyticsEvent({
        action: 'clicked',
        object: 'verify_saml',
        jit_enabled: this.samlAccount.jitEnabled,
      });
      yield this.samlAccount.save();
      safeWindowOpen(`${TEAMMATE_APP_URL}/saml/${this.samlAccount.name}/verify`);
    } catch (error) {
      this.notificationsService.notifyError(
        this.intl.t('apps.app.settings.security.error-starting'),
      );
    }
  }).drop(),

  _samlVerificationCompleted(event) {
    let expectedEventType = 'saml-verification-completed';
    let expectedOrigin = window.location.origin;
    let eventData;
    try {
      eventData = JSON.parse(event.data);
    } catch {
      eventData = null;
    }

    if (this.canLogSamlEvents) {
      console.info(event.data);
    }

    if (!eventData || eventData.type !== expectedEventType || event.origin !== expectedOrigin) {
      return;
    }

    if (eventData.success === true) {
      this.intercomEventService.trackAnalyticsEvent({
        action: 'verified',
        object: 'verify_saml',
      });
      this._save.perform();
    } else {
      this._notifySamlTestError(eventData);
    }
  },

  _initializeCurrentProvisioningMethod() {
    if (this.samlAccount?.jitEnabled) {
      return SAML_PROVISIONING_METHODS.jit;
    } else if (this.scimSettings) {
      return SAML_PROVISIONING_METHODS.scim;
    } else {
      return SAML_PROVISIONING_METHODS.none;
    }
  },

  _notifySamlTestError(eventData) {
    let { error_code: errorCode, message } = eventData;
    let errorMessage = getSamlVerificationErrorMessage(errorCode, message);
    let showLearnMoreButton = shouldShowLinkToSamlArticle(errorCode);

    if (showLearnMoreButton) {
      this.notificationsService.notifyErrorWithButton(
        errorMessage,
        { label: 'Learn more', action: this._openSamlHelpArticle.bind(this) },
        0,
      );
    } else {
      this.notificationsService.notifyError(errorMessage, 0);
    }
  },

  _openSamlHelpArticle() {
    safeWindowOpen(
      'https://www.intercom.com/help/en/articles/3974587-Integrate-with-an-identity-provider-and-log-in-with-SAML-SSO',
    );
  },

  _save: task(function* () {
    try {
      yield this.model.settings.save();
      this.securityAppSettingsService.set(
        'settings.notificationEmailsWithContentEnabled',
        this.model.settings.notificationEmailsWithContentEnabled,
      );
      this.set('showSamlVerificationModal', false);
      this.set('initialProvisioningMethod', this.selectedProvisioningMethod);
      this.notificationsService.notifyConfirmation(
        this.intl.t('apps.app.settings.security.security-settings-updated'),
      );
    } catch (err) {
      let notificationText;
      if (err.jqXHR?.responseJSON?.errors[0]) {
        notificationText = err.jqXHR.responseJSON.errors[0];
      } else {
        notificationText = this.intl.t('apps.app.settings.security.settings-not-updated');
      }
      this.notificationsService.notifyError(notificationText);
    }
  }).drop(),

  _updateAuthWith(methods) {
    let samlSubMethod = SAML_AUTH_SUB_OPTIONS.none;
    if (methods === this.samlAuth) {
      samlSubMethod = this._getSamlSubMethod(this.app.authorized_sign_in_methods);

      if (!this.samlAccount) {
        let { id } = this.app;
        this.set(
          'model.settings.samlAccount',
          this.store.createRecord('saml-account', { id, name: id }),
        );
      }
    }

    this.updateAuthorizedSignInMethods(methods, samlSubMethod);
  },

  enforceSSO: action(function () {
    this.setAuths('googleAuth');
  }),

  setAuths: action(function (auth) {
    this.updateAuthorizedSignInMethods(this.get(auth), this.samlSubMethodSelected);
  }),

  actions: {
    save() {
      if (this.hasChangedSamlSettings) {
        this.set('model.settings.samlAccount', this.samlAccount);

        if (!this.scimEnabled) {
          this.set('model.settings.scimSettings', null);
        }

        if (this.auths === this.samlAuth && !this.canEnforceSaml) {
          this.set('showEnforceSamlModal', true);
        } else if (this.samlAccount.isValid) {
          this.set('showSamlVerificationModal', true);
        } else {
          this.set('showAllErrors', true);
          this.notificationsService.notifyError(
            this.intl.t('apps.app.settings.security.security-settings-not-saved'),
          );
        }
      } else {
        this.set('model.settings.samlAccount', null);
        if (!(this.samlSelected && this.scimEnabled)) {
          this.set('model.settings.scimSettings', null);
        }
        this._save.perform();
      }
    },
    updateProvisioningMethod(selectedProvisioningMethod) {
      this.set('selectedProvisioningMethod', selectedProvisioningMethod);

      if (this.scimEnabled && !this.scimSettings) {
        this.set(
          'model.settings.scimSettings',
          this.store.createRecord('scim-setting', {
            immuneAdminIds: [],
            scimEndpointUrl: buildDefaultScimEndpointUrl(this.app.id),
          }),
        );
      }
    },
    updateSamlSubMethod(method) {
      this.updateAuthorizedSignInMethods(this.samlAuth, method);
    },
    startSamlVerification() {
      this._startSamlVerification.perform();
    },
    twoFaChanged(event) {
      if (event.target.checked) {
        this._updateAuthWith(this.twoFAAuth);
      } else {
        this._updateAuthWith(this.allAuth);
      }
    },
    updateAuthWith(methods) {
      this._updateAuthWith(methods);
    },
    openGoogleSsoWarningModal() {
      this.modalService.openModal('settings/modals/enforce-sso-warning', undefined, {
        enforceSSO: this.enforceSSO,
        canEnforceSSO: this.canEnforceSSO,
      });
    },
    onOpenSectionChange(sectionId) {
      this.set('openSectionId', sectionId);
    },
  },
});
