/* RESPONSIBLE TEAM: team-reporting */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable ember/no-actions-hash */
/* eslint-disable ember/no-controllers */
import ReportBase from 'embercom/controllers/apps/app/reports/base/report';
import { inject as service } from '@ember/service';
import Admin from 'embercom/models/admin';

export default ReportBase.extend({
  intercomEventService: service(),
  store: service(),
  actions: {
    changeTeamOrTeammate(teamOrTeammateId) {
      let teamOrTeammate;
      let models = [];
      if (teamOrTeammateId) {
        teamOrTeammate = Admin.peekAndMaybeLoad(this.store, teamOrTeammateId);
        models = [teamOrTeammate];
      }
      this.setProperties({
        teammateId: teamOrTeammateId,
        teamOrTeammate,
      });
      this.intercomEventService.trackAnalyticsEvent({
        action: 'filtered_by_teammate',
        object: this.reportName,
        models,
      });
    },
  },
});
