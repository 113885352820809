/* RESPONSIBLE TEAM: team-proactive-support */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable ember/no-classic-classes */
import ContentEditorController from 'embercom/controllers/apps/app/content/edit';
import { computed, action } from '@ember/object';
import { inject as service } from '@ember/service';
import { readOnly } from '@ember/object/computed';

export default ContentEditorController.extend({
  appService: service(),
  app: readOnly('appService.app'),
  queryParams: ['editing', 'stat', 'referer', 'filter', 'mode', 'view'],
  editing: false,
  stat: null,
  referer: null,

  initiallyOpenSectionName: computed('model.isNewRuleset', 'referer', function () {
    if (this.model.isNewRuleset) {
      return 'content';
    }
    if (this.referer === 'insert_tour_modal') {
      return 'insertion';
    }
  }),

  transitionToSearch: action(function () {
    this.transitionToRoute('apps.app.outbound.search');
  }),
});
