/* RESPONSIBLE TEAM: team-knowledge-interop */
import Controller from '@ember/controller';
import { inject as service } from '@ember/service';
import type Router from '@ember/routing/router-service';
import type CommonPrimaryNavBarService from 'embercom/services/common-primary-nav-bar-service';

export default class KnowledgeHubController extends Controller {
  queryParams = [];

  @service declare appService: $TSFixMe;
  @service declare navbarCollapsingService: $TSFixMe;
  @service declare router: Router;
  @service declare commonPrimaryNavBarService: CommonPrimaryNavBarService;
}
