/* RESPONSIBLE TEAM: team-customer-data-platform */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable ember/no-controllers */
/* eslint-disable ember/no-classic-classes */
import Controller from '@ember/controller';
import { inject as service } from '@ember/service';
export default Controller.extend({
  intl: service(),

  get steps() {
    return [
      { number: 1, name: this.intl.t('apps.app.import.mailchimp.connect') },
      { number: 2, name: this.intl.t('apps.app.import.mailchimp.choose-list') },
      { number: 3, name: this.intl.t('apps.app.import.mailchimp.tag') },
      { number: 4, name: this.intl.t('apps.app.import.mailchimp.import') },
    ];
  },
});
