/* RESPONSIBLE TEAM: team-data-interop */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
/* eslint-disable ember/no-controllers */
/* eslint-disable ember/no-classic-classes */
import Controller from '@ember/controller';
import { inject as service } from '@ember/service';
import { alias } from '@ember/object/computed';
import { task } from 'ember-concurrency';

export default Controller.extend({
  store: service(),
  intercomEventService: service(),
  notificationsService: service(),
  developerHub: service(),
  appPackage: alias('developerHub.appPackage'),
  isOnEdit: alias('developerHub.isOnEditPage'),
  changeRequestOauthClient: alias('appPackage.changeRequest.appPackageConfig.oauthClient'),
  _setRedirectUrls() {
    if (!this.get('changeRequestOauthClient.useOAuthFlow')) {
      this.setProperties({
        'changeRequestOauthClient.redirectUrls': [],
      });
    } else {
      this.setProperties({
        'changeRequestOauthClient.redirectUrls': this.get('changeRequestOauthClient.redirectUrls'),
      });
    }
  },
  addAnalyticsEvent(action) {
    this.intercomEventService.trackAnalyticsEvent({
      object: 'app_package',
      app_package_id: this.get('appPackage.id'),
      app_metadata: this.get('appPackage.changeRequest'),
      action,
    });
  },

  isOAuthClientInvalid: task(function* () {
    let { validations } = yield this.get(
      'appPackage.changeRequest.appPackageConfig.oauthClient',
    ).validate();
    return validations.get('isInvalid');
  }),

  saveAppPackageTask: task(function* () {
    try {
      let isInvalid = yield this.isOAuthClientInvalid.perform();
      if (isInvalid) {
        return;
      }

      this._setRedirectUrls();
      yield this.appPackage.save();
      yield this.store.queryRecord('developer-hub/app-package-install', {
        app_package_id: this.get('appPackage.id'),
        only_owner_app: true,
      });
      this.addAnalyticsEvent('saved');
      this.transitionToRoute('apps.app.developer-hub.app-packages.app-package.oauth');
    } catch (e) {
      this.notificationsService.notifyError('There was a problem saving your changes.');
    }
  }),
  cancelEditAppPackage() {
    this.appPackage.rollbackAttributes();
    this.addAnalyticsEvent('cancelled');
    this.transitionToRoute('apps.app.developer-hub.app-packages.app-package.oauth');
  },
});
