/* RESPONSIBLE TEAM: team-customer-data-platform */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/require-snake-case-analytics */
/* eslint-disable ember/no-actions-hash */
/* eslint-disable promise/prefer-await-to-then */
/* eslint-disable ember/no-controllers */
/* eslint-disable ember/no-classic-classes */
import { alias } from '@ember/object/computed';
import { inject as service } from '@ember/service';
import { TEAMMATE_APP_URL } from 'embercom/lib/teammate-app';
import Controller from '@ember/controller';
export default Controller.extend({
  intercomEventService: service(),
  redirectService: service(),
  appService: service(),
  mailchimpImport: alias('model.mailchimpImport'),
  syncingState: alias('model.syncingState'),

  actions: {
    redirectToOauth() {
      this.redirectService.redirect(
        `${TEAMMATE_APP_URL}/mailchimp_sync/install?app_id=${this.appService.app.id}&caller=import`,
      );
    },

    transitonToNextPage() {
      let mailchimpImport = this.mailchimpImport;
      mailchimpImport.set('accessToken', this.syncingState.access_token);

      mailchimpImport.save().then(() => {
        this.intercomEventService.trackAnalyticsEvent({
          action: 'mailchimp-authenticated',
          object: 'mailchimp-import',
        });
        this.transitionToRoute('apps.app.import.mailchimp.lists', mailchimpImport);
      });
    },
  },
});
