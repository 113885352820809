/* RESPONSIBLE TEAM: team-customer-data-platform */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable ember/no-actions-hash */
/* eslint-disable ember/require-computed-property-dependencies */
/* eslint-disable ember/no-classic-classes */
import { or } from '@ember/object/computed';
import { computed } from '@ember/object';
import Evented from '@ember/object/evented';
import Controller from '@ember/controller';
import SearchModel from 'embercom/models/search';
import SegmentService from 'embercom/lib/segments/segment-service';
import { notImplemented } from '@intercom/pulse/lib/computed-properties';

export default Controller.extend(Evented, {
  queryParams: ['searchTerm'],
  searchTerm: '',
  showSearch: false,
  searchActive: or('showSearch', 'search.hasValue'),

  segmentRoutePath: notImplemented(),

  allSegment: computed('segment', function () {
    return SegmentService.findAllSegmentFromList(this.model, this.segment);
  }),

  init() {
    this._super(...arguments);
    this.set(
      'search',
      SearchModel.create({
        queryParams: this,
      }),
    );
  },

  actions: {
    exitSearch() {
      if (!this.get('search.hasValue') && !this.searchActive) {
        return;
      }
      this.set('showSearch', false);
      this.set('searchTerm', '');
      this.search.reset();
    },
    doSearch(searchItem) {
      let searchTerm = searchItem.get('isStandardSearch')
        ? searchItem.get('name')
        : `tag: ${searchItem.get('name')}`;
      this.transitionToRoute(this.segmentRoutePath, this.get('allSegment.identifier'), {
        queryParams: { searchTerm },
      });
    },
    toggleSearch() {
      if (this.searchActive) {
        return this.send('exitSearch');
      }

      this.set('showSearch', true);
      if (this.get('segment.hasDirtyAttributes')) {
        this.segment.rollbackAttributes();
      }

      this.transitionToRoute(this.segmentRoutePath, this.get('allSegment.identifier'));
    },
  },
});
