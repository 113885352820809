/* RESPONSIBLE TEAM: team-pricing-and-packaging */
import Controller from '@ember/controller';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import type IntlService from 'ember-intl/services/intl';
import type IntercomConfirmService from 'embercom/services/intercom-confirm-service';
import type Router from '@ember/routing/router-service';
import { isPresent } from '@ember/utils';
import {
  seatsByTeammates,
  countAmountOfSeatModified,
} from 'embercom/lib/teammates/calculate-common-seats';
import { tracked } from '@glimmer/tracking';

export default class TeammatesPermissionsController extends Controller {
  queryParams = [''];

  @service declare appService: $TSFixMe;
  @service declare notificationsService: $TSFixMe;
  @service declare intercomConfirmService: IntercomConfirmService;
  @service declare intl: IntlService;
  @service declare permissionsMutatorService: $TSFixMe;
  @service declare router: Router;
  @service declare intercomEventService: $TSFixMe;

  @tracked hasSeatsToBeSaved = false;

  declare model: $TSFixMe;

  getChangedPermissionProperties(permissionsObject: $TSFixMe) {
    let rawPermissions = permissionsObject.permissions();
    let changedPermissions = Object.keys(rawPermissions).reduce((result: $TSFixMe, key) => {
      if (rawPermissions[key] !== undefined) {
        result[key] = rawPermissions[key];
      }
      return result;
    }, {});

    if (permissionsObject.accessType !== undefined) {
      changedPermissions['accessType'] = permissionsObject.accessType;
      changedPermissions['includeUnassigned'] = permissionsObject.includeUnassigned;
      changedPermissions['assigneeBlockedList'] = permissionsObject.assigneeBlockedList;
    }

    return Object.assign({ role_id: permissionsObject.role_id }, changedPermissions);
  }

  @action async validateAndSavePermissions(seatTypes: $TSFixMe, isOverage = false) {
    try {
      this.model.permissionsObject.validate();
      this.savePermissions(seatTypes, isOverage);
    } catch (error) {
      this.notificationsService.notifyError(error.message);
    }
  }

  @action
  async savePermissions(seatTypes: $TSFixMe, isOverage: boolean) {
    let { teammates, permissionsObject } = this.model;
    if (!isOverage) {
      let confirmed = await this.intercomConfirmService.confirm({
        title: this.intl.t('settings.teammates.permissions.apply-changes-modal.apply-changes'),
        confirmButtonText: this.intl.t(
          'settings.teammates.permissions.apply-changes-modal.apply-changes',
        ),
        body: this.intl.t('settings.teammates.permissions.apply-changes-modal.message-body'),
      });

      if (!confirmed) {
        return;
      }
    }
    let permissionsProperties = this.getChangedPermissionProperties(permissionsObject);
    try {
      await this.bulkEditSeatsAndPermissions(teammates, seatTypes, permissionsProperties);
      this.hasSeatsToBeSaved = false;

      this.model.closeWithoutSaving = true;
      this.router.transitionTo('apps.app.settings.workspace.teammates');
    } catch (err) {
      console.error(err);
      if (
        this.appService.app.cannotAssignWiderAccess &&
        isPresent(err?.jqXHR?.responseJSON?.tokens)
      ) {
        this.notificationsService.notifyError(
          this.intl.t('settings.error_message.cannot_modify_unowned_permissions_error'),
        );
      } else {
        this.notificationsService.notifyError(
          this.intl.t('settings.error_message.cannot_save_error'),
        );
      }
      if (this.appService.app.canUseBulkEditSeats) {
        this._fireBulkEditSeatsAnalyticsEvent({ is_successful: false });
      }
    }
  }

  async bulkEditSeatsAndPermissions(
    teammates: $TSFixMe,
    seatTypes: $TSFixMe,
    permissionsProperties: $TSFixMe,
  ) {
    let seatTypeByUsers: any = seatsByTeammates(teammates, seatTypes, this.model.seatsSelections);

    if (!permissionsProperties.can_inbox__access_copilot) {
      Object.keys(seatTypeByUsers).forEach((key) => {
        seatTypeByUsers[key] = seatTypeByUsers[key].filter((seat: string) => seat !== 'copilot');
      });
    }

    await this.permissionsMutatorService.bulkEditSeatsAndPermissions(
      teammates,
      permissionsProperties,
      seatTypeByUsers,
      this.model.reassignments,
    );

    this.notificationsService.notifyConfirmation(
      this.intl.t('settings.teammates.permissions.notification.permissions-and-seats-changed', {
        teammateCount: teammates.length,
      }),
    );
    let seatModified: $TSFixMe = countAmountOfSeatModified(teammates, seatTypeByUsers);

    this._fireBulkEditSeatsAnalyticsEvent({
      is_successful: true,
      total_seatless_teammates: Object.values(seatTypeByUsers).filter(
        (seats: Array<string>) => seats.length === 0,
      ).length,
      difference_in_support_seat: seatModified['support'],
      difference_in_marketing_seat: seatModified['marketing'],
      difference_in_convert_seat: seatModified['convert'],
      difference_in_engage_seat: seatModified['engage'],
    });
  }

  @action
  seatsUpdated(hasUpdatedSeats: boolean) {
    this.hasSeatsToBeSaved = hasUpdatedSeats;
  }

  _fireBulkEditSeatsAnalyticsEvent({
    is_successful,
    total_seatless_teammates = 0,
    difference_in_support_seat = 0,
    difference_in_marketing_seat = 0,
    difference_in_convert_seat = 0,
    difference_in_engage_seat = 0,
  }: {
    is_successful: boolean;
    total_seatless_teammates?: number;
    difference_in_support_seat?: number;
    difference_in_marketing_seat?: number;
    difference_in_convert_seat?: number;
    difference_in_engage_seat?: number;
  }) {
    this.intercomEventService.trackAnalyticsEvent({
      action: 'clicked',
      object: 'bulk_edit_seats_save_button',
      total_edited_teammates: this.model.teammates.length,
      total_seatless_teammates,
      difference_in_support_seat,
      difference_in_marketing_seat,
      difference_in_convert_seat,
      difference_in_engage_seat,
      is_successful,
    });
  }
}
