/* RESPONSIBLE TEAM: team-growth-opportunities */
import { buildValidations, validator } from 'ember-cp-validations';

const Validations = buildValidations({
  companyName: validator('presence', {
    presence: true,
    ignoreBlank: true,
  }),
  companyWebsite: validator('presence', {
    presence: true,
    ignoreBlank: true,
  }),
  companySize: validator('presence', {
    presence: true,
  }),
  fundingLevel: validator('presence', {
    presence: true,
  }),
  selectedPartner: validator('presence', {
    presence: true,
  }),
  partnerJoinDate: validator('presence', {
    presence: true,
  }),
  yearFounded: validator('presence', {
    presence: true,
  }),
  intent: validator('presence', {
    presence: true,
  }),
  intentOther: [
    validator('presence', {
      presence: true,
    }),
    validator('length', {
      min: 1,
      max: 250,
    }),
  ],
});

export default Validations;
