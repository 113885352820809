/* RESPONSIBLE TEAM: team-workflows */
import Controller from '@ember/controller';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';

export default class AutomationController extends Controller {
  queryParams = ['section'];
  @service modalService;

  @action
  completeConversationImportFlow() {
    this.send('refreshRoute');
    this.modalService.closeModal();
  }

  get identity() {
    return this.model.identity;
  }

  get messengerSettings() {
    return this.model.messengerSettings;
  }

  get customBotsSettings() {
    return this.model.customBotsSettings;
  }

  get botInboxSettings() {
    return this.model.botInboxSettings;
  }

  get aiAgentQuickReplySettings() {
    return this.model.aiAgentQuickReplySettings;
  }

  get aiAgentSettings() {
    return this.model.aiAgentSettings;
  }
}
