/* RESPONSIBLE TEAM: team-pricing-and-packaging */
import type Store from '@ember-data/store';
import Controller from '@ember/controller';
import { action } from '@ember/object';
import type RouterService from '@ember/routing/router-service';
import { inject as service } from '@ember/service';

export default class CsvImport extends Controller {
  queryParams = [''];

  @service declare router: RouterService;
  @service declare store: Store;

  declare model: $TSFixMe;

  _getInviteEmails() {
    let inviteEmails = this.model.columns[this.model.selectedIndex];
    if (this.model.hasHeaderRow) {
      inviteEmails = inviteEmails.slice(1);
    }
    return inviteEmails;
  }

  @action
  onContinue() {
    let transition = this.router.transitionTo('apps.app.settings.workspace.teammates.invite.new');
    // @ts-ignore
    transition.data.emails = this._getInviteEmails();
  }
}
