/* RESPONSIBLE TEAM: team-knowledge-and-data-setup */
import Controller from '@ember/controller';
import { tracked } from '@glimmer/tracking';
import { type ContentFilter } from 'embercom/components/knowledge-hub/overview';

export default class KnowledgeOverviewController extends Controller {
  queryParams = ['tab', 'manageConversations', 'manageMacros'];

  @tracked tab?: ContentFilter | undefined;
  @tracked manageConversations = false;
  @tracked manageMacros = false;
}
