/* RESPONSIBLE TEAM: team-customer-data-platform */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/require-snake-case-analytics */
/* eslint-disable ember/no-actions-hash */
/* eslint-disable promise/prefer-await-to-then */
/* eslint-disable ember/no-controllers */
/* eslint-disable ember/no-classic-classes */
import { alias, reads } from '@ember/object/computed';
import Controller, { inject as controller } from '@ember/controller';
import MailchimpList from 'embercom/models/mailchimp-list';
import { inject as service } from '@ember/service';
import { task } from 'ember-concurrency';
import { action } from '@ember/object';

export default Controller.extend({
  intercomEventService: service(),
  notificationsService: service(),
  intl: service(),
  appController: controller('apps.app'),
  app: alias('appController.model'),
  importController: controller('apps.app.import.mailchimp'),
  mailchimpImport: alias('model'),
  mailchimpList: null,
  lists: reads('mailchimpImport.lists'),
  fetchSingleList: task(function* (listID) {
    try {
      let list = yield MailchimpList.singleList(this.app.id, listID);
      if (list.errorThrown) {
        throw new Error(this.intl.t('apps.app.import.mailchimp.lists.unable-to-find'));
      } else {
        this.set('mailchimpList', list);
        this.set('lists', [list]);
      }
    } catch (error) {
      this.notificationsService.notifyError(error);
    }
  }),
  selectedListID: null,

  lookupList: action(function (listID) {
    if (listID) {
      this.fetchSingleList.perform(listID).then(() => {
        this.set('selectedListID', null);
      });
    } else {
      this.set('lists', this.get('mailchimpImport.lists'));
    }
  }),

  actions: {
    transitionToTag() {
      this.intercomEventService.trackAnalyticsEvent({
        action: 'mailchimp-list-selected',
        object: 'mailchimp-import',
      });
      this.set('importController.mailchimpList', this.mailchimpList);
      this.transitionToRoute('apps.app.import.mailchimp.tag', this.mailchimpImport);
    },
  },
});
