/* RESPONSIBLE TEAM: team-proactive-support */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable ember/no-controllers */
import OutboundBaseListController from 'embercom/lib/outbound/base-list-controller';
import { inject as service } from '@ember/service';
import { getOwner } from '@ember/application';

export default OutboundBaseListController.extend({
  router: service(),
  messagesTags: [],

  init() {
    this._super(...arguments);
    this.router.on('routeWillChange', (transition) => {
      if (transition.targetName) {
        let controller = getOwner(this).lookup(`controller:${transition.targetName}`);
        if (controller) {
          if (controller.bannerData) {
            this.set('bannerData', controller.bannerData);
          }
          this.set('hideDeliveryTypeTabs', controller.hideDeliveryTypeTabs);
          this.set('hideAudienceComponent', controller.hideAudienceComponent);
          this.set('showNewsfeedComponent', controller.showNewsfeedComponent);
        }
      }
    });
  },
});
