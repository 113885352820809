/* RESPONSIBLE TEAM: team-proactive-support */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable ember/no-controllers */
import Controller from '@ember/controller';
import { CONNECTION_POINTS } from 'embercom/routes/graph-demo';
import { action } from '@ember/object';

export default class GraphDemoController extends Controller {
  connectionPoints = CONNECTION_POINTS;

  @action deleteEdge(edge) {
    this.model.deleteEdge(edge);
  }
}
