/* RESPONSIBLE TEAM: team-pricing-and-packaging */
import Controller from '@ember/controller';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import type Store from '@ember-data/store';
import { tracked } from '@glimmer/tracking';

export default class InvoicesController extends Controller {
  @service declare store: Store;
  @service declare appService: $TSFixMe;

  queryParams = ['unpaidInvoice', 'nextAttempt', 'finalAttempt'];

  @tracked unpaidInvoice = null;
  @tracked nextAttempt = null;
  @tracked finalAttempt = null;
  @tracked invoices = [];
  @tracked customer = null;
  @tracked peopleReachedReports = null;

  get app() {
    return this.appService.app;
  }

  get filteredInvoices() {
    return this.invoices.filter((invoice: $TSFixMe) => {
      if (invoice.get('manuallyInvoiced')) {
        return false;
      }

      return true;
    });
  }

  get manualInvoicesPresent() {
    let invoices = this.invoices;
    return invoices.any((invoice: $TSFixMe) => invoice.get('manuallyInvoiced') === true);
  }

  @action
  async retrieveInvoices() {
    return await this.store.findAll('invoice').then((retrievedInvoices) => {
      let sortedInvoices = retrievedInvoices
        .toArray()
        .sort((a, b) => b.parsedDueDate.unix() - a.parsedDueDate.unix());
      this.set('invoices', sortedInvoices);
    });
  }
}
