/* RESPONSIBLE TEAM: team-help-desk-experience */
import Controller from '@ember/controller';
import ENV from 'embercom/config/environment';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { task } from 'ember-concurrency-decorators';
import { timeout } from 'ember-concurrency';
import { inject as service } from '@ember/service';
import { DEFAULT_MACRO_TYPES } from 'embercom/lib/inbox/constants';
import ajax from 'embercom/lib/ajax';

export default class MacrosController extends Controller {
  @service appService;
  @service store;
  @service intercomConfirmService;
  @service notificationsService;
  @service savedReplySearchService;
  @service intercomEventService;
  @service csv;
  @service permissionsService;
  @service intl;
  @service guideLibraryService;

  @tracked selectedReply;
  @tracked editingReply = false;
  @tracked editorDirty = false;
  @tracked replies = this.model.savedReplies;
  @tracked sortedReplies = [];
  @tracked mode = null;

  query = '';
  queryParams = ['mode'];

  get app() {
    return this.appService.app;
  }

  get initiallySelectedReply() {
    if (this.appService.app.canUseSavedRepliesFuzzySearch) {
      return this.initialReplyFromFuzzySearch;
    } else {
      return this.initialReply;
    }
  }

  get initialReplyFromFuzzySearch() {
    if (this.canManageMacros) {
      return this.sortedReplies[0];
    } else {
      return this.sortedReplies.filter((reply) => {
        return reply.visibility === 'owner';
      }).firstObject;
    }
  }

  get initialReply() {
    if (this.canManageMacros) {
      return this.sortedReplies.filter((reply) => {
        return !(reply.isNew || reply.isDeleted);
      }).firstObject;
    } else {
      return this.sortedReplies.filter((reply) => {
        return reply.visibility === 'owner' && !(reply.isNew || reply.isDeleted);
      }).firstObject;
    }
  }

  get hasUserCreatedMacros() {
    let filteredMacros = this.replies?.find(
      (reply) => !reply.name?.toLocaleLowerCase().includes('example'),
    );
    return Boolean(filteredMacros?.name);
  }

  get savedRepliesPresent() {
    return this.replies.length;
  }

  get searchResultsPresent() {
    return this.sortedReplies.length;
  }

  get hasUnsavedChanges() {
    return this.editorDirty;
  }

  get readOnly() {
    return false;
  }

  get confirmationModalContent() {
    return {
      title: this.intl.t('inbox.manage-macros.saved-replies.delete-confirmation-title'),
      body: this.intl.t('inbox.manage-macros.saved-replies.delete-confirmation-body'),
      confirmButtonText: this.intl.t(
        'inbox.manage-macros.saved-replies.delete-confirmation-confirm-button',
      ),
      cancelButtonText: this.intl.t(
        'inbox.manage-macros.saved-replies.delete-confirmation-cancel-button',
      ),
    };
  }

  get createDisabled() {
    return this.readOnly || this.selectedReply?.isNew || this.createSavedReply.isRunning;
  }

  get canManageMacros() {
    let permissions = this.app.currentAdmin?.currentAppPermissions;
    if (!permissions) {
      return true;
    }
    return permissions.can_manage_saved_replies;
  }

  get adminCanExportCsv() {
    return this.permissionsService.currentAdminCan('can_export_csv');
  }

  get showExportButton() {
    return this.adminCanExportCsv;
  }

  @task({ drop: true })
  *updateSelectedReply(savedReply) {
    if (!(yield this.abortEditing.perform())) {
      return;
    }
    if (savedReply) {
      this.editingReply = true;
    }
    this.selectedReply = savedReply;
  }

  @action
  updateEditState(newState) {
    this.editingReply = newState;
  }

  @action
  updateDirtyState(newState) {
    this.editorDirty = newState;
  }

  @action
  cancelEditingReply() {
    if (this.selectedReply) {
      if (this.selectedReply.isNew) {
        this.selectedReply.deleteRecord();
        this.selectedReply = this.initiallySelectedReply;
      } else {
        this.selectedReply.rollbackAttributes();
      }
    }

    this.editingReply = false;
    this.editorDirty = false;
  }

  @action
  openMacrosArticleInMessenger() {
    window.Intercom('showArticle', 6433193);
    this.triggerAnalytics('creating_and_managing_macros', 'discovery_banner_link');
  }

  @action
  saveSelectedReply(selectedReply, newName, composer, selectedTeams, newVisibility, newTypes) {
    let blocks = composer.getBlocks();
    selectedReply.blocks = blocks;
    selectedReply.name = newName;
    selectedReply.visibleToTeamIds = selectedTeams;
    selectedReply.visibility = newVisibility;

    if (newTypes) {
      selectedReply.types = newTypes;
    }

    let actionTypes = selectedReply.actions?.map((a) => a.type) || [];
    let event = {
      action: 'saved',
      object: 'macros.save',
      place: 'macros',
      assign: actionTypes.any(
        (a) => a === 'assign-conversation' || a === 'assign-conversation-to-owner',
      ),
      close: actionTypes.any((a) => a === 'close-conversation'),
      snooze: actionTypes.any((a) => a === 'snooze-conversation'),
      tag: actionTypes.any((a) => a === 'add-tag-to-conversation'),
      priority: actionTypes.any((a) => a === 'change-conversation-priority'),
      cda: actionTypes.any((a) => a === 'set-conversation-data-attribute'),
      has_text: blocks.length > 0,
      visibility: selectedReply.visibility,
    };
    if (selectedTeams) {
      event.visibilityRestrictedToTeamsCount = selectedTeams.length;
    }
    this.intercomEventService.trackAnalyticsEvent(event);
    return this.saveReply.perform(selectedReply);
  }

  @action
  deleteSelectedReply(selectedReply) {
    return this.deleteReply.perform(selectedReply);
  }

  @action
  async export() {
    this.fetchUsageDataByEmail.perform();
  }

  @task({ drop: true })
  *fetchUsageDataByEmail() {
    this.intercomEventService.trackEvent('export-csv', {
      action: 'exported',
      object: 'csv',
      place: 'settings',
      type: 'saved_reply',
    });

    try {
      yield this.fetchUsageData.perform();
      this.notificationsService.notifyConfirmation(
        this.intl.t('inbox.manage-macros.saved-replies.export-email-success'),
      );
    } catch (_e) {
      this.notificationsService.notifyError(
        this.intl.t('inbox.manage-macros.saved-replies.export-error'),
      );
    }
  }

  @task({ drop: true })
  *fetchUsageData() {
    try {
      return yield ajax({
        url: '/ember/saved_reply/export_usage',
        type: 'GET',
        data: {
          app_id: this.appService.app.id,
          admin_id: this.appService.app.currentAdmin.id,
        },
      });
    } catch (_e) {
      this.notificationsService.notifyError(
        this.intl.t('inbox.manage-macros.saved-replies.export-error'),
      );
    }
  }

  @task({ drop: true })
  *createSavedReply() {
    if (!(yield this.abortEditing.perform())) {
      return;
    }

    let appId = this.model.app.id;
    let visibility = this.canManageMacros ? 'everyone' : 'owner';
    let newReply = this.store.createRecord('saved-reply', {
      app_id: appId,
      visibleToTeamIds: null,
      visibility,
      types: DEFAULT_MACRO_TYPES.mapBy('id'),
    });

    this.selectedReply = newReply;
    this.updateEditState(true);

    this.intercomEventService.trackAnalyticsEvent({
      action: 'clicked',
      object: 'macros_new_macro',
      place: 'macros',
    });
  }

  @task({ drop: true })
  *saveReply(reply) {
    if (reply.validationMessage !== undefined) {
      this.notificationsService.notifyError(reply.validationMessage);
      return;
    }

    if (!reply.availabilityValid) {
      this.notificationsService.notifyError(
        this.intl.t('inbox.manage-macros.saved-replies.availability-error'),
      );
      return;
    }

    try {
      yield reply.save();
      this.app.refreshSavedReplies();
    } catch (error) {
      console.error(error);

      if (error.jqXHR && error.jqXHR.status === 422) {
        if (
          error.jqXHR.responseJSON.errors.name?.length &&
          error.jqXHR.responseJSON.errors.name[0] === 'has already been taken'
        ) {
          this.notificationsService.notifyError(
            this.intl.t('inbox.manage-macros.saved-replies.name-taken-error'),
          );
          return;
        }
        if (error.jqXHR.responseJSON.errors.blocks?.length) {
          this.notificationsService.notifyError(error.jqXHR.responseJSON.errors.blocks[0]);
          return;
        }
      }

      let operationType = reply.isNew
        ? this.intl.t('inbox.manage-macros.saved-replies.create')
        : this.intl.t('inbox.manage-macros.saved-replies.update');
      this.notificationsService.notifyError(
        this.intl.t('inbox.manage-macros.saved-replies.save-error', { type: operationType }),
      );
      return;
    }

    if (reply.actions.length > 0) {
      this.intercomEventService.trackEvent('created_macros');
    }

    yield this.refreshSavedRepliesList.perform();

    this.notificationsService.notifyConfirmation(
      this.intl.t('inbox.manage-macros.saved-replies.save-success'),
    );
    if (this.guideLibraryService.canUseGuideLibraryService) {
      yield this.guideLibraryService.markStepCompleted('display_only_create_your_macros');
    }
    this.editorDirty = false;
  }

  @task({ drop: true })
  *deleteReply(reply, composer) {
    try {
      yield reply.destroyRecord();
      this.app.refreshSavedReplies();
    } catch (error) {
      console.error(error);
      this.notificationsService.notifyError(
        this.intl.t('inbox.manage-macros.saved-replies.delete-error'),
      );
      reply.rollbackAttributes();
      return;
    }

    yield this.refreshSavedRepliesList.perform();

    this.notificationsService.notifyConfirmation(
      this.intl.t('inbox.manage-macros.saved-replies.delete-success'),
    );
    this.selectedReply = this.initiallySelectedReply;

    if (composer) {
      composer.send('load', this.selectedReply?.blocks);
    }

    this.editingReply = false;
  }

  @task({ drop: true })
  *initSavedReplySearch() {
    if (this.app.canUseSavedRepliesFuzzySearch) {
      let results = yield this.savedReplySearchService.search.perform();
      this.sortedReplies = results;
    } else {
      this.sortedReplies = this.replies.sortBy('name');
    }

    if (this.mode === 'create') {
      yield this.createSavedReply.perform();
    } else {
      this.selectedReply = this.initiallySelectedReply;
    }
  }

  @task({ restartable: true })
  *debouncedSearch(query = '', initSelectReply = true) {
    yield timeout(ENV.APP._200MS);
    this.query = query;

    if (!(yield this.abortEditing.perform())) {
      return;
    }

    if (this.app.canUseSavedRepliesFuzzySearch) {
      this.sortedReplies = yield this.savedReplySearchService.search.perform(query);
    } else {
      this.sortedReplies = this.savedReplySearchService
        .searchUsingRegex(this.replies, query)
        .sortBy('name');
      if (initSelectReply) {
        this.selectedReply = this.initiallySelectedReply;
      }
    }
  }

  @task({ restartable: true })
  *refreshSavedRepliesList() {
    if (this.app.canUseSavedRepliesFuzzySearch) {
      yield this.savedReplySearchService.rebuild();
    }
    yield this.debouncedSearch.perform(this.query, false);
  }

  @task({ drop: true })
  *abortEditing() {
    if (this.saveReply.isRunning || !this.hasUnsavedChanges) {
      return true;
    }

    let canAbandonWithoutChecking = !this.editorDirty;
    let userWantsToContinue;

    if (!canAbandonWithoutChecking) {
      userWantsToContinue = yield this.intercomConfirmService.confirm(
        this.confirmationModalContent,
      );
    }
    let shouldAbandonChanges = canAbandonWithoutChecking || !userWantsToContinue;

    if (shouldAbandonChanges) {
      this.cancelEditingReply();
      return true;
    } else {
      return false;
    }
  }

  @action
  triggerAnalytics(object, section = 'dropdown_item') {
    this.intercomEventService.trackAnalyticsEvent({
      action: 'clicked',
      object,
      context: `onboarding_guide_library.settings.macros.banner.${section}`,
      place: 'settings.saved-replies-macros',
      section,
    });
  }
}
