/* RESPONSIBLE TEAM: team-growth-opportunities */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable ember/no-controllers */
/* eslint-disable ember/no-classic-classes */
import Controller from '@ember/controller';
import { computed } from '@ember/object';
import { oneWay, readOnly } from '@ember/object/computed';
import { inject, inject as service } from '@ember/service';
import FOOTER_CONTENT from 'embercom/lib/home/guide-footer-content';

export default Controller.extend({
  intercomEventService: service(),
  media: inject(),
  appService: inject(),
  app: oneWay('appService.app'),
  showMobileView: oneWay('media.isMobile'),
  guideIdentifier: readOnly('model.id'),
  footerContent: computed('guideIdentifier', function () {
    return FOOTER_CONTENT[this.guideIdentifier];
  }),
});
