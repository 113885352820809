/* RESPONSIBLE TEAM: team-ai-agent */
import Controller from '@ember/controller';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';

export default class ShowController extends Controller {
  @service router;
  @service appService;

  queryParams = ['eventType'];
  @tracked eventType = null;
  @tracked answersRoute = this.appService.app.answersRoute;
  @tracked currentRouteName = `${this.answersRoute}.answer.show`;

  @action
  editExampleQuestions(answer) {
    this.router.transitionTo(`${this.answersRoute}.answer.edit`, answer.id, {
      queryParams: {
        expandSelectedExamples: true,
      },
    });
  }

  @action
  redirectToEdit(answer) {
    this.router.transitionTo(`${this.answersRoute}.answer.edit`, answer.id, {
      queryParams: {
        expandSelectedExamples: true,
      },
    });
  }

  @action
  composeAnswer(answer) {
    this.router.transitionTo(`${this.answersRoute}.answer.edit`, answer.id, {
      queryParams: {
        initiallyOpenSectionName: 'response-editor',
      },
    });
  }
}
