/* RESPONSIBLE TEAM: team-proactive-support */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable ember/no-controllers */
import Controller from '@ember/controller';
import { action } from '@ember/object';
import {
  senderTypes,
  emailTemplateTypes,
  premadeTemplates,
} from 'embercom/models/data/outbound/constants';
import { inject as service } from '@ember/service';
import shuffle from '@intercom/pulse/lib/shuffle';

export default class AppsAppEmailEditorDemoController extends Controller {
  @service appService;
  isEditing = true;

  @action setTemplate(templateName, templateType) {
    this.model.emailTemplateType = emailTemplateTypes[templateType];
    if (templateName) {
      this.model.emailTemplateId = premadeTemplates[templateName];
    } else {
      this.model.emailTemplateId = shuffle(
        this.appService.app.email_templates.toArray().filterBy('isCustom'),
      ).firstObject.id;
    }
  }

  @action changeToCustomEmailSender() {
    this.model.senderType = senderTypes.customEmailAddress;
    this.model.senderId = shuffle(
      this.appService.app.customEmailAddresses.toArray(),
    ).firstObject.id;
  }

  @action changeToAdminSender() {
    this.model.senderType = senderTypes.admin;
    this.model.senderId = this.appService.app.currentAdmin.id;
  }
}
