/* RESPONSIBLE TEAM: team-proactive-support */
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import ReportBase from 'embercom/controllers/apps/app/reports/base/report';
import { pluralHumanReadableObjectName } from 'embercom/helpers/matching-system/plural-human-readable-object-name';
import { statsSystemObjectNames } from 'embercom/models/data/matching-system/matching-constants';
import { capitalize } from '@ember/string';

export default class OutboundEngagementOverviewReport extends ReportBase {
  @service modalService;
  @service outboundHomeService;
  @service reportingService;
  @service intercomEventService;
  @service permissionsService;
  @service appService;
  @service intl;

  @tracked showCsvExportModal;
  @tracked selectedContentTypeIds = [];

  get app() {
    return this.appService.app;
  }

  @action
  async openExportModal() {
    if (this.permissionsService.currentAdminCan('can_export_csv')) {
      this.showCsvExportModal = true;
    } else {
      this.permissionsService.loadAllAdminsAndShowPermissionRequestModal('can_export_csv');
    }
  }

  @action
  updateContentTypeFilter(_, contentTypeIds) {
    this.selectedContentTypeIds = contentTypeIds;
    this.reportingService.updateContentTypeIds();
    let params = {
      action: 'filtered_by_content_type',
      object: this.reportName,
      content_types: contentTypeIds,
    };
    this.intercomEventService.trackAnalyticsEvent(params);
  }

  @action
  updateRange(range) {
    this.reportingService.updateRange(range);

    let params = {
      action: 'filtered_by_date',
      object: this.reportName,
      date_filter: range.selectedRange,
      start_date: range.startMoment.format('YYYY/MM/DD'),
      end_date: range.endMoment.format('YYYY/MM/DD'),
    };
    this.intercomEventService.trackAnalyticsEvent(params);
  }

  get messagesSentQuery() {
    return {
      name: 'default_query',
      debug_name: 'outbound_messages_sent',
      document_type: 'events',
      range_field: 'event.created_at',
      aggregation_type: 'cardinality',
      aggregation_field: 'stat_receipt.ruleset_id',
      filters: {
        'event.type': 'stats_receipt',
        'stat_receipt.content_type': this._contentTypesForQuery(),
      },
    };
  }

  get timesMessagesSentQuery() {
    return {
      ...this.messagesSentQuery,
      debug_name: 'outbound_times_messages_sent',
      aggregation_type: 'value_count',
    };
  }

  get contentTitle() {
    if (this.selectedContentTypeIds.length === 0) {
      return this.intl.t(
        'reporting.customer-engagement.overview.content-type-filter-title.all-content-types',
      );
    }

    let objectNames = this.selectedContentTypeIds.map((contentTypeId) =>
      pluralHumanReadableObjectName(contentTypeId, this.intl),
    );

    return capitalize(this.intl.formatList(objectNames, { type: 'conjunction' }));
  }

  _contentTypesForQuery() {
    if (this.selectedContentTypeIds.length > 0) {
      return this.selectedContentTypeIds.map((type) => statsSystemObjectNames[type]);
    } else {
      return this.outboundHomeService.allObjectTypes.map((type) => statsSystemObjectNames[type]);
    }
  }
}
