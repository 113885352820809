/* RESPONSIBLE TEAM: team-pricing-and-packaging */

import type Store from '@ember-data/store';
import Controller from '@ember/controller';
import { action } from '@ember/object';
import { restartableTask } from 'ember-concurrency-decorators';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import type Price from 'embercom/models/price';

export default class MigrationController extends Controller {
  queryParams = ['tab'];

  @service declare intercomEventService: $TSFixMe;
  @service declare appService: $TSFixMe;
  @service declare store: Store;

  @tracked declare tab: string;
  // @ts-ignore
  @tracked pricing5Estimates = this.model.pricing5Estimates;
  // @ts-ignore
  @tracked pricing5EstimatesForEditPackage = this.model.pricing5EstimatesForEditPackage;

  tabContainerClass = 'stripe-migrations-header';

  @action
  changeTab(tab: string) {
    this.tab = tab;

    this.intercomEventService.trackAnalyticsEvent({
      action: 'clicked',
      context: 'stripe_migrations',
      place: tab,
      object: 'tab_changes',
    });

    document.querySelector(`.${this.tabContainerClass}`)?.scrollTo({ top: 0, behavior: 'smooth' });
  }

  @restartableTask
  *onPackageConfirmation(): Generator<Promise<Array<Price>>, void, unknown> {
    let newEstimates = yield this.fetchNewEstimatesWithUpdatedSeatCounts();

    this.pricing5Estimates = newEstimates;
  }

  @action
  async onMigrationSeatConfigurationSave(seatCounts: {
    core_seat_count: number;
    copilot_seat_count: number;
  }) {
    let newEstimates = await this.fetchNewEstimatesWithUpdatedSeatCounts(seatCounts);

    this.pricing5EstimatesForEditPackage = newEstimates;
  }

  private async fetchNewEstimatesWithUpdatedSeatCounts(seatCounts?: {
    core_seat_count: number;
    copilot_seat_count: number;
  }): Promise<Array<Price>> {
    let body: {
      app_id: string;
      core_seat_count?: number;
      copilot_seat_count?: number;
    } = {
      app_id: this.appService.app.id,
    };

    if (seatCounts) {
      body.core_seat_count = seatCounts.core_seat_count;
      body.copilot_seat_count = seatCounts.copilot_seat_count;
    }

    let estimates = await this.store.query('migration-estimate', body);

    return estimates.toArray();
  }
}
