/* RESPONSIBLE TEAM: team-proactive-support */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable ember/no-controllers */
/* eslint-disable ember/no-classic-classes */
import { readOnly } from '@ember/object/computed';
import Controller from '@ember/controller';
import moment from 'moment-timezone';
export default Controller.extend({
  calendar: readOnly('model.calendar'),
  duration: readOnly('model.duration'),
  endUserTimezone: {
    timezone: moment.tz.guess(true),
    offset: moment().format('Z'),
  },
});
