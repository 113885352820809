/* RESPONSIBLE TEAM: team-frontend-tech */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable ember/no-new-mixins */
import { equal, not, or, and } from '@ember/object/computed';
import { on } from '@ember/object/evented';
import Mixin from '@ember/object/mixin';
import { greaterThanProperty, lessThanProperty } from '@intercom/pulse/lib/computed-properties';

let NumberPagination = Mixin.create({
  numberPaginationSetup: on('init', function () {
    this.setProperties({
      page: 1,
      isLoading: false,
      isPaginating: false,
      hasErrored: false,
    });
  }),
  reload() {
    if (this.isDestroying) {
      return;
    }
    let isSelectAllMode = this.isSelectAllMode;
    this.trigger('reset');
    this.loadPage(1);
    this.setProperties({
      isLoading: true,
      isSelectAllMode,
    });
  },
  finishedLoading() {
    this.setProperties({
      isSorting: false,
      isLoading: false,
      isPaginating: false,
      hasErrored: false,
    });
  },
  hasLargeCount: equal('totalCount', undefined),
  isNotPaginating: not('isPaginating'),
  allLoaded: greaterThanProperty('totalCount', 'model.length'),
  allLoadedOrCountUnavailable: or('allLoaded', 'hasLargeCount'),
  shouldPageWithPageInformation: and('shouldPageWhenNotPagintatingOrUnavailable', 'hasMorePages'),
  shouldPageWithoutPageInformation: and(
    'shouldPageWhenNotPagintatingOrUnavailable',
    'hasNoTotalPages',
  ),
  shouldPageWhenNotPagintatingOrUnavailable: and('isNotPaginating', 'allLoadedOrCountUnavailable'),
  shouldPage: or('shouldPageWithoutPageInformation', 'shouldPageWithPageInformation'),
  hasNoTotalPages: equal('totalPages', undefined),
  hasMorePages: lessThanProperty('page', 'totalPages'),
  actions: {
    nextPage() {
      if (this.shouldPage) {
        return this.loadPage(this.page + 1);
      }
    },
  },
  loadPage() {}, //implement this in your class
});

export default NumberPagination;
