/* RESPONSIBLE TEAM: team-channels */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable ember/no-actions-hash */
/* eslint-disable promise/prefer-await-to-then */
/* eslint-disable ember/no-controllers */
import { empty, alias, and, not, or } from '@ember/object/computed';
import { notEqualToProperty } from '@intercom/pulse/lib/computed-properties';
import AccountControllerBase from 'embercom/controllers/apps/app/account/base/account-controller-base';

import { inject as service } from '@ember/service';
import ajax from 'embercom/lib/ajax';
import Admin from 'embercom/models/admin';

export default AccountControllerBase.extend({
  store: service(),
  notificationsService: service(),
  appService: service(),
  intl: service(),
  intercomConfirmService: service(),
  emailHasChanged: notEqualToProperty('admin.email', 'newAdminEmail'),

  passwordIsBlank: empty('password'),
  requiresPasswordForSave: alias('admin.has_password'),
  passwordIsBlankAndRequired: and('passwordIsBlank', 'requiresPasswordForSave'),

  samlOnlyWorkspace: not('admin.canChangeEmail'),
  samlNotEnabled: not('appService.app.saml_enabled'),

  canChangeEmail: and('admin.canChangeEmail', 'samlNotEnabled'),

  saveDisabled: or(
    'samlOnlyWorkspace',
    'passwordIsBlankAndRequired',
    'isSaving',
    'appService.app.saml_enabled',
  ),

  isSaving: false,
  isEmailResending: false,
  isEmailCanceling: false,

  resetToCurrentAdminData() {
    this.admin.getTwoFactorQRCodeSVG();
    this.setProperties({
      newAdminEmail: this.get('admin.email'),
    });
  },

  hasUnsavedChanges() {
    return this.emailHasChanged;
  },

  revertUnsavedChanges() {
    this.resetToCurrentAdminData();
  },

  actions: {
    save() {
      this.set('isSaving', true);
      this.admin
        .updatePersonalSettings({
          id: this.get('admin.id'),
          app_id: this.get('app.id'),
          email: this.newAdminEmail,
          current_password: this.password,
        })
        .then((updatedAdmin) => {
          this.notificationsService.notifyConfirmation(
            this.intl.t('account.general.settings-updated'),
          );
          this.setProperties({
            password: null,
            isSaving: false,
            'app.currentAdmin': Admin.peekAndMaybeLoad(this.store, updatedAdmin.id),
          });
        })
        .catch((response) => {
          this.set('isSaving', false);
          if (response.jqXHR.status === 401) {
            this.notificationsService.notifyError(
              this.intl.t('account.general.password-incorrect'),
            );
          } else if (response.jqXHR.status === 422) {
            this.notificationsService.notifyError(
              this.intl.t('account.general.cannot-update-profile', {
                errorMessage: JSON.parse(response.jqXHR.responseText).errors,
              }),
            );
          } else {
            this.notificationsService.notifyError(
              this.intl.t('account.general.cannot-update-profile-try-again'),
            );
          }
        });
    },

    async disable2FA() {
      let confirmOptions = {
        title: this.intl.t('account.general.disable-2fa'),
        body: this.intl.t('account.general.are-you-sure-disable-2fa'),
        confirmButtonText: this.intl.t('account.general.disable-2fa'),
      };
      let isConfirmed = await this.intercomConfirmService.confirm(confirmOptions);
      if (!isConfirmed) {
        return;
      }
      ajax({
        type: 'PUT',
        url: '/ember/admins/disable_two_factor_auth',
        data: JSON.stringify({
          id: this.get('admin.id'),
          app_id: this.get('admin.current_app_id'),
        }),
      }).then(
        () => {
          this.notificationsService.notifyConfirmation(
            this.intl.t('account.general.2fa-was-disabled'),
          );
          this.set('admin.has_enabled_2fa', false);
        },
        () => {
          this.notificationsService.notifyError(
            this.intl.t('account.general.cannot-update-2fa-try-again'),
          );
        },
      );
    },

    adminAvatarChange() {
      this.notificationsService.notifyConfirmation(this.intl.t('account.general.photo-updated'));
    },
    resendValidationEmail() {
      this.set('isEmailResending', true);
      return ajax({
        type: 'PUT',
        url: '/ember/admins/resend_verification_email',
        data: JSON.stringify({
          id: this.get('admin.id'),
          app_id: this.get('admin.current_app_id'),
        }),
      }).then(
        function () {
          this.set('isEmailResending', false);
          this.notificationsService.notifyConfirmation(this.intl.t('account.general.email-resend'));
        }.bind(this),
        function ({ jqXHR }) {
          this.set('isEmailResending', false);
          if (jqXHR && jqXHR.status === 422) {
            this.notificationsService.notifyError(jqXHR.responseJSON.errors);
          } else {
            this.notificationsService.notifyError(
              this.intl.t('account.general.cannot-send-verification-email-try-again'),
            );
          }
        }.bind(this),
      );
    },
    cancelValidationEmail() {
      this.set('isEmailCanceling', true);
      return ajax({
        type: 'PUT',
        url: '/ember/admins/cancel_verification_email',
        data: JSON.stringify({
          id: this.get('admin.id'),
          app_id: this.get('admin.current_app_id'),
        }),
      }).then(
        function () {
          this.set('isEmailCanceling', false);
          this.notificationsService.notifyConfirmation(
            this.intl.t('account.general.pending-email-change-canceled'),
          );
          this.set('admin.pending_email_change', null);
        }.bind(this),
        function ({ jqXHR }) {
          this.set('isEmailCanceling', false);
          if (jqXHR && jqXHR.status === 422) {
            this.notificationsService.notifyError(jqXHR.responseJSON.errors);
          } else {
            this.notificationsService.notifyError(
              this.intl.t('account.general.cannot-cancel-try-again'),
            );
          }
        }.bind(this),
      );
    },
  },
});
