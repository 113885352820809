/* RESPONSIBLE TEAM: team-proactive-support */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable ember/no-new-mixins */
import { A } from '@ember/array';
import Mixin from '@ember/object/mixin';
import { isPresent } from '@ember/utils';
import { task } from 'ember-concurrency';
import { ternaryToProperty } from '@intercom/pulse/lib/computed-properties';
import { inject as service } from '@ember/service';

export default Mixin.create({
  store: service(),
  isLoading: ternaryToProperty('localSortEnabled', 'query.isLoading', 'queryTask.isRunning'),

  _changeSortDirection() {
    let alternateSortDirection = this.alternateSortingDirection;
    this.set('sort_direction', alternateSortDirection);
    if (this.localSortEnabled) {
      this.set('query.sort_direction', alternateSortDirection);
    }
  },

  _requestPayload() {
    let payload = {
      type: this.type,
      state: this.state,
      sort_by: this.sort_by,
      sort_direction: this.sort_direction,
      page: this.pageCount,
      per_page: 25,
    };
    if (this.search_term) {
      payload.search_term = this.search_term;
    }
    if (this.admin_id) {
      payload.from_id = this.admin_id;
    }
    if (this.inFolderView && this.folderId) {
      payload.message_group_id = this.folderId;
    }
    if (this.created_via) {
      payload.created_via = this.created_via;
    }
    return payload;
  },

  nextPageTask: task(function* () {
    if (this.hasNotLoadedAllRemoteData) {
      this.incrementProperty('pageCount');
      yield this.queryTask.perform();
    }
  }).drop(),

  queryTask: task(function* () {
    let nextPageOfMessages = yield this.store.query('message', this._requestPayload());
    this.set('totalMessages', nextPageOfMessages.meta.total_count);
    this.model.pushObjects(nextPageOfMessages.toArray());
  }).drop(),

  localSort(columnName) {
    this.model.sortBy(`${columnName}:${this.sort_direction}`);
  },

  sortTask: task(function* (columnName) {
    this.set('sortableStat', null);
    if (this.sort_by === columnName) {
      this._changeSortDirection();
    } else {
      this.setProperties({
        sort_by: columnName,
        sort_direction: 'desc',
      });
    }
    this.setProperties({
      model: A(),
      pageCount: 1,
    });
    yield this.queryTask.perform();
  }),

  updateQueryParams(searchItem) {
    let fromId = searchItem.get('id');
    if (fromId) {
      this.setProperties({
        admin_id: fromId,
        search_term: '',
      });
    } else if (isPresent(searchItem.name)) {
      this.setProperties({
        admin_id: '',
        search_term: searchItem.name,
      });
    } else {
      this.setProperties({
        admin_id: '',
        search_term: '',
      });
    }
  },

  searchTask: task(function* () {
    this.setProperties({
      pageCount: 1,
      model: [],
    });
    yield this.queryTask.perform();
  }).restartable(),

  actions: {
    doSearch(searchItem) {
      this.updateQueryParams(searchItem);
      this.searchTask.perform();
    },
    resetSearch() {
      this.setProperties({
        search_term: '',
        admin_id: '',
      });
      this.searchTask.perform();
    },
  },
});
