/* RESPONSIBLE TEAM: team-data-interop */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
/* eslint-disable ember/no-actions-hash */
/* eslint-disable ember/no-controllers */
/* eslint-disable ember/no-classic-classes */
import Controller from '@ember/controller';
import { inject as service } from '@ember/service';
import { alias, readOnly } from '@ember/object/computed';
import { task } from 'ember-concurrency';

export default Controller.extend({
  intercomEventService: service(),
  developerHub: service(),
  notificationsService: service(),
  appPackage: readOnly('developerHub.appPackage'),
  isOnEdit: alias('developerHub.isOnEditPage'),
  isSetLiveMode: false,
  addAnalyticsEvent(action, ping_outcome) {
    this.intercomEventService.trackAnalyticsEvent({
      object: 'app_package',
      app_package_id: this.appPackage.id,
      app_metadata: this.appPackage.webhookDefinition.topics,
      action,
      ping_outcome,
    });
  },

  isWebhookDefinitionInvalid: task(function* () {
    let { validations } = yield this.get('appPackage.webhookDefinition').validate();
    return (
      validations.get('isInvalid') || this.get('appPackage.webhookDefinition.missingRequiredScopes')
    );
  }),
  saveWebhookTask: task(function* () {
    try {
      let isInvalid = yield this.isWebhookDefinitionInvalid.perform();
      if (isInvalid) {
        this.notificationsService.notifyError('There was a problem applying your changes.');
        return;
      }

      yield this.appPackage.updateWebhooks();
      yield this.appPackage.save();
      this.transitionToRoute('apps.app.developer-hub.app-packages.app-package.webhooks');
    } catch (e) {
      let statusCode = e.jqXHR.statusCode;
      if (statusCode >= 400 && statusCode < 500) {
        let errorText = e.jqXHR.responseText;
        return this.notificationsService.notifyError(errorText);
      }
      this.notificationsService.notifyError('There was a problem applying your changes.');
      return;
    }
    try {
      yield this.appPackage.pingWebhookDefinition();
      this.addAnalyticsEvent('saved', 'success');
      this.notificationsService.notifyConfirmation(
        'Your webhook settings were successfully updated and a test request was sent successfully',
      );
    } catch (e) {
      this.addAnalyticsEvent('saved', 'failed');
      this.notificationsService.notifyError(
        'Unsuccessful test request. Please check that your Endpoint URL can receive notifications and check for errors.',
      );
    }
  }),

  actions: {
    cancelEdit() {
      this.appPackage.rollbackAttributes();
      this.addAnalyticsEvent('cancelled');
      this.transitionToRoute('apps.app.developer-hub.app-packages.app-package.webhooks');
    },
    async setWebhookAsLiveTask() {
      try {
        this.set('isSetLiveMode', true);
        await this.appPackage.webhookDefinition.setlive();
        await this.appPackage.updateWebhooks();
        this.set('isSetLiveMode', false);

        this.addAnalyticsEvent('setlive', 'success');
        this.notificationsService.notifyConfirmation('Your subscription has been set live.');
      } catch (e) {
        await this.appPackage.webhookDefinition.rollbackSetlive();

        this.addAnalyticsEvent('setlive', 'failed');
        this.notificationsService.notifyError(
          "We couldn't set your subscription live. Please, try again",
        );
      }
    },
  },
});
