/* RESPONSIBLE TEAM: team-knowledge-and-data-setup */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable ember/no-actions-hash */
/* eslint-disable ember/no-controllers */
/* eslint-disable ember/no-classic-classes */
import { equal, alias } from '@ember/object/computed';
import Controller, { inject as controller } from '@ember/controller';
import { inject as service } from '@ember/service';
import Paging from 'embercom/components/mixins/paging';

export default Controller.extend(Paging, {
  intercomEventService: service(),
  hasNoInsights: equal('model.conversations', []),
  totalCount: alias('model.conversations.length'),
  appController: controller('apps/app'),
  app: alias('appController.model'),
  currentAdmin: alias('app.currentAdmin'),
  actions: {
    refreshRelativeTime() {},
    partAddedToConversation() {},
    trackAdminReplyEvent() {},
    nextConversationPage() {},

    trackEventDetails(conversation) {
      this.intercomEventService.trackAnalyticsEvent({
        action: 'expanded',
        object: 'conversation',
        place: 'insights',
        owner: 'educate',
        context: 'conversation_closed_by_operator',
        conversation_id: conversation.get('id'),
      });
    },
  },
});
