/* RESPONSIBLE TEAM: team-pricing-and-packaging */
import Controller from '@ember/controller';
import { action } from '@ember/object';
import { captureException } from 'embercom/lib/sentry';
import { task } from 'ember-concurrency-decorators';
import ajax from 'embercom/lib/ajax';
import { inject as service } from '@ember/service';
import type Store from '@ember-data/store';
import type Router from '@ember/routing/router-service';
import type IntlService from 'ember-intl/services/intl';
import { taskFor } from 'ember-concurrency-ts';

export default class BillingIndexController extends Controller {
  @service declare store: Store;
  @service declare appService: $TSFixMe;
  @service declare customerService: $TSFixMe;
  @service declare notificationsService: $TSFixMe;
  @service declare router: Router;
  @service declare intl: IntlService;

  queryParams = [];

  get featuresLost() {
    return [
      this.intl.t('new-settings.workspace.billing.cancel.confirm.lost-feature-1'),
      this.intl.t('new-settings.workspace.billing.cancel.confirm.lost-feature-2'),
      this.intl.t('new-settings.workspace.billing.cancel.confirm.lost-feature-3'),
    ];
  }

  @task *cancelSubscriptionTask(): Generator<Promise<void>> {
    try {
      let newCustomer = yield ajax({
        url: `/ember/customers/${this.customerService.customer.id}/cancel_subscription`,
        type: 'POST',
        data: JSON.stringify({
          app_id: this.appService.app.id,
        }),
      });
      return this.store.pushPayload('billing/customer', {
        'billing/customer': newCustomer,
      });
    } catch (err) {
      this.notificationsService.notifyError(
        this.intl.t('new-settings.workspace.billing.cancel.confirm.error'),
      );
      captureException(err, {
        'responsible-team': 'team-monetization',
      });
      throw err;
    }
  }

  get isCancelSubscriptionRunning() {
    return taskFor(this.cancelSubscriptionTask).isRunning;
  }

  set isCancelSubscriptionRunning(_value: $TSFixMe) {
    // no-op
  }

  @action
  async cancelSubscription() {
    await taskFor(this.cancelSubscriptionTask).perform();
    this.router.transitionTo('apps.app.settings.workspace.billing.cancel.complete');
  }
}
