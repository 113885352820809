/* RESPONSIBLE TEAM: team-product-exploration */
import Controller from '@ember/controller';
import { inject as service } from '@ember/service';

export default class NewSettingsController extends Controller {
  queryParams = [];

  @service declare navbarCollapsingService: $TSFixMe;
  @service declare appService: $TSFixMe;

  get app() {
    return this.appService.app;
  }
}
