/* RESPONSIBLE TEAM: team-customer-data-platform */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable ember/no-controllers */
import { reads, alias, filterBy } from '@ember/object/computed';
import { inject as controller } from '@ember/controller';
import UserOrCompaniesController from 'embercom/controllers/base/user-or-companies';

export default UserOrCompaniesController.extend({
  segmentController: controller('apps/app/users/segments/segment'),
  appController: controller('apps/app'),
  appsController: controller('apps'),
  app: alias('appController.model'),
  admin: alias('appsController.model'),
  segment: reads('segmentController.model'),
  segmentRoutePath: 'apps.app.users.segments.segment',
  isCompany: false,
  userSegments: filterBy('app.segments', 'isUser'),
});
