/* RESPONSIBLE TEAM: team-pricing-and-packaging */
import Controller from '@ember/controller';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import { type Router } from '@ember/routing';
import type CardlessTrialService from 'embercom/services/cardless-trial-service';

export default class BillingIndexController extends Controller {
  @service declare appService: $TSFixMe;
  @service declare paywallService: $TSFixMe;
  @service declare router: Router;
  @service declare customerService: $TSFixMe;
  @service declare cardlessTrialService: CardlessTrialService;

  queryParams = [];

  @tracked showPaywall = false;

  get app() {
    return this.appService.app;
  }

  get showBillingEarlyStageRejection() {
    return (
      (this.cardlessTrialService.canUseEarlyStageDetection || this.app.onPricing5) &&
      this.app.earlyStageApplicationRejected &&
      (this.customerService.customer?.inCardlessTrial ||
        (this.customerService.customer?.inExpiredCardlessTrial && this.app.hasNoActiveSubscription))
    );
  }

  @action openPaywallOrRedirect() {
    // For Early Stage customers, or customers who are not on a core VBP or core P5 product, redirect to the checkout page
    if (this.app.validEarlyStageApplicant || !this.app.coreProduct) {
      this.paywallService.redirectToPurchase();
    } else {
      this.showPaywall = true;
    }
  }

  @action closePaywall() {
    this.showPaywall = false;
    if (this.app.hasActiveSubscription) {
      this.router.transitionTo('apps.app.settings.workspace.billing.summary.index');
    }
  }
}
