/* RESPONSIBLE TEAM: team-ml */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable ember/no-actions-hash */
/* eslint-disable ember/no-controllers */
/* eslint-disable ember/no-classic-classes */
import { inject as service } from '@ember/service';
import Controller from '@ember/controller';

export default Controller.extend({
  intercomEventService: service(),
  actions: {
    transitionToInstructions() {
      this.intercomEventService.trackAnalyticsEvent({
        action: 'clicked',
        object: 'get_started_button',
        context: 'zendesk_chat_import',
      });
      this.transitionToRoute('apps.app.import.zendesk-chat.authorize');
    },
  },
});
