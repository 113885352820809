/* RESPONSIBLE TEAM: team-pricing-and-packaging */
import { inject as service } from '@ember/service';
import Controller from '@ember/controller';
import { action } from '@ember/object';
import { taskFor } from 'ember-concurrency-ts';
import { allSettled, type TaskGenerator } from 'ember-concurrency';
import { dropTask } from 'ember-concurrency-decorators';
import ENV from 'embercom/config/environment';
import { captureException } from 'embercom/lib/sentry';
import { formatList } from 'embercom/helpers/format-list-helper';
import type Router from '@ember/routing/router-service';
import type IntlService from 'ember-intl/services/intl';

export default class BulkTeammatesRemoveController extends Controller {
  @service declare appService: $TSFixMe;
  @service declare notificationsService: $TSFixMe;
  @service declare intercomEventService: $TSFixMe;
  @service declare intl: IntlService;
  @service declare router: Router;

  declare selectionIsValid: boolean;
  declare model: $TSFixMe;

  queryParams = [''];

  @action
  removeTeammates() {
    return taskFor(this.removeTeammatesTask).perform();
  }

  @dropTask
  *removeTeammatesTask(): TaskGenerator<void> {
    try {
      let results = yield allSettled(this._destroyTeammates());
      if (results.every((result: $TSFixMe) => result.state === 'fulfilled')) {
        this.notificationsService.notifyConfirmation(
          this.intl.t('settings.teammates.remove.successful-removal-message', {
            appName: this.appService.app.name,
          }),
        );
        this._fireBulkDeleteAnalyticsEvent({ isSuccessful: true });
        this.router.transitionTo('apps.app.settings.workspace.teammates');
      } else {
        this._fireBulkDeleteAnalyticsEvent({ isSuccessful: false });
        this._handleBulkDeleteFailures(results);
      }
    } catch (error) {
      this._fireBulkDeleteAnalyticsEvent({ isSuccessful: false });
      this.notificationsService.notifyError(
        this.intl.t('settings.teammates.remove.unexpected-failure-message'),
      );
      captureException(error, { extra: { 'responsible-team': 'team-pricing-and-packaging' } });
    }
  }

  _destroyTeammates() {
    let { teammates, reassignments } = this.model;

    let deletionPromises = teammates.map(async (teammate: $TSFixMe) => {
      if (teammate.isDestroyed) {
        return;
      }
      return Promise.all([
        await teammate.currentAppPermissions.destroyRecord({ adapterOptions: { reassignments } }),
        this.appService.app.admins.removeObject(teammate),
        teammate.unloadRecord(),
      ]);
    });

    return deletionPromises;
  }

  _handleBulkDeleteFailures(results: $TSFixMe) {
    let failedTeammateNames: $TSFixMe = [];
    results.forEach((result: $TSFixMe, index: number) => {
      if (result.state !== 'fulfilled') {
        failedTeammateNames.push(this.model.teammates[index].displayAs);
      }
    });
    this.notificationsService.notifyError(
      this.intl.t('settings.teammates.remove.partial-failure-message', {
        failedTeammateCount: failedTeammateNames.length,
        failedTeammateNames: formatList([failedTeammateNames], { type: 'conjunction' }),
      }),
      ENV.APP.notificationNoTimeout,
    );
  }

  _fireBulkDeleteAnalyticsEvent({ isSuccessful }: { isSuccessful: boolean }) {
    this.intercomEventService.trackAnalyticsEvent({
      action: 'clicked',
      object: 'remove_teammates_button',
      deleted_teammates_count: this.model.teammates.length,
      is_successful: isSuccessful,
    });
  }
}
