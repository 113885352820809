/* RESPONSIBLE TEAM: team-workflows */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable ember/no-actions-hash */
/* eslint-disable ember/require-computed-property-dependencies */
/* eslint-disable ember/no-controllers */
/* eslint-disable ember/no-classic-classes */
import Controller, { inject as controller } from '@ember/controller';
import { computed } from '@ember/object';
import { readOnly } from '@ember/object/computed';
import { inject as service } from '@ember/service';
import { task } from 'ember-concurrency';
import ajax from 'embercom/lib/ajax';

const TASK_NAME_MODEL_MAP = {
  'team-availability-users': 'teamAvailability',
  'team-availability-leads': 'teamAvailability',
  'conversation-routing': 'conversationRouting',
  'conversation-rating': 'conversationRating',
  'conversation-rating-users': 'conversationRating',
  'conversation-rating-leads': 'conversationRating',
  'article-suggestions-users': 'articleSuggestions',
  'article-suggestions-leads': 'articleSuggestions',
  'article-auto-reaction-leads': 'articleAutoReaction',
  'article-auto-reaction-users': 'articleAutoReaction',
  'qualify-leads': 'qualifyLeads',
  'contact-collection': 'contactCollection',
};

export default Controller.extend({
  intercomEventService: service(),
  permissionsService: service(),
  intercomConfirmService: service(),
  appService: service(),
  intl: service(),
  app: readOnly('appService.app'),
  operatorController: controller('apps/app/operator'),
  settings: readOnly('model'),
  previewConfiguration: readOnly('operatorController.previewConfiguration'),
  groupedTasks: [
    [
      'team-availability',
      'conversation-routing',
      'contact-collection',
      'qualify-leads',
      'article-suggestions-leads',
    ],
    ['article-auto-reaction'],
    ['conversation-rating'],
  ],

  updateLeadDelayEnabled: task(function* () {
    try {
      yield this.permissionsService.checkPermission('can_create_and_edit_bots');
    } catch (e) {
      return;
    }
    this.toggleProperty('settings.leadDelayEnabled');
    yield ajax({
      url: `/ember/operator_settings/lead_delay/${this.get('settings.id')}`,
      type: 'PUT',
      data: JSON.stringify({
        lead_delay_enabled: this.get('settings.leadDelayEnabled'),
      }),
    });
  }).drop(),

  updateUserDelayEnabled: task(function* () {
    try {
      yield this.permissionsService.checkPermission('can_create_and_edit_bots');
    } catch (e) {
      return;
    }
    this.toggleProperty('settings.userDelayEnabled');
    yield ajax({
      url: `/ember/operator_settings/user_delay/${this.get('settings.id')}`,
      type: 'PUT',
      data: JSON.stringify({
        user_delay_enabled: this.get('settings.userDelayEnabled'),
      }),
    });
  }).drop(),

  previewableTasks: computed('openSectionName', function () {
    let currentTask = this.openSectionName;
    let taskGroups = this.groupedTasks;
    if (currentTask === undefined) {
      return taskGroups[0];
    }
    let currentGroup = taskGroups.find((group) => group.includes(currentTask));
    let currentTaskIndex = currentGroup.indexOf(currentTask);
    return currentGroup.slice(0, currentTaskIndex + 1);
  }),

  sectionCanClose: task(function* () {
    if (!this.currentTaskHasChanged()) {
      return true;
    }
    let confirmed = yield this.intercomConfirmService.confirm({
      title: this.intl.t('operator.task-bots.users.confirm-not-saved.title'),
      primaryButtonType: 'primary-destructive',
      confirmButtonText: this.intl.t('operator.task-bots.users.confirm-not-saved.confirm-button'),
      cancelButtonText: this.intl.t('operator.task-bots.users.confirm-not-saved.cancel-button'),
      body: this.intl.t('operator.task-bots.users.confirm-not-saved.body'),
    });
    if (confirmed) {
      this.currentTask.refresh();
      return true;
    } else {
      return false;
    }
  }),

  currentTaskHasChanged() {
    let currentTask = this.currentTask;
    return currentTask && !currentTask.get('isNotDirty');
  },

  currentTask: computed('openSectionName', function () {
    let taskName = TASK_NAME_MODEL_MAP[this.openSectionName];
    return this.get(`settings.${taskName}`);
  }),

  actions: {
    sectionDidClose() {
      this.set('section', null);
      this.set('openSectionName', undefined);
    },
    sectionDidOpen(sectionName) {
      this.set('section', sectionName);
      this.set('openSectionName', sectionName);
    },
  },
});
