/* RESPONSIBLE TEAM: team-workflows */
import TaskBotBase from 'embercom/controllers/apps/app/operator/task-bots';

export default TaskBotBase.extend({
  queryParams: ['section'],
  groupedTasks: [
    ['upfront-collection-users', 'team-availability-users', 'article-suggestions-users'],
    ['article-auto-reaction-users'],
    ['conversation-rating-users'],
  ],
});
