/* RESPONSIBLE TEAM: team-help-desk-experience */
import Controller from '@ember/controller';
import { tracked } from '@glimmer/tracking';
import { OnboardingType } from 'embercom/lib/inbox2/onboarding/steps';

export default class OnboardingController extends Controller {
  queryParams = ['type', 'from'];

  @tracked type: OnboardingType = OnboardingType.Default;

  // Where the user was redirected from.
  @tracked from = 'inbox';
}
