/* RESPONSIBLE TEAM: team-ml */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable ember/no-controllers */
/* eslint-disable ember/no-classic-classes */
import { readOnly, or } from '@ember/object/computed';
import Controller, { inject as controller } from '@ember/controller';
import { inject as service } from '@ember/service';
import { task } from 'ember-concurrency';
import { later } from '@ember/runloop';

export default Controller.extend({
  store: service(),
  intercomEventService: service(),
  notificationsService: service(),
  appController: controller('apps.app'),
  app: readOnly('appController.model'),
  zendeskChatController: controller('apps.app.import.zendesk-chat'),
  origin: readOnly('zendeskChatController.origin'),
  importerConfiguration: readOnly('zendeskChatController.importerConfiguration'),

  conversationImportCredentialsService: service(),
  showErrorMessage: or('authWindowError', 'tokenRetrievalError'),
  authWindowError: false,
  tokenRetrievalError: readOnly('conversationImportCredentialsService.tokenRetrievalError'),

  authorize: task(function* (clientId, clientSecret, subdomain) {
    this.intercomEventService.trackAnalyticsEvent({
      action: 'clicked',
      object: 'save_credentials_button',
      context: 'zendesk_chat_import',
    });

    this.set('authWindowError', false);

    let conversationImport = this.store.createRecord('conversation-import', {
      appId: this.get('app.id'),
      origin: this.origin,
      client_id: clientId,
      client_secret: clientSecret,
      subdomain,
    });

    try {
      let authorizationWindow =
        yield this.conversationImportCredentialsService.authorize(conversationImport);
      if (authorizationWindow) {
        this._waitForZendeskChat(authorizationWindow);
      }
    } catch (error) {
      this.notificationsService.notifyError(error);
    }
  }),

  _waitForZendeskChat(authorizeWindow) {
    if (!authorizeWindow.closed) {
      if (!this.authWindowError) {
        this._checkForErrors(authorizeWindow.location);
      }
      later(() => this._waitForZendeskChat(authorizeWindow), 200);
    }
  },

  _checkForErrors(url) {
    let searchParams = new URLSearchParams(url.search);
    if (searchParams.get('error')) {
      this.set('authWindowError', true);
    }
  },
});
