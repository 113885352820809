/* RESPONSIBLE TEAM: team-knowledge-and-data-setup */
import Controller from '@ember/controller';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import EmberObject from '@ember/object';
import RouteRegexes from 'embercom/lib/route-regexes';
import type Router from '@ember/routing/router-service';
import { tracked } from '@glimmer/tracking';
import { MediaHelper, UploadHelper } from 'embercom/objects/media/media-helper';
import { FILE_SIZE_VALIDATOR } from 'embercom/components/articles/site/settings/upload-button';
import type IntlService from 'ember-intl/services/intl';

const MAX_IMG_SIZES = {
  header: 3000,
  logo: 800,
  footerLogo: 720,
  favicon: 588,
  social: 1300,
  collection_card: 3000,
  contentBlock: 3000,
};

export class TlsSelectionHelper extends EmberObject {
  @tracked currentSelection = 0;
  initialSelection = 0;

  initialize(newSelection: number): void {
    this.initialSelection = newSelection;
    this.currentSelection = newSelection;
  }

  reset(): void {
    this.currentSelection = this.initialSelection;
  }

  get hasUnsavedChanges(): boolean {
    return this.currentSelection !== this.initialSelection;
  }
}

export default class WorkspaceHelpcenter extends Controller {
  @service declare router: Router;
  @service declare intl: IntlService;
  @service declare helpCenterService: $TSFixMe;

  @tracked uploadHelper = this._createUploadHelper();
  @tracked tlsSelectionHelper = TlsSelectionHelper.create();
  @tracked createNewCollectionAction: any;
  @tracked isSaveRunning = false;
  queryParams = [];

  get isSaveEnabled() {
    return (
      !this.isSaveRunning &&
      (this.helpCenterService.site.hasUnsavedChanges || this.uploadHelper.hasUpdates)
    );
  }

  @action
  toggleIsSaveRunning() {
    this.isSaveRunning = !this.isSaveRunning;
  }

  get selectedTab(): string | undefined {
    if (RouteRegexes.newSettings.workspaceHelpcenter.settings.test(this.router.currentRouteName)) {
      return 'configure';
    } else {
      return 'collections';
    }
  }

  @action
  setSelectedTab(tab: string) {
    if (tab === 'configure') {
      this.router.replaceWith('apps.app.settings.helpcenter.workspace-helpcenter.settings');
    } else {
      this.router.replaceWith('apps.app.settings.helpcenter.workspace-helpcenter.collections');
    }
  }

  fileSizeValidator(size: $TSFixMe, sizeText: $TSFixMe) {
    return FILE_SIZE_VALIDATOR(
      size,
      this.intl.t('articles.settings.redesign.validation.file-size-error', {
        size: sizeText,
      }),
    );
  }
  imageSizeValidator(width?: number, height?: number) {
    return {
      validate: (image: File) => {
        let url = URL.createObjectURL(image);
        let img = new Image();
        let imgLoaded = new Promise((resolve, _reject) => {
          img.onload = function () {
            let tooTall = height && img.height > height;
            let tooWide = width && img.width > width;
            resolve(!tooTall && !tooWide);
          };
          img.onerror = function () {
            // If there was an error on uploading the image it will be checked on the backend
            resolve(true);
          };
        });
        img.src = url;

        return imgLoaded;
      },
      errorMessage: () =>
        this.intl.t('articles.settings.redesign.validation.image-size-error', {
          size: width || height,
        }),
    };
  }
  _createUploadHelper() {
    let _MediaHelper = MediaHelper as $TSFixMe;
    return UploadHelper.create({
      header: _MediaHelper.create({
        type: 'header',
        siteSettingsName: 'headerUrl',
        validators: [
          this.fileSizeValidator(2000000, '2MB'),
          this.imageSizeValidator(MAX_IMG_SIZES.header, MAX_IMG_SIZES.header),
        ],
      }),
      logo: _MediaHelper.create({
        type: 'logo',
        siteSettingsName: 'logoUrl',
        validators: [
          this.fileSizeValidator(1000000, '1MB'),
          this.imageSizeValidator(MAX_IMG_SIZES.logo, MAX_IMG_SIZES.logo),
        ],
      }),
      footerLogo: _MediaHelper.create({
        type: 'footer_logo',
        siteSettingsName: 'footerLogoUrl',
        validators: [
          this.fileSizeValidator(1000000, '1MB'),
          this.imageSizeValidator(MAX_IMG_SIZES.footerLogo, MAX_IMG_SIZES.footerLogo),
        ],
      }),
      favicon: _MediaHelper.create({
        type: 'favicon',
        siteSettingsName: 'faviconUrl',
        validators: [
          this.fileSizeValidator(1500000, '1.5MB'),
          this.imageSizeValidator(MAX_IMG_SIZES.favicon, MAX_IMG_SIZES.favicon),
        ],
      }),
      social: _MediaHelper.create({
        type: 'social_image',
        siteSettingsName: 'socialUrl',
        validators: [
          this.fileSizeValidator(2000000, '2MB'),
          this.imageSizeValidator(MAX_IMG_SIZES.social, MAX_IMG_SIZES.social),
        ],
      }),
      collection_card: [
        _MediaHelper.create({
          type: 'collection_card',
          siteSettingsName: 'collectionCardGlobal',
          validators: [
            this.fileSizeValidator(2000000, '2MB'),
            this.imageSizeValidator(MAX_IMG_SIZES.collection_card, MAX_IMG_SIZES.collection_card),
          ],
        }),
        _MediaHelper.create({
          type: 'collection_card',
          siteSettingsName: 'collectionCardGlobal',
          collectionCardType: 'images',
          validators: [
            this.fileSizeValidator(2000000, '2MB'),
            this.imageSizeValidator(MAX_IMG_SIZES.collection_card, MAX_IMG_SIZES.collection_card),
          ],
        }),
        _MediaHelper.create({
          type: 'collection_card',
          siteSettingsName: 'collectionCardGlobal',
          collectionCardType: 'icons',
          validators: [this.fileSizeValidator(2000000, '2MB')],
        }),
      ],
      contentBlock: _MediaHelper.create({
        type: 'content_block',
        siteSettingsName: 'contentBlock',
        validators: [
          this.fileSizeValidator(2000000, '2MB'),
          this.imageSizeValidator(MAX_IMG_SIZES.contentBlock, MAX_IMG_SIZES.contentBlock),
        ],
      }),
      mediaTypes: [
        'header',
        'logo',
        'footerLogo',
        'favicon',
        'social',
        'collection_card',
        'contentBlock',
      ],
    });
  }
}
