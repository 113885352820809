/* RESPONSIBLE TEAM: team-channels */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable promise/prefer-await-to-then */
/* eslint-disable ember/no-controllers */
import { not, and } from '@ember/object/computed';
import { equalToProperty } from '@intercom/pulse/lib/computed-properties';
import { inject as service } from '@ember/service';
import AccountControllerBase from 'embercom/controllers/apps/app/account/base/account-controller-base';

export default AccountControllerBase.extend({
  notificationsService: service(),
  intl: service(),
  currentPassword: null,
  newPassword: null,
  confirmPassword: null,

  newPasswordsMatch: equalToProperty('newPassword', 'confirmPassword'),
  newPasswordsDontMatch: not('newPasswordsMatch'),

  allPasswordFieldsComplete: and(
    'currentPassword.length',
    'newPassword.length',
    'confirmPassword.length',
  ),
  passwordsFieldsRequireCompletion: not('allPasswordFieldsComplete'),

  save() {
    if (this.passwordsFieldsRequireCompletion) {
      this.notificationsService.notifyError(
        this.intl.t('apps.app.account.password.complete-all-fields'),
      );
    } else if (this.newPasswordsDontMatch) {
      this.notificationsService.notifyError(
        this.intl.t('apps.app.account.password.confirmation-not-match'),
      );
    } else {
      this.admin
        .updatePersonalSettings({
          id: this.get('admin.id'),
          app_id: this.get('app.id'),
          current_password: this.currentPassword,
          password: this.newPassword,
          password_confirmation: this.confirmPassword,
        })
        .then(
          function () {
            this.notificationsService.notifyConfirmation(
              this.intl.t('apps.app.account.password.successfully-changed'),
            );
            this.setProperties({
              currentPassword: null,
              newPassword: null,
              confirmPassword: null,
            });
            this.transitionToRoute('apps.app.account.general');
          }.bind(this),
          function (response) {
            this._showErrorForResponse(response);
          }.bind(this),
        );
    }
  },

  _showErrorForResponse(response) {
    if (response.jqXHR.status === 401) {
      this.notificationsService.notifyError(
        this.intl.t('apps.app.account.password.incorrect-password'),
      );
    } else if (response.jqXHR.status === 422) {
      this.notificationsService.notifyError(
        this.intl.t('apps.app.account.password.errors-in-updates', {
          responseTextErrors: JSON.parse(response.jqXHR.responseText).errors,
        }),
      );
    } else {
      this.notificationsService.notifyError(
        this.intl.t('apps.app.account.password.password-not-updated'),
      );
    }
  },
});
