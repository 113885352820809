/* RESPONSIBLE TEAM: team-proactive-support */
import { inject as service } from '@ember/service';
import OutboundBaseListController from 'embercom/lib/outbound/base-list-controller';
import {
  objectIcons,
  objectTypes,
  objectInterfaceIcons,
} from 'embercom/models/data/matching-system/matching-constants';

export default class AppsAppOutboundSurveyIndexController extends OutboundBaseListController {
  @service outboundHomeService;
  @service contentEditorService;
  @service appService;
  @service intl;

  bannerData = {
    pageTitle: this.intl.t('outbound.tooltips.hero-banner.page-title'),
    button: this.intl.t('outbound.tooltips.hero-banner.button'),
    icon: objectIcons[objectTypes.tooltipGroup],
    isTooltipsSelected: true,
    interfaceIcon: objectInterfaceIcons[objectTypes.tooltipGroup],
    selectedObjectType: objectTypes.tooltipGroup,
    description: this.intl.t('outbound.tooltips.hero-banner.description'),
    links: [
      {
        tooltipLabel: true,
        description: this.intl.t('outbound.tooltips.hero-banner.links.tooltip.description'),
      },
      {
        description: this.intl.t('outbound.tooltips.hero-banner.links.article.description'),
        target: 'https://www.intercom.com/help/en/articles/6475940-tooltips-explained',
        analyticsObject: 'how_tooltips_work_link',
      },
      {
        description: this.intl.t(
          'outbound.tooltips.hero-banner.links.watch-how-to-create.description',
        ),
        wistiaId: '1pe67ejf10',
        analyticsObject: 'how_to_create_tooltips_link',
      },
    ],
  };
}
