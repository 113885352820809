/* RESPONSIBLE TEAM: team-customer-data-platform */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable ember/no-classic-classes */
import { alias } from '@ember/object/computed';
import Controller, { inject as controller } from '@ember/controller';
export default Controller.extend({
  queryParams: ['fromCompletedChecklist'],
  currentChecklist: alias('guideController.currentChecklist'),
  appController: controller('apps/app'),
  app: alias('appController.model'),
  checklists: alias('model'),
  admin: alias('app.currentAdmin'),
});
