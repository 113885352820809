/* RESPONSIBLE TEAM: team-data-interop */
import { action } from '@ember/object';
import AppstoreIndexController from 'embercom/controllers/base/appstore/index';

export default class AppPackageController extends AppstoreIndexController {
  @action
  closeModal() {
    if (this.app_package_code === 'jira-for-tickets') {
      let settings = this.store.peekAll(
        // @ts-ignore
        `appstore/app-package/${this.app_package_code}/app-settings`,
      );
      if (settings.firstObject && settings.firstObject.hasDirtyAttributes) {
        this.showDiscardChangeWarning();
      } else {
        this.clearAppPackageCode();
      }
    } else {
      this.clearAppPackageCode();
    }
  }

  @action
  transitionAppPackageTab(tab: string) {
    this.transitionToRoute({
      queryParams: { tab: tab || null },
    });
  }
}
