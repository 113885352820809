/* RESPONSIBLE TEAM: team-proactive-support */
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import type IntlService from 'ember-intl/services/intl';
import OutboundBaseListController from 'embercom/lib/outbound/base-list-controller';
import {
  objectIcons,
  objectInterfaceIcons,
  objectTypes,
} from 'embercom/models/data/matching-system/matching-constants';
import type NewsFeedService from 'embercom/services/news-feed-service';

export default class NewsfeedsController extends OutboundBaseListController {
  @service declare intl: IntlService;
  @service declare newsFeedService: NewsFeedService;
  @tracked selectedTab = 'targeted-newsfeeds';
  hideDeliveryTypeTabs = true;
  hideAudienceComponent = true;
  showNewsfeedComponent = true;
  messagesTags = [];

  get targetedNewsfeedWrappers() {
    return (this.model as any).filter((wrapper: any) => {
      return wrapper.contents.firstObject.contentData?.platform !== 'web';
    });
  }

  get bannerData() {
    return {
      pageTitle: this.intl.t('outbound.newsfeeds.list.title'),
      button: this.intl.t('outbound.newsfeeds.list.button'),
      icon: objectIcons[objectTypes.newsfeed],
      interfaceIcon: objectInterfaceIcons[objectTypes.newsfeed],
      selectedObjectType: objectTypes.newsfeed,
      description: this.intl.t('outbound.newsfeeds.list.description'),
      links: [this.getStarted, this.tour],
    };
  }

  get tour() {
    return {
      description: this.intl.t(
        `outbound.newsfeeds.hero-banner.links.${this.tabString}.tour.description`,
      ),
      tourId: this.intl.t(`outbound.newsfeeds.hero-banner.links.${this.tabString}.tour.id`),
      analyticsObject: 'messages_tour_link',
    };
  }

  get getStarted() {
    return {
      description: this.intl.t(
        `outbound.newsfeeds.hero-banner.links.${this.tabString}.get-started.description`,
      ),
      target: this.intl.t(
        `outbound.newsfeeds.hero-banner.links.${this.tabString}.get-started.target`,
      ),
      analyticsObject: 'how_to_get_started_link',
    };
  }

  get tabString() {
    return this.selectedTab === 'targeted-newsfeeds' ? 'newsfeed' : 'news-center';
  }

  get priorityTooltipText() {
    return this.intl.t('outbound.newsfeeds.list.priority-tooltip');
  }

  get showDeleteNewsfeedConfirmModal() {
    return this.newsFeedService.showDeleteNewsfeedConfirmModal;
  }

  get showTargetedNewsfeeds() {
    return this.selectedTab === 'targeted-newsfeeds';
  }

  @action
  // @ts-ignore
  async createContent() {
    await this.newsFeedService.createRuleset();
    // @ts-ignore
    this.refresh();
  }

  @action
  async deleteNewsfeed() {
    await this.newsFeedService.deleteNewsfeed();
    // @ts-ignore
    this.refresh();
  }

  @action
  closeDeleteNewsfeedConfirmModal() {
    this.newsFeedService.showDeleteNewsfeedConfirmModal = false;
  }
}
