/* RESPONSIBLE TEAM: team-frontend-tech */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable ember/no-controllers */
/* eslint-disable ember/no-classic-classes */
import Controller, { inject as controller } from '@ember/controller';
import { readOnly, reads } from '@ember/object/computed';
import { inject as service } from '@ember/service';
export default Controller.extend({
  intercomEventService: service(),
  router: service(),
  guideLibraryService: service(),
  identityVerificationRiskStateService: service(),
  commonPrimaryNavBarService: service(),

  appController: controller('apps/app'),
  app: reads('appController.model'),
  currentRouteName: readOnly('router.currentRouteName'),
});
