/* RESPONSIBLE TEAM: team-frontend-tech */
import Controller from '@ember/controller';

export default class VideoController extends Controller {
  queryParams = ['v'];

  get videoKey() {
    return this.v;
  }
}
