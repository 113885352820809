/* RESPONSIBLE TEAM: team-growth-opportunities */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
/* eslint-disable promise/prefer-await-to-then */
/* eslint-disable ember/no-controllers */
/* eslint-disable ember/no-classic-classes */
import Controller, { inject } from '@ember/controller';
import { computed } from '@ember/object';
import { alias, readOnly } from '@ember/object/computed';
import { inject as service } from '@ember/service';
import { task } from 'ember-concurrency';
import Admin from 'embercom/models/admin';

export default Controller.extend({
  intl: service(),
  appService: service(),
  existingApp: readOnly('appService.app'),

  appsController: inject('apps'),
  store: service(),

  appName: null,
  companySize: undefined,

  companySizeError: null,
  nameError: null,
  serverError: null,

  admin: alias('appsController.model'),

  testWindow: null,

  companySizeOptions: computed('intl.locale', function () {
    return [
      {
        text: this.intl.t('signup.teams.start.form.company_size_options.one_to_five'),
        value: '1-5',
      },
      {
        text: this.intl.t('signup.teams.start.form.company_size_options.six_to_ten'),
        value: '6-10',
      },
      {
        text: this.intl.t('signup.teams.start.form.company_size_options.eleven_to_forty-nine'),
        value: '11-49',
      },
      {
        text: this.intl.t(
          'signup.teams.start.form.company_size_options.fifty_to_one-hundred-ninety-nine',
        ),
        value: '50-199',
      },
      {
        text: this.intl.t(
          'signup.teams.start.form.company_size_options.two-hundred_to_nine-hundred-ninety-nine',
        ),
        value: '200-999',
      },
      {
        text: this.intl.t('signup.teams.start.form.company_size_options.one-thousand_plus'),
        value: '1,000+',
      },
    ];
  }),

  _reloadCurrentAdminToGetPermissions() {
    return Admin.me().then((admin) => {
      this.set('admin', admin);
      return admin;
    });
  },

  /**
   * Parse and display an error on the create workspace form if the creation was unsuccessful. The method identifies
   * a translated message key to display and the correct property in which to display this error before setting
   * the translated message in the correct property. For example, a generic 500 response will result in setting
   * the translated `generic.error` message in a banner, not attached to any particular field. As another example,
   * a validation error for the name field will be populated beside the name field itself.
   *
   * @param {object} errorResponse the response from the attempted create record call.
   */
  _handleError(errorResponse) {
    let xhr = errorResponse?.jqXHR;
    let status = xhr?.status;
    let response = xhr?.responseJSON;

    let errorMessageKey = 'generic.error';
    let errorTarget = 'serverError';

    if (status === 422 && response) {
      if (response['name_sym']) {
        if (response['name_sym'] === 'app_name_invalid') {
          errorMessageKey = 'sign_up.new.name_invalid';
          errorTarget = 'nameError';
        } else {
          errorMessageKey = 'sign_up.new.name_error';
          errorTarget = 'nameError';
        }
      } else if (response['company_size_sym']) {
        errorMessageKey = 'sign_up.new.company_size_error';
        errorTarget = 'companySizeError';
      }
    } else if (status === 504) {
      errorMessageKey = 'generic.timeout';
    }

    this.set(errorTarget, this.intl.t(errorMessageKey));
  },

  createApp: task(function* () {
    this.set('companySizeError', null);
    this.set('nameError', null);
    this.set('serverError', null);

    let app;
    let previousAppId = this.get('existingApp.id');

    try {
      app = yield this.store
        .createRecord('app', {
          name: this.appName,
          company_size: this.companySize,
          force_stripe_billing: true,
        })
        .save();
    } catch (errorResponse) {
      this._handleError(errorResponse);
      return;
    }

    yield this._reloadCurrentAdminToGetPermissions();

    // We use window.location navigation here to show the Intercom app loading animation for a new workspace and,
    // perhaps, to blow away the ember app context for the new workspace. This may not be necessary or desired but
    // we need to investigate before changing anything.
    let navigationWindow = this.testWindow || window;
    let { protocol, host } = navigationWindow.location;
    navigationWindow.location.href = `${protocol}//${host}/a/apps/${app.get(
      'id',
    )}/created?previousAppId=${previousAppId}`;
  }),
});
