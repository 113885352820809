/* RESPONSIBLE TEAM: team-workflows */

import { inject as service } from '@ember/service';
import Controller from '@ember/controller';
import PALETTE from '@intercom/pulse/lib/palette';
import { SIGNAL_CONFIGS } from 'embercom/models/reporting/signal';
import { isNone } from '@ember/utils';
import RouteRegexes from 'embercom/lib/route-regexes';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';

import {
  MEETING_BOOKED,
  SALESFORCE_LEAD_CREATED,
  OPERATOR_CONVERSATIONS_ASSIGNED,
  OPERATOR_CONVERSATIONS_COMPLETED,
  OPERATOR_CONVERSATION_ENGAGED,
  OPERATOR_CONVERSATION_SENT,
  OPERATOR_CUSTOM_BOTS_BUSIEST_PERIODS,
} from 'embercom/lib/reporting/queries';

const WORKFLOW_TRIGGER_START_DATE = '2023-02-20';

export default class ReportsLeadGenerationCustomBotsController extends Controller {
  @service appService;
  @service intercomEventService;
  @service intl;
  @service reportingService;
  @service router;

  queryParams = ['customBot', 'workflowTarget'];
  palette = PALETTE;

  @tracked bots;
  @tracked customBot = null;
  @tracked workflowTarget = null;
  @tracked range;
  @tracked statsSystemFilters;

  get app() {
    return this.appService.app;
  }

  get workflowTriggerReportingStartDate() {
    if (this.workflowTarget) {
      return WORKFLOW_TRIGGER_START_DATE;
    }
    return null;
  }

  get baseCustomBotsRoute() {
    if (this.isOnCustomerSupportReports) {
      return 'apps.app.reports.customer-support.custom-bots';
    } else {
      return 'apps.app.reports.lead-generation.custom-bots';
    }
  }

  get botsDropdown() {
    let allBotsItem = {
      items: [
        {
          text: this.intl.t('reporting.custom-bots.filter.no-filter'),
          value: null,
          isSelected: isNone(this.customBot),
        },
      ],
    };

    let botsListItems = {
      heading: this.intl.t('reporting.custom-bots.filter.placeholder'),
      items: this.bots.sortBy('order').map((bot) => ({
        text: bot.title,
        value: bot.id,
        isSelected: bot.id === this.customBot,
      })),
    };

    return [allBotsItem, botsListItems];
  }

  get targetDropdown() {
    let allTargetItem = {
      items: [
        {
          text: this.intl.t('reporting.custom-bots.target-filter.no-filter'),
          value: null,
          isSelected: isNone(this.workflowTarget),
        },
      ],
    };

    let customBotConfigs = this.bots.map((b) => b.customBotConfig).uniqBy('target');

    let targetsListItems = {
      heading: this.intl.t('reporting.custom-bots.target-filter.placeholder'),
      items: customBotConfigs.map((config) => ({
        text: this.intl.t(config.titleTranslationKey),
        value: config.target,
        isSelected: config.target === this.workflowTarget,
      })),
    };

    return [allTargetItem, targetsListItems];
  }

  get conversationsParticipatedInByWeekAndHourFilters() {
    return Object.assign({ 'event.type': 'stats_reply' }, this.statsSystemFilters);
  }

  get hasCustomBots() {
    return this.bots.length > 0;
  }

  get isOnCustomerSupportReports() {
    return this.router.currentRouteName.match(RouteRegexes.reports.customerSupportReports);
  }

  get queries() {
    return {
      leadDisqualified: SIGNAL_CONFIGS.LEAD_DISQUALIFIED,
      emailCaptured: SIGNAL_CONFIGS.EMAIL_CAPTURED,
      salesforceLeadCreated: SALESFORCE_LEAD_CREATED,
      meetingBooked: MEETING_BOOKED,
      conversationsAssigned: OPERATOR_CONVERSATIONS_ASSIGNED,
      conversationsParticipatedInByWeekAndHour: OPERATOR_CUSTOM_BOTS_BUSIEST_PERIODS,
      conversationsSent: OPERATOR_CONVERSATION_SENT,
      conversationsEngaged: OPERATOR_CONVERSATION_ENGAGED,
      conversationsCompleted: OPERATOR_CONVERSATIONS_COMPLETED,
    };
  }

  @action
  updateRange(range) {
    this.reportingService.updateRange(range, null, true, this.workflowTriggerReportingStartDate);

    this.intercomEventService.trackAnalyticsEvent({
      action: 'filtered_by_date',
      object: 'custom_bots_report',
      date_filter: this.range.selectedRange,
      start_date: this.range.startMoment.format('YYYY/MM/DD'),
      end_date: this.range.endMoment.format('YYYY/MM/DD'),
    });
  }

  @action
  updateBot(botId) {
    this.customBot = botId;

    this.intercomEventService.trackAnalyticsEvent({
      action: 'filtered_by_bot',
      object: 'custom_bots_report',
      date_filter: this.range.selectedRange,
      start_date: this.range.startMoment.format('YYYY/MM/DD'),
      end_date: this.range.endMoment.format('YYYY/MM/DD'),
      bot_id: botId,
    });
  }

  @action
  updateTarget(target) {
    this.workflowTarget = target;

    this.intercomEventService.trackAnalyticsEvent({
      action: 'filtered_by_trigger',
      object: 'custom_bots_report',
      date_filter: this.range.selectedRange,
      start_date: this.range.startMoment.format('YYYY/MM/DD'),
      end_date: this.range.endMoment.format('YYYY/MM/DD'),
      workflow_target: this.workflowTarget,
    });
  }
}
