/* RESPONSIBLE TEAM: team-purchase-experience */
/* eslint-disable ember/no-controllers */
import Controller from '@ember/controller';
import { inject as service } from '@ember/service';

export default class ReferralsController extends Controller {
  @service declare intl: any;

  get referralUrl() {
    return `https://app.intercom.com/admins/sign_up?solution_id=29&referral_code=${this.model}`;
  }
}
