/* RESPONSIBLE TEAM: team-workflows */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable ember/no-controllers */
import Controller from '@ember/controller';
import { assetUrl } from '@intercom/pulse/helpers/asset-url';
import { inject as service } from '@ember/service';

export default class OperatorController extends Controller {
  @service store;
  @service intl;
  initializeConfigurationModel() {
    let messengerResponseTimeKey =
      this.get('model.availability.messengerResponseTimeKey') || 'a_few_minutes';
    this.set(
      'previewConfiguration',
      this.store.createFragment('messenger-preview/configuration', {
        locale: 'en',
        backgroundPatternNumber: 1,
        logoURL: assetUrl('/assets/images/error/intercom-logo.png'),
        appPrimaryColor: this.get('model.lookAndFeel.primaryColor'),
        appSecondaryColor: this.get('model.lookAndFeel.secondaryColor'),
        greetingText: this.intl.t('controllers.apps.app.operator.hi-there'),
        aboutUsText: this.intl.t('controllers.apps.app.operator.happy-to-help'),
        messengerResponseTimeKey,
        showWeRunOnIntercom: true,
        inboundConversationsEnabledForUsers: false,
        inboundConversationsEnabledForVisitors: false,
        launcherVisibleForUsers: true,
        launcherVisibleForVisitors: true,
      }),
    );
  }
}
