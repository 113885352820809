/* RESPONSIBLE TEAM: team-proactive-support */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
/* eslint-disable ember/no-controllers */
import OutboundBaseListController from 'embercom/lib/outbound/base-list-controller';
import { matchBehaviors } from 'embercom/models/data/matching-system/matching-constants';

const GETTING_STARTED_TOUR_ID = 2309;

export default OutboundBaseListController.extend({
  bannerData: {
    pageTitle: 'Product Tours',
    button: 'New product tour',
    icon: 'product-tours-filled',
    isToursSelected: true,
    description: 'Guide customers through your product to help them see all its value faster.',
    links: [
      {
        description: 'See what you can do with tours',
        tourId: GETTING_STARTED_TOUR_ID,
        analyticsObject: 'how_to_get_started_link',
      },
      {
        description: 'Learn how tours work',
        target:
          'https://www.intercom.com/help/product-tours/guide-customers-to-success-with-product-tours',
        analyticsObject: 'how_product_tours_work_link',
      },
    ],
  },
  matchBehavior: matchBehaviors.single,

  tourId: GETTING_STARTED_TOUR_ID,
});
