/* RESPONSIBLE TEAM: team-messenger */
import Controller from '@ember/controller';

export default class Web extends Controller {
  queryParams = [{ tab: { type: 'string' as const } }, { section: { type: 'string' as const } }];

  declare model: {
    settings: any;
    identity: any;
  };
}
