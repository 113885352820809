/* RESPONSIBLE TEAM: team-help-desk-experience */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
/* eslint-disable ember/no-classic-classes */
import { computed } from '@ember/object';
import { alias } from '@ember/object/computed';
import Controller, { inject as controller } from '@ember/controller';
export default Controller.extend({
  conversation: alias('model'),
  appController: controller('apps/app'),
  app: alias('appController.model'),
  admin: alias('appController.admin'),

  queryParams: ['redirectTo'],
  // Do not set this to 'feed' or else the URL will not reflect it
  redirectTo: null,

  breadcrumbs: computed('app', function () {
    return [
      {
        text: 'Conversations',
        route: 'apps.app.feed',
        model: this.app,
      },
    ];
  }),

  // TO DO
  isSaving: false,
});
