/* RESPONSIBLE TEAM: team-tickets-1 */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
/* eslint-disable ember/no-actions-hash */
/* eslint-disable ember/require-computed-property-dependencies */
/* eslint-disable ember/no-classic-classes */
import { computed } from '@ember/object';
import { findByProperty } from '@intercom/pulse/lib/computed-properties';
import Controller from '@ember/controller';
import { inject as service } from '@ember/service';
import { filterBy, union, readOnly } from '@ember/object/computed';

export default Controller.extend({
  intercomEventService: service(),
  media: service(),
  appstoreService: service(),
  app: readOnly('appService.app'),

  queryParams: ['category', 'search', 'installed', 'capability', 'inbox_capability'],
  category: null,
  search: null,
  installed: null,
  capability: null,
  features: null,
  inbox_capability: null,
  displayFeaturedCategoriesGrid: computed(
    'category',
    'search',
    'installed',
    'capability',
    function () {
      return !(this.category || this.search || this.installed || this.capability);
    },
  ),
  featuredCategoriesAndApps: null,
  selectedCapability: computed('capability', function () {
    return this.appstoreService.appCapabilities.find(
      (capability) => capability.slug === this.capability,
    );
  }),
  publicCategories: filterBy('model.defaultCategories', 'publiclyVisible', true),
  featuredCategories: filterBy('model.featuredCategories', 'publiclyVisible', true),
  allCategories: union('model.defaultCategories', 'model.featuredCategories'),
  selectedCategory: findByProperty('allCategories', 'slug', 'category'),
  gridTitle: computed('search', 'selectedCategory.name', 'installed', function () {
    if (this.search) {
      return 'Search results';
    } else if (this.get('selectedCategory.name')) {
      return this.get('selectedCategory.name');
    } else if (this.installed) {
      return 'Your installed apps';
    } else {
      return 'All apps';
    }
  }),

  actions: {
    searchForApps(query) {
      this.setProperties({
        search: query || null,
        category: null,
        installed: null,
        capability: null,
      });
    },
    clickSeeAllLink(categorySlug) {
      this.intercomEventService.trackAnalyticsEvent({
        action: 'clicked',
        object: 'see_all_link',
        category_name: categorySlug,
      });
      this.transitionToRoute(this.get('appstoreService.baseRoute'), {
        queryParams: {
          category: categorySlug,
          search: null,
          installed: null,
          capability: null,
        },
      });
    },
    onAppPackageClick(categorySlug, appPackage) {
      this.intercomEventService.trackAnalyticsEvent({
        action: 'clicked',
        object: appPackage,
        category_name: categorySlug,
      });
      this.transitionToRoute({
        queryParams: { app_package_code: appPackage.get('id') },
      });
    },
  },
});
