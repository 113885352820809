/* RESPONSIBLE TEAM: team-growth-opportunities */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable ember/no-controllers */
import Controller, { inject as controller } from '@ember/controller';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import ajax from 'embercom/lib/ajax';
import { MARKETO_FORM_IDS } from 'embercom/lib/gtm';
import { hideIntercomWidgetWhenLoaded } from 'embercom/lib/intercom-widget-helper';
import { captureException } from 'embercom/lib/sentry';

export default class EarlyStageApplicationController extends Controller {
  @service purchaseAnalyticsService;
  @service intl;
  @service gtmService;
  @service notificationsService;
  @service earlyStageService;
  @service cardlessTrialService;
  @service customerService;
  @service router;

  @controller('apps/app/teams-checkout') teamsController;
  @controller('apps.app') appController;

  get app() {
    return this.appController.app;
  }

  get mobileDevice() {
    return this.teamsController.mobileCart;
  }

  async transitionToCheckout() {
    await this.app.reload();
    await this.customerService.refreshCustomer();
    this.notificationsService.notifyConfirmation(this.intl.t('teams-checkout.early_stage.success'));

    let route = 'apps.app.teams-checkout.confirm';
    let queryParams = {};
    if (this.cardlessTrialService.isInCardlessTrial) {
      queryParams.cardless_trial = true;
      queryParams.solution_id = this.teamsController.solutionId;
    } else if (!this.app.get('hasActiveSubscription')) {
      queryParams.solution_id = this.teamsController.solutionId;
    } else {
      route = 'apps.app.billing.details';
    }

    this.transitionToRoute(route, { queryParams });
  }

  hideWidgetIfAppropriate() {
    if (this.mobileDevice) {
      hideIntercomWidgetWhenLoaded();
    }
  }

  @action
  async submitApplication(formData) {
    formData.solution_id = this.teamsController.solutionId;
    this.set('isSubmitting', true);
    try {
      await ajax({
        url: '/marketing_onboarding/signups/early_stage',
        type: 'POST',
        data: JSON.stringify(formData),
      });

      await this.gtmService.fireMarketoEvent(MARKETO_FORM_IDS.earlyStage, {
        Email: this.app.email || this.app.currentAdmin.email,
        App_ID__c: this.app.id,
        Company: this.app.name,
        name: this.app.currentAdmin.name || this.app.name,
      });

      this.purchaseAnalyticsService.trackEvent({
        action: 'submitted',
        context: 'usecase_signup_flow',
        place: 'early_stage_application',
        object: 'early_stage_application',
        solutionId: this.solutionId,
        app_id_code: this.app.id,
      });
      await this.transitionToCheckout();
    } catch (err) {
      this.set('isSubmitting', false);
      let textKey =
        err.jqXHR.responseJSON && err.jqXHR.responseJSON.error_code
          ? `teams-checkout.early_stage.${err.jqXHR.responseJSON.error_code}`
          : 'teams-checkout.early_stage.error_submit';
      if (
        (this.cardlessTrialService.canUseEarlyStageDetection || this.app.onPricing5) &&
        err.jqXHR.responseJSON.error_code === 'early_stage_application_rejected'
      ) {
        this.notificationsService.notifyError(this.intl.t(textKey));
        await this.app.reload();
        this.cardlessTrialService.initializeUsingApp(this.app);
        this.router.transitionTo('apps.app.billing.index');
      } else {
        this.notificationsService.notifyError(this.intl.t(textKey));
        this.router.transitionTo(this.app.onboardingHomeRoute);
        captureException(err, {
          fingerprint: ['early-stage-application'],
        });
      }
    }
  }
}
