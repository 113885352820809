/* RESPONSIBLE TEAM: team-channels */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable ember/no-actions-hash */
/* eslint-disable ember/no-controllers */
import { reads } from '@ember/object/computed';
import AccountControllerBase from 'embercom/controllers/apps/app/account/base/account-controller-base';

export default AccountControllerBase.extend({
  segments: reads('app.companySegments'),
  showSaveSuccessfulNotification: false,
  showSaveErrorNotification: false,

  actions: {
    showSegment(segment) {
      this.addSegmentIdsToAdminsVisibleSegmentIds([segment.get('id')]);
      this.save();
    },
    hideSegment(segment) {
      this.removeSegmentIdsFromAdminsVisibleSegmentIds([segment.get('id')]);
      this.save();
    },
  },
});
