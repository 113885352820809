/* RESPONSIBLE TEAM: team-proactive-support */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable ember/require-computed-property-dependencies */
/* eslint-disable ember/no-controllers */
import { inject as service } from '@ember/service';
import { task } from 'ember-concurrency';
import ReportBase from 'embercom/controllers/apps/app/reports/base/report';
import { computed } from '@ember/object';
import {
  LEADS_CREATED,
  MEDIAN_FIRST_RESPONSE_TIME_NEW,
  SALESFORCE_LEAD_CREATED,
  LEADS_CREATED_FILTERS,
} from 'embercom/lib/reporting/queries';

export default ReportBase.extend({
  store: service(),
  appService: service(),
  intl: service(),
  salesforceIntegrationInstalled: false,
  loadSalesforceIntegrationTask: task(function* () {
    let salesforceAppPackage = yield this.store.findRecord(
      'appstore/app-package',
      'salesforce-by-intercom',
    );
    this.set('salesforceIntegrationInstalled', salesforceAppPackage.isInstalled);
  }),

  queries: computed('settings', function () {
    return {
      leadsCreated: LEADS_CREATED,
      salesforceLeadsCreated: SALESFORCE_LEAD_CREATED,
      medianFirstResponseTime: MEDIAN_FIRST_RESPONSE_TIME_NEW({
        withinOfficeHours: this.settings.reportingExcludeOooHours,
        excludeBotInbox: this.botInboxTime === 'excluded',
      }),
    };
  }),
  filters: {
    leadsCreated: LEADS_CREATED_FILTERS,
    medianFirstResponseTime: { user_roles: ['contact_role'] },
  },
  customOfficeHoursTooltipText: computed(
    'intl.locale',
    'appService',
    'intl',
    'settings',
    function () {
      let defaultTooltip = this.intl.t(
        'apps.app.reports.lead-generation.leads.median-time-tooltip-text',
      );
      let officeHoursTooltip = this.intl.t(
        'components.reporting.flexible.chart-header-custom-tooltip.office-hours-tooltip.tooltip-description',
        { includeOfficeHours: this.settings.reportingExcludeOooHours },
      );
      return this.intl.t(
        'components.reporting.reports.effectiveness.office-hours.tooltip-with-office-hours',
        { defaultTooltip, officeHoursTooltip, htmlSafe: true },
      );
    },
  ),
});
