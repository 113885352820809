/* RESPONSIBLE TEAM: team-customer-data-platform */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable ember/require-computed-property-dependencies */
/* eslint-disable ember/no-controllers */
/* eslint-disable ember/no-classic-classes */
import { action, computed } from '@ember/object';
import { reads, equal, and, empty, sort } from '@ember/object/computed';
import Controller, { inject as controller } from '@ember/controller';
import { inject as service } from '@ember/service';

export default Controller.extend({
  appController: controller('apps/app'),
  app: reads('appController.model'),
  // eslint-disable-next-line @intercom/intercom/no-legacy-modal
  modalService: service(),
  sortBy: 'name',
  sortOrder: 'ascending',
  isSortedByName: equal('sortBy', 'name'),
  isSortedByUserCount: equal('sortBy', 'user_count'),
  isSortedByCompanyCount: equal('sortBy', 'company_count'),
  isSortedAscending: equal('sortOrder', 'ascending'),
  nameIsAscending: and('isSortedByName', 'isSortedAscending'),
  companyCountIsAscending: and('isSortedByUserCount', 'isSortedAscending'),
  userCountIsAscending: and('isSortedByCompanyCount', 'isSortedAscending'),
  sortDefinition: computed('sortBy', 'sortOrder', function () {
    let sortOrderForEmber = this.isSortedAscending ? 'asc' : 'desc';
    return [`${this.sortBy}:${sortOrderForEmber}`];
  }),
  displayEmptyState: empty('nonDeletedTagsOrSegments'),
  sortedTagsOrSegments: sort('nonDeletedTagsOrSegments', 'sortDefinition'),
  toggleSortOrder(newSortBy) {
    if (this.sortBy === newSortBy) {
      let newSortOrder = this.sortOrder === 'ascending' ? 'descending' : 'ascending';
      this.set('sortOrder', newSortOrder);
    } else {
      this.set('sortBy', newSortBy);
    }
  },
  toggleSortByNameOrder: action(function () {
    this.toggleSortOrder('name');
  }),
  toggleSortByUserCountOrder: action(function () {
    this.toggleSortOrder('user_count');
  }),
  toggleSortByCompanyCountOrder: action(function () {
    this.toggleSortOrder('company_count');
  }),
});
