/* RESPONSIBLE TEAM: team-ml */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
import Controller from '@ember/controller';
import { tracked } from '@glimmer/tracking';

class ZendeskChat {
  origin = 'zendesk_chat';
  name = 'Zendesk Chat';
  isZendeskChat = true;
}

class ZendeskTickets {
  origin = 'zendesk_tickets';
  name = 'Zendesk Tickets';
  isZendeskTickets = true;
}

export default class ZendeskChatController extends Controller {
  queryParams = ['origin'];

  @tracked origin = 'zendesk_chat';

  currentStep = 1;

  steps = [
    { number: 1, name: 'Get started' },
    { number: 2, name: 'Authorize' },
    { number: 3, name: 'Import' },
  ];

  get importerConfiguration() {
    if (this.origin === 'zendesk_chat') {
      return new ZendeskChat();
    } else if (this.origin === 'zendesk_tickets') {
      return new ZendeskTickets();
    } else {
      throw new Error(`Unknown origin type ${origin}`);
    }
  }
}
